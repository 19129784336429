<template>
  <div>
    <b-row>
      <b-col class="px-0" cols="10">
        <h5 class="my-auto">{{ comment.name }}</h5>
        <small>
          {{ unixtimeToDate(comment.unixtime) }}
          {{ commentVisibility }}
        </small>
      </b-col>
      <b-col class="text-right px-0" cols="2">
        <b-button
          v-if="comment.name == userName"
          v-b-toggle="'editingComment' + comment.id"
          variant="success"
          class="my-0 ml-auto"
          size="sm"
        >
          <font-awesome-icon :icon="['fas', 'pencil-alt']" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="px-0">
        <p style="white-space: pre-line" class="text-break">{{ comment.text }}</p>
        <b-collapse :id="'editingComment' + comment.id">
          <validation-observer ref="obs" v-slot="ObserverProps">
            <validation-provider name="editingComment" rules="required">
              <b-textarea v-model="localText" max-rows="20"></b-textarea>
              <div class="text-right">
                <b-button
                  variant="outline-danger"
                  class="my-2 mx-2"
                  size="sm"
                  @click="
                    $bvModal.show('removeComment');
                    setRemoveCommentKey(comment.id);
                  "
                >
                  削除
                </b-button>
                <b-button
                  v-b-toggle="'editingComment' + comment.id"
                  variant="success"
                  class="my-2 mx-2"
                  size="sm"
                  :disabled="ObserverProps.invalid"
                  @click="updateComment(comment.id)"
                >
                  更新
                </b-button>
              </div>
            </validation-provider>
          </validation-observer>
        </b-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "dayjs/locale/ja";
import dayjs from "dayjs";

export default {
  name: "CommentItem",
  props: {
    comment: { type: Object, default: () => {} },
    userName: { type: String, default: "" },
    commentsReplica: { type: Object, default: () => {} },
    commentVisibility: { type: String, default: "" },
    setRemoveCommentKey: { type: Function, default: () => {} },
    updateComment: { type: Function, default: () => {} },
  },
  emits: ["update-text"],

  data() {
    return {
      localText: "",
    };
  },
  watch: {
    localText(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("update-text", { id: this.comment.id, text: newVal });
      }
    },
  },
  created() {
    this.localText = this.commentsReplica[this.comment.id]["text"];
  },
  methods: {
    unixtimeToDate: function (unixtime) {
      return dayjs(unixtime * 1000).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
