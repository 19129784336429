<template>
  <div>
    <b-tabs>
      <b-tab title="試合回数（20位まで）">
        <b-table
          :items="participateRanking.filter((player) => player.rank <= 20)"
          :fields="[
            { key: 'rank', label: '順位' },
            { key: 'name', label: '名前' },
            { key: 'count', label: '回数' },
          ]"
        ></b-table>
      </b-tab>
      <b-tab title="コメント回数（10位まで）">
        <b-table
          :items="commentRanking.filter((player) => player.rank <= 10)"
          :fields="[
            { key: 'rank', label: '順位' },
            { key: 'name', label: '名前' },
            { key: 'count', label: '回数' },
          ]"
        ></b-table>
      </b-tab>
      <b-tab title="読み回数（10位まで）">
        <b-table
          :items="readingRanking.filter((player) => player.rank <= 10)"
          :fields="[
            { key: 'rank', label: '順位' },
            { key: 'name', label: '名前' },
            { key: 'count', label: '回数' },
          ]"
        ></b-table>
      </b-tab>
      <b-tab title="ありあけ/わすらもち押し回数（10位まで）">
        <b-table
          :items="operatingRanking.filter((player) => player.rank <= 10)"
          :fields="[
            { key: 'rank', label: '順位' },
            { key: 'name', label: '名前' },
            { key: 'count', label: '回数' },
          ]"
        ></b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
export default {
  name: "RankingTables",
  components: {},
  props: {
    recordList: { type: Array, default: () => {} },
    user: { type: Object, default: () => {} },
  },
  data() {
    return {
      participateRanking: {},
      commentRanking: {},
      readingRanking: {},
      operatingRanking: {},
    };
  },
  watch: {
    recordList: function () {
      this.setRankings();
    },
  },
  created: function () {
    this.setRankings();
  },
  methods: {
    setRankings() {
      this.setParticipateRanking();
      this.setCommentRanking();
      this.setReadingAndOperatingRanking();
    },
    assignRankingToRankingList(rankingList) {
      let rank = 1;
      rankingList.forEach((player, index) => {
        if (player.count < rankingList[index - 1]?.count) rank = index + 1;
        player.rank = rank;
      });
    },
    setReadingAndOperatingRanking() {
      let readingRanking = {};
      let operaingRanking = {};
      this.recordList.forEach((round) => {
        if (!round.readPlaceInfo) return;
        round.readPlaceInfo.forEach((readPlace) => {
          if (!readPlace.reader || readPlace.reader === "記載なし") return;
          if (readPlace.readMode === "読み") {
            readingRanking[readPlace.reader] = (readingRanking[readPlace.reader] || 0) + 1;
          } else {
            operaingRanking[readPlace.reader] = (operaingRanking[readPlace.reader] || 0) + 1;
          }
        });
      });
      this.readingRanking = Object.entries(readingRanking)
        .sort((a, b) => b[1] - a[1])
        .map((player) => ({ name: player[0], count: player[1] }));
      this.operatingRanking = Object.entries(operaingRanking)
        .sort((a, b) => b[1] - a[1])
        .map((player) => ({ name: player[0], count: player[1] }));
      this.assignRankingToRankingList(this.readingRanking);
      this.assignRankingToRankingList(this.operatingRanking);
    },
    setParticipateRanking() {
      let participateRanking = {};
      this.recordList.forEach((round) => {
        round.dataList.forEach((record) => {
          [record.player1Name, record.player2Name].forEach((playerName) => {
            participateRanking[playerName] = (participateRanking[playerName] || 0) + 1;
          });
        });
      });

      participateRanking = Object.entries(participateRanking).sort((a, b) => b[1] - a[1]);
      // participateRankingの各要素を、{name: "player1Name", count: 1}のようなオブジェクトに変換する
      this.participateRanking = participateRanking.map((player) => {
        return { name: player[0], count: player[1] };
      });
      this.assignRankingToRankingList(this.participateRanking);
    },
    setCommentRanking() {
      let commentRanking = {};
      this.recordList.forEach((round) => {
        round.dataList.forEach((record) => {
          if (!record.comments) return;
          Object.values(record.comments).forEach((comment) => {
            if (comment.commentType == "private") return; // 自分専用のコメントはカウントしない
            if (comment.commentType == "directMessage") return; // DMはカウントしない
            if (comment.commentType == "seniorMemberOnly" && comment.upperLimitOfViewersEnterYear < this.user.enterYear)
              return; // 上級生のみのコメントは、自分が見られない場合はカウントしない

            commentRanking[comment.name] = (commentRanking[comment.name] || 0) + 1;
          });
        });
      });
      this.commentRanking = Object.entries(commentRanking)
        .sort((a, b) => b[1] - a[1])
        .map((player) => ({ name: player[0], count: player[1] }));

      this.assignRankingToRankingList(this.commentRanking);
    },
  },
};
</script>
