<template>
  <div class="container">
    <HeaderComponent />
    <b-card no-body>
      <b-tabs card>
        <b-tab title="ユーザー一覧" active>
          <p>空蝉に登録されているユーザーです。</p>
          <b-table striped hover responsive :items="userList" :fields="fields">
            <template #[`cell(authorized)`]="data">
              <b-button
                v-if="data.item.email !== currentUser.email && !!data.item.name && !!data.item.kana"
                size="sm"
                :variant="data.item.authorized ? 'danger' : 'success'"
                @click="
                  $bvModal.show('changeUserAuthorization');
                  selectedUser = data.item;
                "
              >
                {{ data.item.authorized ? "停止" : "承認" }}
              </b-button>
            </template>
            <template #[`cell(lastUpdateDate)`]="data">
              {{ unixtimeToDate(data.value) }}
            </template>
          </b-table>
        </b-tab>
        <b-tab title="名前候補">
          <p>
            対戦記録を入力するときの名前候補を編集できます。
            <br />
            人を追加したい場合:
            <br />
            「メンバー」で「新規登録」を選択
          </p>
          <validation-observer ref="obs" v-slot="ObserverProps">
            <b-form>
              <b-form-group label="入会年度">
                <b-form-select
                  v-model="meiboYear"
                  :options="meiboYearList"
                  @change="setMemberListByYear(meiboYear)"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="メンバー">
                <b-form-select
                  v-model="selectedMember"
                  :options="nameOptions"
                  @change="setMeiboData(meiboYear, selectedMember)"
                ></b-form-select>
              </b-form-group>
              <validation-provider name="editingComment" rules="required">
                <b-form-group v-if="selectedMember == '新規登録'" label="名前" description="必ず入力してください">
                  <b-form-input v-model="memberName"></b-form-input>
                </b-form-group>
              </validation-provider>
              <b-form-group label="かな">
                <b-form-input v-model="memberKana"></b-form-input>
              </b-form-group>
              <b-form-group label="段級位">
                <b-form inline>
                  <b-form-select v-model="memberKyu" :options="rankOptions" style="width: auto"></b-form-select>
                  <label for="inline-form-input-kyu" class="my-auto">級</label>
                  <b-form-select v-model="memberDan" :options="danOptions" style="width: auto"></b-form-select>
                  <label for="inline-form-input-dan" class="my-auto">段</label>
                </b-form>
              </b-form-group>
              <b-form-group>
                <b-button
                  v-if="selectedMember == '新規登録'"
                  size="sm"
                  variant="success"
                  class="my-2 mx-2 float-right"
                  :disabled="ObserverProps.invalid"
                  @click="updateMemberObject(meiboYear, memberName)"
                >
                  追加
                </b-button>
                <b-button
                  v-else
                  size="sm"
                  variant="success"
                  class="my-2 mx-2 float-right"
                  @click="updateMemberObject(meiboYear, memberName)"
                >
                  更新
                </b-button>
                <b-button
                  v-if="selectedMember != '新規登録'"
                  variant="outline-danger"
                  class="my-2 mx-2 float-right"
                  size="sm"
                  @click="$bvModal.show('remove')"
                >
                  削除
                </b-button>
              </b-form-group>
              <hr />
              <b-form-group label="入会年度を追加">
                <b-form-input v-model="addYear"></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-button
                  size="sm"
                  variant="success"
                  class="my-2 mx-2 float-right"
                  @click="addYearToMemberObject(addYear)"
                >
                  追加
                </b-button>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal :id="'remove'" hide-header hide-footer modal-center>
      <b-row>
        <b-col>
          <label>{{ selectedMember }}さんを名前候補から削除します。よろしいですか？</label>
        </b-col>
      </b-row>
      <b-row class="float-right">
        <b-col>
          <b-button variant="outline-secondary" class="my-2 mx-2" size="sm" @click="$bvModal.hide('remove')">
            やめる
          </b-button>
          <b-button
            variant="danger"
            class="my-2 mx-2"
            size="sm"
            @click="removeNameFromMeibo(meiboYear, selectedMember)"
          >
            名前を削除
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="changeUserAuthorization" hide-header hide-footer modal-center>
      <p>
        <b-row>
          <b-col>
            <p>
              {{ selectedUser.name }}さんの利用を{{ selectedUser.authorized ? "停止" : "承認" }}します。よろしいですか？
            </p>
          </b-col>
        </b-row>
        <b-row class="float-right">
          <b-col>
            <b-button
              variant="outline-secondary"
              class="my-2 mx-2"
              size="sm"
              @click="$bvModal.hide('changeUserAuthorization')"
            >
              やめる
            </b-button>
            <b-button
              :variant="selectedUser.authorized ? 'danger' : 'success'"
              class="my-2 mx-2"
              size="sm"
              @click="
                updateUserAuthorization(selectedUser.key, !selectedUser.authorized);
                $bvModal.hide('changeUserAuthorization');
              "
            >
              {{ selectedUser.authorized ? "停止" : "承認" }}する
            </b-button>
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>
<script>
import HeaderComponent from "@/components/Header.vue";
import { firebaseApp, functions } from "../main.js";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, update, set } from "firebase/database";
import { httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import "dayjs/locale/ja";
import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
import config from "../config/adminUsersEmail";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);
import OPTIONS from "../constants/options.js";

export default {
  name: "UserManage",
  components: {
    HeaderComponent,
    ValidationProvider,
  },
  data() {
    return {
      currentUser: {},
      meiboYear: "",
      memberObject: {},
      selectedMember: "",
      memberListByYear: "",
      addNewMember: false,
      memberName: "",
      memberKana: "",
      memberKyu: "",
      memberDan: "",
      nameOptions: [],
      rankOptions: OPTIONS.RANK_OPTIONS,
      danOptions: OPTIONS.DAN_OPTIONS,
      userList: [],
      fields: [
        {
          key: "name",
          label: "名前",
          sortable: true,
        },
        {
          key: "kana",
          label: "かな",
          sortable: true,
        },
        {
          key: "enterYear",
          label: "入会年次",
          sortable: true,
        },
        {
          key: "lastUpdateDate",
          label: "最終更新日",
          sortable: true,
        },
      ],
      addYear: dayjs(new Date()).format("YYYY"),
      meiboYearList: [],
      yearOptions: [],
      selectedUser: {},
    };
  },
  computed: {},
  created: function () {
    const database = getDatabase();
    const auth = getAuth(firebaseApp);
    this.currentUser = auth.currentUser;

    // 管理者のみ、利用可能列を表に表示する
    if (config.adminUsersEmail.includes(auth.currentUser.email)) {
      this.fields.unshift({
        key: "authorized",
        label: "",
        sortable: true,
      });
      // fieldsのenterYearのindexを取得する
      const enterYearIndex = this.fields.findIndex((field) => field.key === "enterYear");

      // enterYearのindex+1にemailを挿入する
      this.fields.splice(enterYearIndex + 1, 0, {
        key: "email",
        label: "メールアドレス",
        sortable: true,
      });
    }

    let _this = this;
    const usersRef = ref(database, "users/");
    onValue(usersRef, (snapshot) => {
      if (!snapshot) return;
      _this.userList = [];
      snapshot.forEach((data) => {
        let user = data.val();
        user["key"] = data.key;
        const academicEmailPattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+ac\.+.[A-Za-z0-9]+$/;
        if (academicEmailPattern.test(user["email"]) && !user["authorized"]) return; // 大学のメールアドレス、かつ、認証されていないユーザは表示しない

        _this.userList.push(user);
      });

      _this.userList.sort((a, b) => {
        if (a.lastUpdateDate > b.lastUpdateDate) return -1;
        return 1;
      });
    });

    const meiboRef = ref(database, "meibo/");
    onValue(meiboRef, (snapshot) => {
      _this.memberObject = {};
      snapshot.forEach((data) => {
        let member = data.val();
        _this.memberObject[data.key] = member;
      });
      let meiboYearList = Object.keys(this.memberObject);
      _this.meiboYearList = meiboYearList;
      this.meiboYearList.reverse();
      if (this.meiboYear === "" || !this.memberObject[this.meiboYear]) {
        _this.meiboYear = meiboYearList[0];
      }
      _this.setMemberListByYear(_this.meiboYear);
    });
  },
  methods: {
    sendEmail(email, name) {
      if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "localhost", 5001);
      }

      const sendRegistrationApprovalNotificationToUser = httpsCallable(
        functions,
        "sendRegistrationApprovalNotificationToUser"
      );
      sendRegistrationApprovalNotificationToUser({ email: email, name: name })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateUserAuthorization(key, authorizedValue) {
      const database = getDatabase();
      update(ref(database, "users/" + key), { authorized: authorizedValue });

      if (!authorizedValue) {
        alert("停止しました。");
        return;
      }

      // 利用可能にした場合にメールを送信する
      const user = this.userList.find((user) => user.key === key);
      this.sendEmail(user.email, user.name);
      alert("承認しました。");
    },
    updateMemberObject(meiboYear, memberName) {
      var appendKana = this.memberKana;
      var appendKyu = this.memberKyu;
      var appendDan = this.memberDan;
      var appendData = {
        [memberName]: {
          kana: appendKana,
          kyu: appendKyu,
          dan: appendDan,
        },
      };
      const database = getDatabase();
      update(ref(database, "meibo/" + meiboYear + "/"), appendData);
      alert("更新しました。");
    },
    setMemberListByYear(meiboYear) {
      let memberList = this.memberObject[meiboYear];
      if (memberList) {
        var memberNameList = Object.keys(memberList);
        memberNameList.push("新規登録");
        this.nameOptions = memberNameList;
        this.selectedMember = memberNameList[0];
        this.setMeiboData(meiboYear, memberNameList[0]);
      }
    },
    addYearToMemberObject(addYear) {
      if (this.memberObject[addYear]) {
        alert("この入会年度は既に存在しています。");
        return;
      }
      let dummyUser = {
        "テストユーザー（後で削除してください）": {
          dan: "4",
          kana: "てすとゆーざー",
          kyu: "A",
        },
      };
      const database = getDatabase();
      const meiboRef = ref(database, "meibo/" + addYear);
      update(meiboRef, dummyUser);
      alert("入会年度の候補を追加しました。");
    },
    setMeiboData(year, name) {
      if (name == "新規登録") {
        this.memberName = "";
        this.memberKana = "";
        this.memberKyu = "";
        this.memberDan = "";
      } else {
        this.memberName = name;
        this.memberKana = this.memberObject[year][name]["kana"];
        this.memberKyu = this.memberObject[year][name]["kyu"];
        this.memberDan = this.memberObject[year][name]["dan"];
      }
    },
    removeNameFromMeibo(year, name) {
      const database = getDatabase();
      const personInMeiboRef = ref(database, "meibo/" + year + "/" + name);
      set(personInMeiboRef, null);
      this.$bvModal.hide("remove");
    },
    unixtimeToDate(unixtime) {
      return dayjs(unixtime * 1000).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
