<template>
  <div>
    <validation-observer ref="obs" v-slot="ObserverProps">
      <b-row>
        <b-col>
          <validation-provider name="date" rules="required">
            <b-form-input v-model="editingRecord.date" :type="'date'"></b-form-input>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form inline style="width: auto">
            <b-form-select
              v-model="editingRecord.round"
              :options="roundOptions"
              class="mb-2 mr-sm-2 mb-sm-0"
            ></b-form-select>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="text-center clear">
        <b-col>
          <b-form-input
            v-model="editingRecord.player1Name"
            placeholder="1人目"
            list="utsusemiUserListInModal1"
            autocomplete="off"
            @update="setUid(1, editingRecord.player1Name)"
          ></b-form-input>
          <datalist id="utsusemiUserListInModal1">
            <option v-for="member in memberNameList" :key="member">{{ member }}</option>
          </datalist>
          <b-row v-if="editingRecord.player1Kyu != '' || editingRecord.player1Dan != ''" class="danRow">
            <b-col class="mx-0 px-0 danCol d-flex align-items-center">
              <div class="my-auto align-middle" style="font-size: 16px; padding-left: 15px">
                {{ editingRecord.player1Kyu }}級{{ editingRecord.player1Dan }}段
              </div>
              <b-badge
                class="danBadge ml-auto"
                @click="
                  $bvModal.show('editRankAndDan2');
                  setEditParams(editingRecord.player1Name, 'player1');
                "
              >
                修正
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-form-input
            v-model="editingRecord.player2Name"
            placeholder="2人目"
            list="utsusemiUserListInModal2"
            autocomplete="off"
            @update="setUid(2, editingRecord.player2Name)"
          ></b-form-input>
          <datalist id="utsusemiUserListInModal2">
            <option v-for="member in memberNameList" :key="member">{{ member }}</option>
          </datalist>
          <b-row v-if="editingRecord.player2Kyu != '' || editingRecord.player2Dan != ''" class="danRow">
            <b-col class="mx-0 px-0 danCol d-flex align-items-center">
              <div class="my-auto align-middle" style="font-size: 16px; padding-left: 15px">
                {{ editingRecord.player2Kyu }}級{{ editingRecord.player2Dan }}段
              </div>
              <b-badge
                class="danBadge ml-auto"
                @click="
                  $bvModal.show('editRankAndDan2');
                  setEditParams(editingRecord.player2Name, 'player2');
                "
              >
                修正
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col>
          <b-form-select
            key="input-result"
            v-model="editingRecord.player1Result"
            :options="resultOptions"
            @input="setResultFromPlayer1()"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-row>
            <validation-provider v-slot="{ errors }" name="cardNumber" rules="min_value:1|max_value:48">
              <b-form-input
                key="input-result"
                v-model="editingRecord.cardNumber"
                placeholder="枚数"
                type="number"
              ></b-form-input>
              <p class="error margin-bottom:0">{{ errors[0] }}</p>
            </validation-provider>
          </b-row>
          <b-row>
            <b-form-checkbox v-model="editingRecord.is40cardsKaruta">40枚</b-form-checkbox>
          </b-row>
        </b-col>
        <b-col>
          <b-form-select
            key="input-result"
            v-model="editingRecord.player2Result"
            :options="resultOptions"
            @input="setResultFromPlayer2()"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="練習会の分類">
            <b-form-input v-model="editingRecord.practiceType" list="practiceTypeList"></b-form-input>
          </b-form-group>
          <datalist id="practiceTypeList">
            <option v-for="practiceType in practiceTypeList" :key="practiceType">{{ practiceType }}</option>
          </datalist>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="success"
            class="my-2 mx-2 float-right"
            :disabled="ObserverProps.invalid"
            size="sm"
            @click="updateRecord()"
          >
            更新
          </b-button>
          <b-button variant="outline-danger" class="my-2 mx-2 float-right" size="sm" @click="$bvModal.show('remove')">
            削除
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    <hr />
    <b-row>
      <b-col>
        <div v-for="(value, key) in comments" :key="key">
          <CommentItem
            v-if="value.commentType == 'public'"
            :comment="{ ...value, id: key }"
            :user-name="userName"
            :comments-replica="commentsReplica"
            :comment-visibility="'(公開)'"
            :set-remove-comment-key="setRemoveCommentKey"
            :update-comment="updateComment"
            @update-text="updateText"
          />
          <CommentItem
            v-if="value.commentType == 'private' && value.name == userName"
            :comment="{ ...value, id: key }"
            :user-name="userName"
            :comments-replica="commentsReplica"
            :comment-visibility="'(自分のみ)'"
            :set-remove-comment-key="setRemoveCommentKey"
            :update-comment="updateComment"
            @update-text="updateText"
          />
          <CommentItem
            v-if="value.commentType == 'seniorMemberOnly' && userEnterYear <= value.upperLimitOfViewersEnterYear"
            :comment="{ ...value, id: key }"
            :user-name="userName"
            :comments-replica="commentsReplica"
            :comment-visibility="'(' + (fiscalYear - value.upperLimitOfViewersEnterYear + 1) + '年生以上のみ)'"
            :set-remove-comment-key="setRemoveCommentKey"
            :update-comment="updateComment"
            @update-text="updateText"
          />
          <CommentItem
            v-if="value.commentType == 'directMessage' && (value.name === userName || value.towardUserId === user.uid)"
            :comment="{ ...value, id: key }"
            :user-name="userName"
            :comments-replica="commentsReplica"
            :comment-visibility="'(DM)'"
            :set-remove-comment-key="setRemoveCommentKey"
            :update-comment="updateComment"
            @update-text="updateText"
          ></CommentItem>
        </div>
      </b-col>
    </b-row>
    <hr v-if="comments" />
    <validation-observer ref="obs" v-slot="ObserverProps">
      <b-row>
        <b-col>
          <b-form-group label-cols="4" content-cols="8" label="公開設定" label-for="commentType">
            <b-form-select id="commentType" v-model="commentType" :options="commentTypeOptions"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="commentType == 'seniorMemberOnly'" class="mb-2">
        <b-col cols="12">
          <b-form-group
            label-cols="4"
            content-cols="8"
            label="何年生以上？"
            label-for="lowerLimitOfViewersSchoolGrade"
            :description="`${
              fiscalYear - lowerLimitOfViewersSchoolGrade + 1
            }年度か、それより前に入会した人が見られます`"
          >
            <validation-provider v-slot="{ errors }" name="学年" rules="min_value_of_grade:2">
              <b-form-input
                id="lowerLimitOfViewersSchoolGrade"
                v-model="lowerLimitOfViewersSchoolGrade"
                type="number"
                style="max-width: 50px; padding: 0.375rem"
                min="2"
                :max="fiscalYear - userEnterYear + 1"
              ></b-form-input>

              <p class="error">
                <small>{{ errors[0] }}</small>
              </p>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="comment" rules="required">
            <b-textarea v-model="comment" rows="3" max-rows="20" @input="saveCommentInLocalStorage"></b-textarea>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-b-toggle.comment
            variant="success"
            class="my-2 ml-auto mr-2 float-right"
            size="sm"
            :disabled="ObserverProps.invalid"
            @click="sendComment()"
          >
            送信
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row>
      <b-col>
        <div v-if="editingRecord.player1Uid !== ''" class="text-center">
          <router-link
            :to="{
              name: 'individualRecords',
              query: { userId: editingRecord.player1Uid, userName: editingRecord.player1Name },
            }"
          >
            <b-button variant="outline-info" class="my-2 mx-2" size="sm" @click="$bvModal.hide('edit')">
              {{ editingRecord.player1Name }}さんの他の記録を見る
            </b-button>
          </router-link>
        </div>
      </b-col>
      <b-col>
        <div v-if="editingRecord.player2Uid !== ''" class="text-center">
          <router-link
            :to="{
              name: 'individualRecords',
              query: { userId: editingRecord.player2Uid, userName: editingRecord.player2Name },
            }"
          >
            <b-button variant="outline-info" class="my-2 mx-2" size="sm" @click="$bvModal.hide('edit')">
              {{ editingRecord.player2Name }}さんの他の記録を見る
            </b-button>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <div v-if="modalName.includes('editModalFromCommentTable')" class="ml-auto">
        <b-button variant="outline-secondary" class="my-2 mx-auto" size="sm" @click="$bvModal.hide(modalName)">
          閉じる
        </b-button>
      </div>
      <div v-else class="ml-auto">
        <b-button variant="outline-secondary" class="my-2 mx-auto" size="sm" @click="$bvModal.hide('edit')">
          閉じる
        </b-button>
      </div>
    </b-row>

    <b-modal :id="'remove'" hide-header hide-footer modal-center>
      <b-row>
        <b-col>
          <label>記録を削除します。よろしいですか？</label>
        </b-col>
      </b-row>
      <b-row class="float-right">
        <b-col>
          <b-button variant="secondary" class="my-2 mx-2" size="sm" @click="$bvModal.hide('remove')">やめる</b-button>
          <b-button variant="outline-danger" class="my-2 mx-2" size="sm" @click="remove()">記録を削除</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal :id="'removeComment'" hide-header hide-footer modal-center>
      <b-row>
        <b-col>
          <label>コメントを削除します。よろしいですか？</label>
        </b-col>
      </b-row>
      <b-row class="float-right">
        <b-col>
          <b-button variant="secondary" class="my-2 mx-2" size="sm" @click="$bvModal.hide('removeComment')">
            やめる
          </b-button>
          <b-button
            variant="outline-danger"
            class="my-2 mx-2"
            size="sm"
            @click="
              removeComment();
              $bvModal.hide('removeComment');
            "
          >
            コメントを削除
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal :id="'editRankAndDan2'" hide-header hide-footer modal-center>
      <b-row>
        <b-col>
          <label>{{ memberEditParams2.name }}さんの段と級を変更します。</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form inline>
            <b-form-select v-model="memberEditParams2.rank" :options="rankOptions"></b-form-select>
            <label for="inline-form-input-kyu">級</label>
            <b-form-select v-model="memberEditParams2.dan" :options="danOptions"></b-form-select>
            <label for="inline-form-input-dan">段</label>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="success"
            class="my-2 mx-2 float-right"
            size="sm"
            @click="
              editMeiboMember(memberEditParams2);
              $bvModal.hide('editRankAndDan2');
            "
          >
            変更
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { getDatabase, ref, onValue, update, set, push } from "firebase/database";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import { ValidationProvider, extend } from "vee-validate";
import { required, min_value, max_value } from "vee-validate/dist/rules";
extend("required", required);
extend("min_value_of_grade", {
  ...min_value,
  message: "学年は2以上の値を入力してください",
});
extend("min_value", {
  ...min_value,
  message: "枚数は1以上の値を入力してください",
});
extend("max_value", {
  ...max_value,
  message: "枚数は48以下の値を入力してください",
});
import OPTIONS from "../constants/options.js";
import CommentItem from "./CommentItem.vue";

export default {
  name: "RecordComponent",
  components: {
    ValidationProvider,
    CommentItem,
  },
  props: {
    record: { type: Object, default: () => {} },
    modalName: { type: String, default: "" },
    practiceTypeList: { type: Array, default: () => {} },
    user: { type: Object, default: () => {} },
  },
  data() {
    return {
      round: "",
      date: "",
      unixtime: "",
      editingRecord: {},
      memberNameList: [],
      memberKanaList: [],
      utsusemiUserList: [],
      meiboHash: {},
      resultOptions: OPTIONS.RESULT_OPTIONS,
      roundOptions: OPTIONS.ROUND_OPTIONS,
      comment: "",
      comments: new Object(),
      commentsReplica: new Object(),
      editingComment: new Object(),
      memberEditParams2: {
        name: "test",
      },
      rankOptions: OPTIONS.RANK_OPTIONS,
      danOptions: OPTIONS.DAN_OPTIONS,
      removeCommentKey: "",
      userName: "",
      userEnterYear: "",
      commentType: "public",
      commentTypeOptions: [],
      fiscalYear: "",
      lowerLimitOfViewersSchoolGrade: 2,
    };
  },
  created: function () {
    var _this = this;
    if (this.round === "") {
      this.round = this.record.round;
    }
    if (this.date === "") {
      this.unixtime = -dayjs(this.record.date).unix();
      this.date = this.record.date;
    }

    this.editingRecord = JSON.parse(JSON.stringify(this.record));

    const database = getDatabase();
    const userRef = ref(database, "users/" + this.user.uid);
    onValue(userRef, (snapshot) => {
      if (snapshot) {
        const user = snapshot.val();
        _this.userName = user.name;
        _this.userEnterYear = user.enterYear;
      }
    });

    // 空蝉ユーザーの取得
    const usersRef = ref(database, "users");
    onValue(usersRef, (snapShot) => {
      if (snapShot) {
        _this.utsusemiUserList = [];
        snapShot.forEach((data) => {
          let user = data.val();
          user["uid"] = data.key;
          if (user.authorized) {
            _this.utsusemiUserList.push(user);
          }
        });
      }
    });

    // 名簿の取得
    const meiboRef = ref(database, "meibo");
    onValue(meiboRef, (snapShot) => {
      if (snapShot) {
        snapShot.forEach((data) => {
          let meiboMemberByYear = data.val();
          for (let member in meiboMemberByYear) {
            meiboMemberByYear[member]["year"] = data.key;
          }
          _this.meiboHash = Object.assign(_this.meiboHash, meiboMemberByYear);
        });
      }
    });
    _this.meiboHash = this.sortMeibo();
    _this.memberNameList = Object.keys(this.meiboHash);

    this.fetchComments(this.unixtime, this.round); // コメントをセット

    let now = dayjs();
    this.fiscalYear = this.getFiscalYear(now.unix());

    // この練習記録に対してどのタイプのコメントができるかを設定
    // OPTIONS.COMMENT_TYPE_OPTIONSをディープコピー
    this.commentTypeOptions = JSON.parse(JSON.stringify(OPTIONS.COMMENT_TYPE_OPTIONS));

    // 上級生の場合は、上級生のみのコメントを追加
    if (this.userEnterYear < this.fiscalYear) {
      this.commentTypeOptions.push({
        value: "seniorMemberOnly",
        text: "上級生のみ",
      });
    }

    // this.user.uidがthis.record.player1Uidまたはthis.record.player2Uidに等しい場合、DMを追加
    if (this.canSendDirectMessage()) {
      this.commentTypeOptions.push({
        value: "directMessage",
        text: "DM",
      });
    }

    // コメントがlocalStorageに保存されていたら復元
    if (localStorage.getItem(this.record.key)) {
      this.comment = localStorage.getItem(this.record.key);
    }
  },
  methods: {
    // プレイヤー1の結果が入力された場合にプレイヤー2の結果を自動補完する
    setResultFromPlayer1() {
      if ([0, 1].includes(this.editingRecord.player1Result)) {
        this.editingRecord.player2Result = 1 - this.editingRecord.player1Result;
        return;
      }
      this.editingRecord.player2Result = this.editingRecord.player1Result;
    },
    // プレイヤー2の結果が入力された場合にプレイヤー1の結果を自動補完する
    setResultFromPlayer2() {
      if ([0, 1].includes(this.editingRecord.player2Result)) {
        this.editingRecord.player1Result = 1 - this.editingRecord.player2Result;
        return;
      }
      this.editingRecord.player1Result = this.editingRecord.player2Result;
    },
    setUid(playerSide, playerName) {
      //アプリ利用者の場合、Uidを設定する
      for (let i = 0, l = this.utsusemiUserList.length; i < l; i++) {
        if (playerName == this.utsusemiUserList[i].name) {
          if (playerSide == 1) {
            this.editingRecord.player1Uid = this.utsusemiUserList[i].uid;
          } else {
            this.editingRecord.player2Uid = this.utsusemiUserList[i].uid;
          }
          break;
        } else {
          if (playerSide == 1) {
            this.editingRecord.player1Uid = "";
          } else {
            this.editingRecord.player2Uid = "";
          }
        }
      }

      if (this.memberNameList.includes(playerName)) {
        if (playerSide == 1) {
          this.editingRecord.player1Kyu = this.meiboHash[playerName].kyu;
          this.editingRecord.player1Dan = this.meiboHash[playerName].dan;
        } else {
          this.editingRecord.player2Kyu = this.meiboHash[playerName].kyu;
          this.editingRecord.player2Dan = this.meiboHash[playerName].dan;
        }
      } else {
        if (playerSide == 1) {
          this.editingRecord.player1Kyu = "";
          this.editingRecord.player1Dan = "";
        } else {
          this.editingRecord.player2Kyu = "";
          this.editingRecord.player2Dan = "";
        }
      }
    },
    updateRecord() {
      let round = this.editingRecord.round;
      let unixtime = -dayjs(this.editingRecord.date).unix();
      let oldRound = this.round;
      let oldUnixtime = -dayjs(this.date).unix();

      const database = getDatabase();
      const recordsPostRef = ref(database, "records/" + unixtime + "/" + round + "/" + this.editingRecord["key"]);

      // コメント消える現象の対応（updateでコメントが上書き消去されている仮説、間違っているかも）
      onValue(recordsPostRef, (snapshot) => {
        const record = snapshot.val();
        if (record.comments) this.editingRecord["comments"] = record.comments;
      });
      update(recordsPostRef, this.editingRecord);

      //プレイヤーが変わった場合、変更前のプレイヤーの個人記録を削除する
      if (this.record.player1Uid !== "" && this.editingRecord.player1Uid !== this.record.player1Uid) {
        const oldUserRecordsPostRefForPlayer1 = ref(
          database,
          "userRecords/" + this.record.player1Uid + "/" + oldUnixtime + "/" + oldRound + "/" + this.editingRecord["key"]
        );
        set(oldUserRecordsPostRefForPlayer1, null);
      }
      if (this.record.player2Uid !== "" && this.editingRecord.player2Uid !== this.record.player2Uid) {
        const oldUserRecordsPostRefForPlayer2 = ref(
          database,
          "userRecords/" + this.record.player2Uid + "/" + oldUnixtime + "/" + oldRound + "/" + this.editingRecord["key"]
        );
        set(oldUserRecordsPostRefForPlayer2, null);
      }

      //プレイヤー1の個人記録の更新
      if (this.editingRecord.player1Uid !== "") {
        const userRecordsPostRefForPlayer1 = ref(
          database,
          "userRecords/" +
            this.editingRecord.player1Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"]
        );
        let player1Obj = {
          date: this.editingRecord.date,
          round: this.editingRecord.round,
          opponent: this.editingRecord.player2Name,
          opponentId: this.editingRecord.player2Uid,
          opponentKyu: this.editingRecord.player2Kyu,
          opponentDan: this.editingRecord.player2Dan,
          result: this.editingRecord.player1Result,
          key: this.editingRecord["key"],
          cardNumber: this.editingRecord["cardNumber"],
          is40cardsKaruta: this.editingRecord["is40cardsKaruta"] ? this.editingRecord["is40cardsKaruta"] : false,
        };
        if (this.editingRecord["comments"]) player1Obj["comments"] = this.editingRecord["comments"];
        update(userRecordsPostRefForPlayer1, player1Obj);
      }

      //プレイヤー2の個人記録の更新
      if (this.editingRecord.player2Uid !== "") {
        const userRecordsPostRefForPlayer2 = ref(
          database,
          "userRecords/" +
            this.editingRecord.player2Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"]
        );
        let player2Obj = {
          date: this.editingRecord.date,
          round: this.editingRecord.round,
          opponent: this.editingRecord.player1Name,
          opponentId: this.editingRecord.player1Uid,
          opponentKyu: this.editingRecord.player1Kyu,
          opponentDan: this.editingRecord.player1Dan,
          result: this.editingRecord.player2Result,
          key: this.editingRecord["key"],
          cardNumber: this.editingRecord["cardNumber"],
          is40cardsKaruta: this.editingRecord["is40cardsKaruta"] ? this.editingRecord["is40cardsKaruta"] : false,
        };
        if (this.editingRecord["comments"]) player2Obj["comments"] = this.editingRecord["comments"];
        update(userRecordsPostRefForPlayer2, player2Obj);
      }

      //日付または回戦が変わった場合、元のデータを削除する
      if (this.editingRecord.round !== this.record.round || this.editingRecord.date !== this.record.date) {
        if (this.editingRecord.player1Uid !== "") {
          const userRecordsPostRefForPlayer1 = ref(
            database,
            "userRecords/" +
              this.editingRecord.player1Uid +
              "/" +
              oldUnixtime +
              "/" +
              oldRound +
              "/" +
              this.editingRecord["key"]
          );
          set(userRecordsPostRefForPlayer1, null);
        }
        if (this.editingRecord.player2Uid !== "") {
          const userRecordsPostRefForPlayer2 = ref(
            database,
            "userRecords/" +
              this.editingRecord.player2Uid +
              "/" +
              oldUnixtime +
              "/" +
              oldRound +
              "/" +
              this.editingRecord["key"]
          );
          set(userRecordsPostRefForPlayer2, null);
        }
        this.round = this.editingRecord.round;
        this.date = this.editingRecord.date;
        this.unixtime = -dayjs(this.editingRecord.date).unix();
        const oldRecordsPostRef = ref(
          database,
          "records/" + oldUnixtime + "/" + oldRound + "/" + this.editingRecord["key"]
        );
        set(oldRecordsPostRef, null);
      }

      if (this.modalName) {
        this.$bvModal.hide(this.modalName);
      } else {
        this.$bvModal.hide("edit");
      }
    },
    remove() {
      let round = this.editingRecord.round;
      let unixtime = -dayjs(this.editingRecord.date).unix();
      const database = getDatabase();
      const recordsPostRef = ref(database, "records/" + unixtime + "/" + round + "/" + this.editingRecord["key"]);
      set(recordsPostRef, null);
      //userRecordsの更新の処理を書く
      if (this.editingRecord.player1Uid !== "") {
        const userRecordsPostRefForPlayer1 = ref(
          database,
          "userRecords/" +
            this.editingRecord.player1Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"]
        );
        set(userRecordsPostRefForPlayer1, null);
      }
      if (this.editingRecord.player2Uid !== "") {
        const userRecordsPostRefForPlayer2 = ref(
          database,
          "userRecords/" +
            this.editingRecord.player2Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"]
        );
        set(userRecordsPostRefForPlayer2, null);
      }
      //これがrecords/unixtime/round/の下の唯一の記録であれば、placeInfoだけ残ってしまうので消去する
      const roundRef = ref(database, `records/${unixtime}/${round}`);
      onValue(
        roundRef,
        (snapShot) => {
          if (snapShot.val()) {
            let keys = Object.keys(snapShot.val());
            if (keys.length == 1 && keys[0] == "placeInfo") {
              const placeInfoRef = ref(database, `records/${unixtime}/${round}`);
              set(placeInfoRef, null);
            }
          }
        },
        { onlyOnce: true }
      );
      this.$bvModal.hide("remove");
      if (this.modalName) {
        this.$bvModal.hide(this.modalName);
      } else {
        this.$bvModal.hide("edit");
      }
    },
    fetchComments(unixtime, round) {
      let _this = this;
      const database = getDatabase();
      const commentsRef = ref(database, `records/${unixtime}/${round}/${this.editingRecord["key"]}/comments`);
      onValue(commentsRef, (snapShot) => {
        if (snapShot) {
          _this.comments = snapShot.val();
          _this.commentsReplica = JSON.parse(JSON.stringify(_this.comments));
        }
      });
    },
    sendComment() {
      let round = this.editingRecord.round;
      let unixtime = -dayjs(this.editingRecord.date).unix();
      let comment = {
        text: this.comment,
        unixtime: dayjs().unix(),
        name: this.userName,
        commentType: this.commentType,
        upperLimitOfViewersEnterYear: this.fiscalYear - this.lowerLimitOfViewersSchoolGrade + 1,
      };

      // DMの場合、towardUserIdを設定する
      if (this.commentType == "directMessage") {
        if (this.editingRecord.player1Uid === this.user.uid) {
          comment["towardUserId"] = this.editingRecord.player2Uid;
        } else {
          comment["towardUserId"] = this.editingRecord.player1Uid;
        }
      }

      const database = getDatabase();

      // recordsにコメントを投稿し、key(id)を取得
      const commentPostRef = ref(
        database,
        "records/" + unixtime + "/" + round + "/" + this.editingRecord["key"] + "/comments"
      );
      const commentId = push(commentPostRef, comment).key;

      // コメントが消えるバグの対策としてバックアップする（createだけ行う）
      if (comment.commentType == "public") {
        const commentBackupPostRef = ref(database, "commentBackup/");
        push(commentBackupPostRef, comment);
      }

      // プレイヤー1の個人記録にコメントを投稿
      if (this.editingRecord.player1Uid !== "") {
        const userRecordsPostRefForPlayer1 = ref(
          database,
          "userRecords/" +
            this.editingRecord.player1Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"] +
            "/comments/" +
            commentId
        );
        update(userRecordsPostRefForPlayer1, comment);
      }

      // プレイヤー2の個人記録にコメントを投稿
      if (this.editingRecord.player2Uid !== "") {
        const userRecordsPostRefForPlayer2 = ref(
          database,
          "userRecords/" +
            this.editingRecord.player2Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"] +
            "/comments/" +
            commentId
        );
        update(userRecordsPostRefForPlayer2, comment);
      }

      this.fetchComments(unixtime, round);
      this.comment = "";
      localStorage.removeItem(this.record.key);
    },
    setEditingComment(key, value) {
      this.editingComment[key] = value.text;
    },
    updateComment(key) {
      let commentKey = key.replace("comments", "");
      let round = this.editingRecord.round;
      let unixtime = -dayjs(this.editingRecord.date).unix();

      const database = getDatabase();
      const commentUpdateRef = ref(
        database,
        "records/" + unixtime + "/" + round + "/" + this.editingRecord["key"] + "/comments/" + commentKey
      );
      let textObject = {
        text: this.commentsReplica[commentKey]["text"],
      };
      update(commentUpdateRef, textObject);

      // コメントが消えるバグの対策としてバックアップする（createだけ行う）
      if (this.commentsReplica[commentKey].commentType == "public") {
        const commentBackupPostRef = ref(database, "commentBackup/");
        push(commentBackupPostRef, this.commentsReplica[commentKey]);
      }

      // プレイヤー1の個人記録にコメントを投稿
      if (this.editingRecord.player1Uid !== "") {
        const player1CommentUpdateRef = ref(
          database,
          "userRecords/" +
            this.editingRecord.player1Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"] +
            "/comments/" +
            commentKey
        );
        update(player1CommentUpdateRef, textObject);
      }

      // プレイヤー2の個人記録にコメントを投稿
      if (this.editingRecord.player2Uid !== "") {
        const player2CommentUpdateRef = ref(
          database,
          "userRecords/" +
            this.editingRecord.player2Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"] +
            "/comments/" +
            commentKey
        );
        update(player2CommentUpdateRef, textObject);
      }

      this.fetchComments(unixtime, round);
    },
    setRemoveCommentKey(key) {
      this.removeCommentKey = key;
    },
    removeComment() {
      if (this.removeCommentKey === "") return;
      let commentKey = this.removeCommentKey.replace("comments", "");
      let round = this.editingRecord.round;
      let unixtime = -dayjs(this.editingRecord.date).unix();
      const database = getDatabase();
      const commentRemoveRef = ref(
        database,
        "records/" + unixtime + "/" + round + "/" + this.editingRecord["key"] + "/comments/" + commentKey
      );
      set(commentRemoveRef, null);
      if (this.editingRecord.player1Uid !== "") {
        const player1CommentRemoveRef = ref(
          database,
          "userRecords/" +
            this.editingRecord.player1Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"] +
            "/comments/" +
            commentKey
        );
        set(player1CommentRemoveRef, null);
      }
      if (this.editingRecord.player2Uid !== "") {
        const player2CommentRemoveRef = ref(
          database,
          "userRecords/" +
            this.editingRecord.player2Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"] +
            "/comments/" +
            commentKey
        );
        set(player2CommentRemoveRef, null);
      }
      this.fetchComments(unixtime, round);
    },
    sortMeibo() {
      let meiboArray = new Array();
      for (let person in this.meiboHash) {
        let kana = this.meiboHash[person].kana;
        let personObj = {
          kana: kana,
          name: person,
          value: this.meiboHash[person],
        };
        meiboArray.push(personObj);
      }
      meiboArray.sort(function (a, b) {
        if (a.kana < b.kana) {
          return -1;
        } else {
          return 1;
        }
      });
      let meiboObj = new Object();
      for (let i in meiboArray) {
        meiboObj[meiboArray[i].name] = meiboArray[i].value;
      }
      return meiboObj;
    },
    setEditParams(name, playerSide) {
      this.memberEditParams2.name = name;
      this.memberEditParams2.rank = this.meiboHash[name].kyu;
      this.memberEditParams2.dan = this.meiboHash[name].dan;
      this.memberEditParams2.year = this.meiboHash[name].year;
      this.memberEditParams2.playerSide = playerSide;
    },
    editMeiboMember(memberEditParams2) {
      let kyu = memberEditParams2.rank;
      let dan = memberEditParams2.dan;
      const database = getDatabase();
      const meiboDetailRef = ref(database, "meibo/" + memberEditParams2["year"] + "/" + memberEditParams2["name"]);
      update(meiboDetailRef, { dan: dan, kyu: kyu });

      let round = this.editingRecord.round;
      let unixtime = -dayjs(this.editingRecord.date).unix();
      if (memberEditParams2.playerSide === "player1") {
        const recordRef = ref(database, "records/" + unixtime + "/" + round + "/" + this.editingRecord["key"]);
        update(recordRef, {
          player1Dan: dan,
          player1Kyu: kyu,
        });
        const player2recordRef = ref(
          database,
          "userRecords/" +
            this.editingRecord.player2Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"]
        );
        update(player2recordRef, {
          opponentDan: dan,
          opponentKyu: kyu,
        });
      } else {
        const recordRef = ref(database, "records/" + unixtime + "/" + round + "/" + this.editingRecord["key"]);
        update(recordRef, {
          player2Dan: dan,
          player2Kyu: kyu,
        });
        const player1recordRef = ref(
          database,
          "userRecords/" +
            this.editingRecord.player1Uid +
            "/" +
            unixtime +
            "/" +
            round +
            "/" +
            this.editingRecord["key"]
        );
        update(player1recordRef, {
          opponentDan: dan,
          opponentKyu: kyu,
        });
      }
    },
    getFiscalYear(unixtime) {
      let year = dayjs(unixtime * 1000).format("YYYY");
      let month = dayjs(unixtime * 1000).format("M");
      if (month < 4) return year - 1;
      else return year;
    },
    saveCommentInLocalStorage() {
      if (this.comment == "" && localStorage.getItem(this.record.key)) {
        localStorage.removeItem(this.record.key);
        return;
      }
      localStorage.setItem(this.record.key, this.comment);
    },
    updateText({ id, text }) {
      this.commentsReplica[id]["text"] = text;
    },
    canSendDirectMessage() {
      // this.record.player1Uidが""またはthis.record.player2Uidが""の場合はfalseを返す
      if (this.record.player1Uid === "" || this.record.player2Uid === "") return false;

      // this.user.uidがthis.record.player1Uidにもthis.record.player2Uidにも一致しない場合はfalseを返す
      if (this.user.uid !== this.record.player1Uid && this.user.uid !== this.record.player2Uid) return false;

      return true;
    },
  },
};
</script>
