import Vue from "vue";
import VueHead from "vue-head";
import App from "./App.vue";

// Firebase Configuration
import firebaseConfig from "./config/firebaseConfig";
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
functions.region = "asia-northeast1";
const auth = getAuth(firebaseApp);
export { functions, auth, firebaseApp };

// BootstrapVue
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fas, far);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

// PWA Support
import "./registerServiceWorker";
Vue.use(VueHead);

// VeeValidate
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  computesRequired: true,
});
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// dayjs
import "dayjs/locale/ja";
import dayjs from "dayjs";
dayjs.locale("ja");

Vue.config.productionTip = false;

// Router
import router from "./router";
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
