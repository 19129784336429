export default Object.freeze({
    apiKey: "AIzaSyDigdYkAJwmTI2joJoTWuw3OJdLjSVAZJU",
    authDomain: "utkaruta-freshman-management.firebaseapp.com",
    databaseURL: "https://utkaruta-freshman-management-default-rtdb.firebaseio.com",
    projectId: "utkaruta-freshman-management",
    storageBucket: "utkaruta-freshman-management.appspot.com",
    messagingSenderId: "503438740408",
    appId: "1:503438740408:web:d9e7680f78d8ed67aa1d4d",
    measurementId: "G-EE661L25DQ"
});
