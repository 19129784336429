import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login.vue";
import Records from "./views/Records.vue";
import IndividualRecords from "./views/IndividualRecords.vue";
import Applying from "./views/Applying.vue";
import UserManage from "./views/UserManage.vue";
import Shokuhoe from "./views/Shokuhoe.vue";
import ShidoNoShido from "./views/ShidoNoShido.vue";
import EmailSetting from "./views/EmailSetting.vue";
import RecentlyUpdatedProfiles from "./views/RecentlyUpdatedProfiles.vue";
import CommentBackup from "./views/CommentBackup.vue";
import firebaseConfig from "./config/firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import PlayerList from "./views/PlayerList.vue";
import PlayerDetail from "./views/PlayerDetail.vue";

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const database = getDatabase();

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
      meta: { title: "ログイン | 空蝉" },
    },
    {
      path: "/applying",
      name: "applying",
      component: Applying,
      meta: { title: "利用申請 | 空蝉" },
    },
    {
      path: "/userManage",
      name: "userManage",
      component: UserManage,
      meta: { title: "ユーザー管理 | 空蝉" },
    },
    {
      path: "/records",
      name: "records",
      component: Records,
      meta: { title: "記録一覧 | 空蝉" },
    },
    {
      path: "/individualRecords",
      name: "individualRecords",
      component: IndividualRecords,
      meta: { title: "個人記録 | 空蝉" },
      props: true,
    },
    {
      path: "/recentlyUpdatedProfiles",
      name: "recentlyUpdatedProfiles",
      component: RecentlyUpdatedProfiles,
      meta: { title: "皆の目標 | 空蝉" },
      props: true,
    },
    {
      path: "/shokuhoe",
      name: "shokuhoe",
      component: Shokuhoe,
      meta: { title: "職域でほえろ！過去ログ | 空蝉" },
      props: true,
    },
    {
      path: "/shidoNoShido",
      name: "shidoNoShido",
      component: ShidoNoShido,
      meta: { title: "指導のための指導書 | 空蝉" },
      props: true,
    },
    {
      path: "/emailSetting",
      name: "emailSetting",
      component: EmailSetting,
      meta: { title: "メールアドレス設定 | 空蝉" },
      props: true,
    },
    {
      path: "/commentBackup",
      name: "commentBackup",
      component: CommentBackup,
      meta: { title: "コメントのバックアップ | 空蝉" },
      props: true,
    },
    {
      path: "/players",
      name: "PlayerList",
      component: PlayerList,
    },
    {
      path: "/players/:id",
      name: "PlayerDetail",
      component: PlayerDetail,
      props: true,
    },
    {
      path: "*",
      name: "others",
      component: Login,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve(async (to, _from, next) => {
  // ページタイトルの設定
  if (to.meta?.title) {
    document.title = to.meta.title;
  }

  // ログインページへのルーティング
  if (to.path === "/") {
    return next();
  }

  // ログインしているか確認
  const user = await new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    });
  });

  // 未ログインの場合はログインページへ
  if (!user) {
    return next({ path: "/" });
  }

  // 以下はログイン済であることが前提

  // 利用申請ページへのルーティング
  if (to.path === "/applying") {
    return next();
  }

  // ユーザー情報の取得
  const currentUserRef = ref(database, `users/${user.uid}`);
  const snapshot = await get(currentUserRef);

  // ユーザー情報が存在し、利用が許可されている場合は次のページへ
  if (snapshot.exists() && snapshot.val().authorized) {
    return next();
  }

  // 利用が許可されておらず、ユーザーのメールアドレスが~@~.ac.~または~@~.ed.~の場合は、利用禁止の旨を表示してログイン画面へ
  if (/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+(ac|ed)\.+.[A-Za-z0-9]+$/.test(user.email)) {
    alert(
      "大学や高校のメールアドレス(Googleアカウント)でのユーザー登録はできません。\n私用のGoogleアカウントを選択してください。"
    );
    auth.signOut();
    return;
  }

  // それ以外の場合は利用申請ページへ
  next("applying");
});

export default router;
