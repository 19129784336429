<template>
  <div class="container">
    <HeaderComponent />
    <b-table hover :items="commentList" :fields="fields" stacked>
      <template #table-caption>
        直近で投稿/更新があった50件を表示しています。
        バックアップに表示されているコメントを削除したい場合は、管理人に連絡をお願いします。
      </template>
      <template #cell(unixtime)="data">
        {{ unixtimeToDateTime(data.item.unixtime) }}
      </template>
      <template #cell(name)="data">
        <span style="white-space: pre-line">{{ data.item.name }}</span>
      </template>
      <template #cell(text)="data">
        <span style="white-space: pre-line">{{ data.item.text }}</span>
      </template>
    </b-table>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { getDatabase, limitToLast, query, onValue, ref } from "firebase/database";
import HeaderComponent from "../components/Header.vue";

export default {
  name: "CommentBackup",
  components: { HeaderComponent },
  props: {},
  data() {
    return {
      fields: [
        {
          key: "unixtime",
          label: "投稿/更新日時",
        },
        {
          key: "name",
          label: "名前",
        },
        {
          key: "text",
          label: "本文",
        },
      ],
      commentList: [],
    };
  },
  watch: {},
  created: function () {
    const database = getDatabase();
    const commentBackupRef = query(ref(database, "commentBackup"), limitToLast(50));
    let _this = this;
    onValue(commentBackupRef, (snapshot) => {
      _this.commentList = [];
      snapshot.forEach((comment) => {
        let data = JSON.parse(JSON.stringify(comment));
        data.key = comment.key;
        _this.commentList.push(data);
      });
      _this.commentList = _this.commentList.reverse();
    });
  },
  methods: {
    unixtimeToDateTime(unixtime) {
      return dayjs(unixtime * 1000).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
<style></style>
