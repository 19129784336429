<template>
  <div class="container">
    <HeaderComponent />
    <div class="text-center">
      <div v-if="isSearchOptionOpen == false">
        <b-button v-b-toggle.search-option variant="success" class="my-2 mx-2" size="sm">
          <font-awesome-icon icon="chevron-right" />
          検索条件を開く
        </b-button>
      </div>
      <div v-else>
        <b-button v-b-toggle.search-option variant="success" class="my-2 mx-2" size="sm">
          <font-awesome-icon icon="chevron-down" />
          検索条件を閉じる
        </b-button>
      </div>
    </div>
    <b-collapse id="search-option" v-model="isSearchOptionOpen">
      <b-card>
        <b-row>
          <b-col style="padding-left: 0px">
            <b-form-group label="名前">
              <b-form-select
                v-model="columnOfNamesFirstLetter"
                class="mx-2"
                :options="aiueOptions"
                @change="
                  clearYear();
                  filterUser();
                "
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col style="padding-left: 0px">
            <b-form-group label="入学年度">
              <b-form-select v-model="year" class="mx-2" :options="yearOptions" @change="filterUser"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form></b-form>
        <hr />
        <div v-if="filteredUserList.length == 0">
          <p class="text-center">該当のユーザーは見つかりませんでした。</p>
        </div>
        <div v-else>
          <b-row>
            <b-col v-for="(filteredUser, index) in filteredUserList" :key="index" cols="4" class="my-2 nameButton">
              <router-link
                :to="{ name: 'individualRecords', query: { userId: filteredUser.id, userName: filteredUser.name } }"
              >
                <b-button block class="px-0" variant="outline-info">{{ filteredUser.name }}</b-button>
              </router-link>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-collapse>
    <hr />
    <div v-if="dataAreaVisibility" id="dataArea">
      <Profile :user-id="selectedUserId" :current-user-id="uid"></Profile>
      <hr />
      <!-- 以下はGoogleアカウントの移行に失敗した人のデータを移植するためのボタン-->
      <!-- <b-button size="sm" variant="success" @click="updateRecords()">更新</b-button>
      <hr /> -->
      <b-row class="pb-2">
        <h5 class="my-auto">{{ selectedName }}さんの練習記録</h5>
        <b-button size="sm" variant="outline-info" class="ml-auto" @click="showKagetoraRecord()">
          <font-awesome-icon icon="external-link-alt" />
          景虎の記録
        </b-button>
      </b-row>
      <b-sidebar id="collapseSearch" v-model="collapseSearch" right shadow backdrop>
        <div>
          <validation-observer ref="obs" v-slot="ObserverProps">
            <b-row class="mb-2">
              <b-col class="col-12">
                <validation-provider name="field1" rules="required|compareStartDateAndEndDate:field2">
                  <div class="text-center">開始日</div>
                  <b-form-datepicker
                    v-model="searchParam.startDate"
                    name="field1"
                    lacale="ja"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                  ></b-form-datepicker>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col class="col-12">
                <validation-provider vid="field2" name="field2" rules="required">
                  <div class="text-center">終了日</div>
                  <b-form-datepicker
                    v-model="searchParam.endDate"
                    name="field2"
                    lacale="ja"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                  ></b-form-datepicker>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col class="col-12">
                <div class="text-center">対戦相手</div>
                <b-form-input v-model="searchParam.opponentName" name="opponentName" placeholder=""></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <div class="text-center">曜日</div>
                <div class="d-flex justify-content-center"></div>
                <b-form-checkbox-group v-model="searchParam.selectedDays" class="justify-content-center">
                  <b-form-checkbox
                    v-for="day in days"
                    :key="day.value"
                    :value="day.value"
                    button-variant="primary"
                    class="mx-2"
                  >
                    {{ day.text }}
                  </b-form-checkbox>
                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="mx-2"
                    @click="searchParam.selectedDays = [0, 1, 2, 3, 4, 5, 6]"
                  >
                    全選択
                  </b-button>
                  <b-button variant="outline-secondary" size="sm" class="mx-2" @click="searchParam.selectedDays = []">
                    全解除
                  </b-button>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col class="col-12">
                <div class="d-flex justify-content-end">
                  <b-button variant="success" class="my-2" size="sm" :disabled="ObserverProps.invalid" @click="search">
                    絞り込み実行
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <div class="text-right"></div>
          </validation-observer>
        </div>
      </b-sidebar>
      <div id="fieldsButtonGroup">
        <b-row>
          <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
            <b-form-radio-group
              v-model="selectedFieldsValue"
              size="sm"
              :options="fieldsOptions"
              button-variant="outline-primary"
              :aria-describedby="ariaDescribedby"
              buttons
              @change="changeShownFields"
            ></b-form-radio-group>
          </b-form-group>
          <b-button v-model="collapseSearch" v-b-toggle.collapseSearch variant="success" class="ml-auto" size="sm">
            <font-awesome-icon size="lg" :icon="['fas', 'sliders']" />
            絞り込み
          </b-button>
        </b-row>
      </div>
      <div v-if="dataList.length == 0">
        <p class="text-center py-2">条件に該当する練習記録がありません</p>
      </div>
      <div v-else class="py-2">
        <b-table
          v-for="(practiceDataArray, index) in dataList"
          :key="index"
          striped
          bordered
          hover
          :items="practiceDataArray.data"
          :fields="fields"
          caption-top
          @row-clicked="loadRecordAndShowModal"
        >
          <template #table-caption>
            <div v-if="index > 0">
              <hr />
            </div>
            {{ unixtimeToDate(practiceDataArray.date) }}
          </template>
          <template #[`cell(round)`]="data">
            <div class="text-center">
              {{ data.item.round }}
              <div v-if="data.item.is40cardsKaruta == true">
                <b-badge pill variant="secondary">40枚</b-badge>
              </div>
            </div>
          </template>
          <template #[`cell(opponent)`]="data">
            <div class="text-center">
              <div v-if="data.item.opponentId == ''">
                {{ data.item.opponent }}
                <font-awesome-icon size="sm" :icon="['fas', 'user-alt-slash']" />
              </div>
              <div v-else>
                <router-link
                  :to="{
                    name: 'individualRecords',
                    query: { userId: data.item.opponentId, userName: data.item.opponent },
                  }"
                >
                  {{ data.item.opponent }}
                </router-link>
              </div>
            </div>
          </template>
          <template #cell(comment)="data">
            <div
              v-if="data.item.comments != undefined"
              class="text-left"
              style="word-wrap: break-word; white-space: pre-line"
            >
              {{ showCommentName(showLatestComment(data.item.comments)) }}
              {{ unixtimeToDate(showCommentUnixtime(showLatestComment(data.item.comments))) }}
              <br />
              {{ showCommentText(showLatestComment(data.item.comments)) }}
            </div>
          </template>
        </b-table>
      </div>
      <b-modal :id="'edit'" hide-header hide-footer modal-center>
        <Record :record="focusRecord" modal-name="edit" :user="user" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "../components/Header.vue";
import Record from "../components/Record.vue";
import Profile from "../components/Profile.vue";
import { firebaseApp } from "../main";
import { getAuth } from "firebase/auth";
import {
  get,
  getDatabase,
  ref,
  onValue,
  off,
  orderByKey,
  orderByChild,
  query,
  startAt,
  endAt,
  update,
  set,
} from "firebase/database";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import OPTIONS from "../constants/options.js";

export default {
  name: "IndividualRecords",
  components: {
    HeaderComponent,
    Profile,
    Record,
  },
  data() {
    return {
      memberNameList: [],
      searchedName: "",
      result: {
        date: dayjs(new Date()).format("YYYY-MM-DD"),
        player1Name: "",
        player2Name: "",
        player1Result: "",
        player2Result: "",
        cardNumber: "",
        is40cardsKaruta: false,
      },
      nameList: [],
      filteredUserList: [],
      aiueOptions: [
        "指定なし",
        "あ行",
        "か行",
        "さ行",
        "た行",
        "な行",
        "は行",
        "ま行",
        "や行",
        "ら行",
        "わ行",
        "その他",
      ],
      columnOfNamesFirstLetter: "指定なし",
      yearOptions: [],
      year: "",
      gojuonhyo: {
        あ行: ["あ", "い", "う", "え", "お"],
        か行: ["か", "き", "く", "け", "こ", "が", "ぎ", "ぐ", "げ", "ご"],
        さ行: ["さ", "し", "す", "せ", "そ", "ざ", "じ", "ず", "ぜ", "ぞ"],
        た行: ["た", "ち", "つ", "て", "と", "だ", "ぢ", "づ", "で", "ど"],
        な行: ["な", "に", "ぬ", "ね", "の"],
        は行: ["は", "ひ", "ふ", "へ", "ほ", "ば", "び", "ぶ", "べ", "ぼ", "ぱ", "ぴ", "ぷ", "ぺ", "ぽ"],
        ま行: ["ま", "み", "む", "め", "も"],
        や行: ["や", "ゆ", "よ"],
        ら行: ["ら", "り", "る", "れ", "ろ"],
        わ行: ["わ"],
        その他: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
      },
      dataList: [],
      isSearchOptionOpen: true,
      dataAreaVisibility: false,
      currentQuery: "",
      selectedName: "",
      selectedUserId: "",
      selectedFieldsValue: "showResult",
      fieldsOptions: [
        {
          text: "勝敗",
          value: "showResult",
        },
        {
          text: "コメント",
          value: "showComment",
        },
      ],
      resultOptions: OPTIONS.RESULT_OPTIONS,
      recordList: [],
      fields: [
        {
          key: "round",
          label: "回戦",
          thClass: "text-center",
          tdClass: "text-center records-round",
        },
        {
          key: "opponent",
          label: "対戦相手",
          thClass: "text-center",
          tdClass: "text-center records-name",
        },
        {
          key: "result",
          label: "勝敗",
          formatter: "formatResultToShortText",
          thClass: "text-center",
          tdClass: "text-center records-result",
        },
        {
          key: "cardNumber",
          label: "枚数",
          thClass: "text-center",
          tdClass: "text-center records-cardNumber",
        },
      ],
      focusRecord: {},
      user: {},
      uid: "",
      KAGETORA_URL: "",
      KAGETORA_URL_BASE: "https://kagetora.bushidoo.com/result_record#show/",
      searchParam: {
        startDate: dayjs(dayjs().subtract(6, "month")).format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        opponentName: "",
        selectedDays: [0, 1, 2, 3, 4, 5, 6],
      },
      userRecordsRef: null,
      days: [
        { value: 1, text: "月" },
        { value: 2, text: "火" },
        { value: 3, text: "水" },
        { value: 4, text: "木" },
        { value: 5, text: "金" },
        { value: 6, text: "土" },
        { value: 0, text: "日" },
      ],
      collapseSearch: false,
    };
  },
  watch: {
    async $route(to) {
      if (to.query.userId) {
        this.getUserRecords(to.query.userId, to.query.userName);
        this.selectedUserId = to.query.userId;
        this.searchParam.opponentName = "";
        this.searchOff();
      } else {
        await this.fetchAndProcessAuthorizedUsers();
        this.isSearchOptionOpen = true;
        this.dataAreaVisibility = false;
      }
    },
    selectedUserId: function () {
      this.createKagetoraURL();
    },
  },
  beforeCreate() {},
  created: async function () {
    const auth = getAuth(firebaseApp);
    this.user = auth.currentUser;
    this.uid = auth.currentUser.uid;

    const database = getDatabase();
    const currentUserRef = ref(database, "users/" + this.uid);
    onValue(currentUserRef, (snapshot) => {
      if (!snapshot) return;
      snapshot.forEach((data) => {
        if (data.key == "email") return;
        this.user[data.key] = data.val();
      });
    });

    await this.fetchAndProcessAuthorizedUsers();

    this.filterUser();

    if (this.$route.query.userId) {
      this.isSearchOptionOpen = false;
      this.getUserRecords(this.$route.query.userId, this.$route.query.userName);
      this.selectedUserId = this.$route.query.userId;
      // this.searchOff();
    } else {
      this.isSearchOptionOpen = true;
      this.dataAreaVisibility = false;
    }
  },
  methods: {
    async updateRecords() {
      for (let i = 0; i < 20; i++) {
        await this.updateRecord();
      }
      alert("更新しました");
    },
    updateRecord() {
      const targetPlayerName = "東大太郎";
      const newUid = "hoge";
      const oldUid = "fuga";

      if (this.dataList.length == 0) return;
      const unixtime = this.dataList[0]["date"] * -1;
      const matchData = this.dataList[0]["data"][0];
      const round = matchData.round;

      const database = getDatabase();

      // recordsの更新処理
      const recordsPostRef = ref(database, "records/" + unixtime + "/" + round + "/" + matchData["key"]);
      let record = {};
      onValue(recordsPostRef, (snapshot) => {
        // snapshotの中身をconsole.logで表示
        record = snapshot.val();
      });

      if (record.player1Name == targetPlayerName) {
        record.player1Uid = newUid;
      } else {
        record.player2Uid = newUid;
      }
      update(recordsPostRef, record);

      // userRecordsの更新処理
      // 移行対象者側の更新処理
      const oldUserRecordsPostRef = ref(
        database,
        "userRecords/" + oldUid + "/" + unixtime + "/" + round + "/" + matchData["key"]
      );

      set(oldUserRecordsPostRef, null);

      const userRecordsPostRef = ref(
        database,
        "userRecords/" + newUid + "/" + unixtime + "/" + round + "/" + matchData["key"]
      );

      let userRecord = {
        date: matchData.date,
        round: matchData.round,
        opponent: matchData.opponent,
        opponentId: matchData.opponentId,
        opponentKyu: matchData.opponentKyu,
        opponentDan: matchData.opponentDan,
        result: matchData.result,
        key: matchData["key"],
        cardNumber: matchData["cardNumber"],
        is40cardsKaruta: matchData["is40cardsKaruta"] ? matchData["is40cardsKaruta"] : false,
      };

      if (matchData["comments"]) userRecord["comments"] = matchData["comments"];

      update(userRecordsPostRef, userRecord);

      // 対戦相手側の更新処理
      if (!matchData.opponentId) return;

      const opponentUserRecordPostRef = ref(
        database,
        "userRecords/" + matchData.opponentId + "/" + unixtime + "/" + round + "/" + matchData["key"]
      );

      update(opponentUserRecordPostRef, { opponentId: newUid });
    },

    async fetchAndProcessAuthorizedUsers() {
      const database = getDatabase();
      // kanaフィールドでソートされたユーザーリファレンスを取得
      const usersRef = query(ref(database, "users"), orderByChild("kana"));

      try {
        const snapshot = await get(usersRef);
        console.log("snapshot.size: ", snapshot.size);

        // リスト類を初期化
        this.memberNameList = [];
        this.nameList = [];
        const yearSet = new Set(["指定なし"]);

        snapshot.forEach((child) => {
          const userData = child.val();

          // 未認証ユーザーをスキップ
          if (!userData.authorized) return;

          // メンバー名リストに追加
          this.memberNameList.push(userData.name);

          // ユーザー詳細リストに追加
          this.nameList.push({
            id: child.key,
            name: userData.name,
            kana: userData.kana,
            firstLetter: userData.kana.charAt(0), // かなの最初の文字
            enterYear: userData.enterYear,
          });

          // 年度セットに追加（重複は自動的に除外される）
          yearSet.add(userData.enterYear + "年");
        });

        // 年度オプションを作成し、降順でソート
        this.yearOptions = Array.from(yearSet).sort((a, b) => b.localeCompare(a));

        // デフォルトで2番目の選択肢（最新の年度）を選択
        // "指定なし"が常に先頭にあるため、インデックス1が最新の年度となる
        this.year = this.yearOptions[1];
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    filterUser() {
      //FizzBuzzみたい
      var selectedFirstLetterList = this.gojuonhyo[this.columnOfNamesFirstLetter];
      var selectedEnterYear = this.year.slice(0, -1);
      if (this.columnOfNamesFirstLetter != "指定なし" && this.year != "指定なし") {
        //名前と入学年度の二重検索
        this.filteredUserList = this.nameList.filter(
          (id) => selectedFirstLetterList.includes(id["firstLetter"]) && id["enterYear"] == selectedEnterYear
        );
      } else if (this.columnOfNamesFirstLetter != "指定なし") {
        //名前のみで検索
        this.filteredUserList = this.nameList.filter((id) => selectedFirstLetterList.includes(id["firstLetter"]));
      } else if (this.year != "指定なし") {
        //入学年度のみで検索
        this.filteredUserList = this.nameList.filter((id) => id["enterYear"] == selectedEnterYear);
      } else {
        //どちらも全て。一応。
        this.filteredUserList = this.nameList;
      }
    },
    search() {
      this.getUserRecords(this.$route.query.userId, this.$route.query.userName);
      this.selectedUserId = this.$route.query.userId;
      this.searchOff();
    },
    getUserRecords(userID, userName) {
      //userIDを引数にしてuserRecordsから練習データを取得して並べる
      const startDate = dayjs(this.searchParam.startDate);
      const endDate = dayjs(this.searchParam.endDate);
      if (this.userRecordsRef) {
        off(this.userRecordsRef);
      }
      const database = getDatabase();
      const userRecordsRef = query(
        ref(database, "userRecords/" + userID),
        ...[orderByKey(), startAt(String(-endDate / 1000)), endAt(String(-startDate / 1000))]
      );
      this.userRecordsRef = userRecordsRef;
      this.currentQuery = userRecordsRef;
      this.selectedName = userName;
      let _this = this; //foreach中でのthisはwindowではないっぽい
      onValue(userRecordsRef, (snapshot) => {
        _this.dataList = [];
        snapshot.forEach((dataByDay) => {
          const dateKey = -dataByDay.key;

          // 検索条件に合致しない曜日のデータはスキップ
          if (!_this.searchParam.selectedDays.includes(dayjs(dateKey * 1000).day())) {
            return;
          }

          const dataHash = { date: dateKey, data: [] };
          const dayValues = dataByDay.val(); // dataByDay.val() の結果をキャッシュ

          Object.keys(dayValues).forEach((round) => {
            const roundData = dayValues[round];
            const id = Object.keys(roundData)[0]; // 最初のキーを取得
            const detail = roundData[id];

            // 詳細データを作成
            const detailHash = { round, id, ...detail };

            // 検索パラメータに基づいてデータをフィルタリング
            if (_this.searchParam.opponentName && !detailHash.opponent.includes(_this.searchParam.opponentName)) {
              return;
            }

            dataHash.data.push(detailHash);
          });

          // 有効なデータがあればリストに追加
          if (dataHash.data.length > 0) {
            _this.dataList.push(dataHash);
          }
        });
      });
      _this.dataAreaVisibility = true;
      this.collapseSearch = false;
    },
    searchOff() {
      this.isSearchOptionOpen = false;
    },
    loadRecordAndShowModal(record) {
      const oppositeOfUnixtime = -dayjs(record.date).unix();
      const database = getDatabase();
      let _this = this;
      const recordRef = ref(database, `records/${oppositeOfUnixtime}/${record.round}/${record.key}`);
      onValue(
        recordRef,
        (snapshot) => {
          if (snapshot) {
            _this.focusRecord = snapshot.val();
            _this.$bvModal.show("edit");
          } else {
            alert("データが見つかりませんでした。");
          }
        },
        { onlyOnce: true }
      );
    },
    formatResultToShortText(num) {
      switch (num) {
        case 1:
          return "○";
        case 0:
          return "×";
        case -1:
          return "指導";
        case -2:
          return "一人";
        case -3:
          return "T.U.";
        case "":
          return "";
      }
    },
    changeShownFields(value) {
      this.fields = [
        {
          key: "round",
          label: "回戦",
          thClass: "text-center",
          tdClass: "text-center records-round",
        },
        {
          key: "opponent",
          label: "対戦相手",
          thClass: "text-center",
          tdClass: "text-center records-name",
        },
      ];
      if (value == "showResult") {
        this.fields.push(
          {
            key: "result",
            label: "勝敗",
            formatter: "formatResultToShortText",
            thClass: "text-center",
            tdClass: "text-center records-result",
          },
          {
            key: "cardNumber",
            label: "枚数",
            thClass: "text-center",
            tdClass: "text-center records-cardNumber",
          }
        );
      } else {
        this.fields.push({
          key: "comment",
          label: "最新のコメント",
          thClass: "text-center",
          tdClass: "text-center",
        });
      }
    },
    createKagetoraURL() {
      this.KAGETORA_URL = this.KAGETORA_URL_BASE + this.selectedName;
    },
    showKagetoraRecord() {
      const url = this.KAGETORA_URL;
      window.open(url, "_blank");
    },
    clearYear() {
      this.year = "指定なし";
    },
    unixtimeToDate(unixtime) {
      return dayjs(unixtime * 1000).format("YYYY/MM/DD (dd)");
    },
    showLatestComment(comments) {
      let keys = Object.keys(comments);
      let latestComment = "";
      for (let i = keys.length - 1; i >= 0; i--) {
        let comment = comments[keys[i]];
        if ("commentType" in comment) {
          if (comment["commentType"] == "public") {
            latestComment = comment;
            break;
          }
        }
      }
      return latestComment;
    },
    showCommentText(comment) {
      if (comment === "") return "";
      else return comment["text"];
    },
    showCommentUnixtime(comment) {
      if (comment === "") return "";
      else return comment["unixtime"];
    },
    showCommentName(comment) {
      if (comment === "") return "";
      else return comment["name"] + ": ";
    },
  },
};
</script>
<style>
.resetButton {
  margin-right: 0.5rem !important;
  margin-left: auto !important;
}

.row {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.nameButton {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/*PCの場合*/
.pc {
  display: inline !important;
}

.mb {
  display: none !important;
}

@media screen and (max-width: 991px) {
  /*タブレット、スマホの場合*/
  .pc {
    display: none !important;
  }

  .mb {
    display: inline !important;
  }

  .container {
    margin-bottom: 64px;
  }
}

.table th,
.table td {
  padding: 0.25rem;
  vertical-align: middle;
}

.table td.records-result {
  width: 13%;
}

.table td.records-cardNumber {
  width: 13%;
}

.table td.records-round {
  width: 13%;
}
</style>
