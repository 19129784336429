var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-tabs',[_c('b-tab',{attrs:{"title":"試合回数（20位まで）"}},[_c('b-table',{attrs:{"items":_vm.participateRanking.filter((player) => player.rank <= 20),"fields":[
          { key: 'rank', label: '順位' },
          { key: 'name', label: '名前' },
          { key: 'count', label: '回数' },
        ]}})],1),_c('b-tab',{attrs:{"title":"コメント回数（10位まで）"}},[_c('b-table',{attrs:{"items":_vm.commentRanking.filter((player) => player.rank <= 10),"fields":[
          { key: 'rank', label: '順位' },
          { key: 'name', label: '名前' },
          { key: 'count', label: '回数' },
        ]}})],1),_c('b-tab',{attrs:{"title":"読み回数（10位まで）"}},[_c('b-table',{attrs:{"items":_vm.readingRanking.filter((player) => player.rank <= 10),"fields":[
          { key: 'rank', label: '順位' },
          { key: 'name', label: '名前' },
          { key: 'count', label: '回数' },
        ]}})],1),_c('b-tab',{attrs:{"title":"ありあけ/わすらもち押し回数（10位まで）"}},[_c('b-table',{attrs:{"items":_vm.operatingRanking.filter((player) => player.rank <= 10),"fields":[
          { key: 'rank', label: '順位' },
          { key: 'name', label: '名前' },
          { key: 'count', label: '回数' },
        ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }