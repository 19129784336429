<template>
  <div>
    <h5>{{ selectedUser.name }}さんのプロフィール</h5>
    <b-form-group label="段級位" class="mb-4">
      <b-form-select v-model="selectedUser.kyu" :options="rankOptions" style="width: auto" v-bind="buttonAttr" />
      <label for="inline-form-input-kyu" class="my-auto">級</label>
      <b-form-select
        v-model="selectedUser.dan"
        class="ml-2"
        :options="danOptions"
        style="width: auto"
        v-bind="buttonAttr"
      />
      <label for="inline-form-input-dan" class="my-auto">段</label>
    </b-form-group>
    <b-form-group label="プレースタイル" class="mb-4">
      <template v-if="userId == currentUserId" #description>
        現在のプレースタイルや、目標にしているプレースタイルを書きましょう。
        <br />
        新入生は追加で「これはできるようになってきた、これがまだできなくて困っている」あたりを書くとよいかも。
      </template>
      <b-form-textarea v-model="selectedUser.playStyle" rows="2" max-rows="8" v-bind="buttonAttr"></b-form-textarea>
    </b-form-group>
    <b-form-group label="現在の目標" class="mb-4">
      <template v-if="userId == currentUserId" #description>
        行動目標、結果目標および状態目標に分けて書くと分かりやすいです！
        <br />
        参考：
        <b-link href="https://note.com/tsukasa_gr/n/n640f072c46fc" target="_blank">
          https://note.com/tsukasa_gr/n/n640f072c46fc
        </b-link>
      </template>
      <b-form-textarea v-model="selectedUser.currentGoal" rows="2" max-rows="8" v-bind="buttonAttr"></b-form-textarea>
    </b-form-group>
    <b-form-group label="練習で意識していること" class="mb-4">
      <template v-if="userId == currentUserId" #description>
        沢山書いておくと、先輩が暗記時間中に読んでくれるかもしれません。
      </template>
      <b-form-textarea v-model="selectedUser.themeInPractice" rows="2" max-rows="8" v-bind="buttonAttr" />
    </b-form-group>
    <b-form-group v-if="userId == currentUserId">
      <b-button variant="success" size="sm" class="float-right" @click="updateUserProfile(userId)">更新</b-button>
    </b-form-group>
  </div>
</template>

<script>
import { getDatabase, ref, onValue, update } from "firebase/database";
import OPTIONS from "../constants/options.js";
import dayjs from "dayjs";

export default {
  name: "ProfileComponent",
  components: {},
  props: {
    userId: { type: String, default: "" },
    currentUserId: { type: String, default: "" },
  },
  data() {
    return {
      selectedUser: {},
      rankOptions: OPTIONS.RANK_OPTIONS,
      danOptions: OPTIONS.DAN_OPTIONS,
    };
  },
  computed: {
    buttonAttr() {
      return this.userId == this.currentUserId ? { disabled: false } : { disabled: true };
    },
  },
  watch: {
    userId: function () {
      this.selectUser(this.userId);
    },
  },
  created: function () {
    this.selectUser(this.userId);
  },
  methods: {
    selectUser(userId) {
      var _this = this;
      const database = getDatabase();
      const userProfileRef = ref(database, "users/" + userId);
      onValue(userProfileRef, (snapshot) => {
        _this.selectedUser = snapshot.val();
      });
    },
    updateUserProfile(userId) {
      const now = dayjs();
      this.selectedUser["lastUpdateDateOfProfile"] = now.unix();
      const database = getDatabase();
      update(ref(database, "users/" + userId), this.selectedUser);
      alert("プロフィールを更新しました。");
    },
  },
};
</script>
