// TODO: 環境変数化

const adminUsersEmail = [
  "cyowwwoyakitawwwotz@gmail.com",
  "8hototogisu1sherlockholmes@gmail.com",
  "sugam.kawa.14@gmail.com",
  "chienomaildesu@gmail.com",
];
export default Object.freeze({
  adminUsersEmail: adminUsersEmail,
});
