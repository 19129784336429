<template>
  <div class="container">
    <HeaderComponent />
    <h1>皆の目標</h1>
    <p>直近1ヶ月以内に更新された目標</p>
    <div v-for="(user, index) in profileUpdatedUserList" :key="index" class="pb-2">
      <b-card bg-variant="default" class="mb-2">
        <b-row class="mb-2" align-v="center">
          <h4 class="mb-0">{{ user.name }}</h4>
          <font-awesome-icon
            :icon="['fas', 'external-link']"
            class="ml-auto my-auto"
            style="cursor: pointer"
            @click="moveToIndividualRecords(user)"
          />
        </b-row>
        <h6 class="card-subtitle text-muted mb-2">{{ unixtimeToDateTime(user.lastUpdateDateOfProfile) }}</h6>
        <b-card-text style="word-wrap: break-word; white-space: pre-line" class="mt-4">
          <b>プレースタイル</b>
          <p>{{ user.playStyle === "" || !user.playStyle ? "-" : user.playStyle }}</p>
          <b>現在の目標</b>
          <p>{{ user.currentGoal === "" || !user.currentGoal ? "-" : user.currentGoal }}</p>
          <b>練習で意識していること</b>
          <p>{{ user.themeInPractice === "" || !user.themeInPractice ? "-" : user.themeInPractice }}</p>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { getDatabase, query, onValue, orderByChild, ref, startAt } from "firebase/database";
import HeaderComponent from "../components/Header.vue";

export default {
  name: "RecentlyUpdatedProfiles",
  components: { HeaderComponent },
  props: {},
  data() {
    return {
      profileUpdatedUserList: [],
    };
  },
  watch: {},
  created: function () {
    const database = getDatabase();

    // lastUpdateDateOfProfileの値が、現在時刻の1か月前のunixtimeよりも大きいデータを取得する
    const oneMonthAgoUnixtime = dayjs().subtract(1, "month").unix();
    const profileUpdatedUsersRef = query(
      ref(database, "users"),
      orderByChild("lastUpdateDateOfProfile"),
      startAt(oneMonthAgoUnixtime)
    );

    let _this = this;
    onValue(profileUpdatedUsersRef, (snapshot) => {
      _this.profileUpdatedUserList = [];
      snapshot.forEach((profileUpdatedUser) => {
        let user = JSON.parse(JSON.stringify(profileUpdatedUser));
        user.key = profileUpdatedUser.key;
        // user.playStyle, user.currentGoal, user.themeInPracticeの全てが設定されていない場合は、表示しない
        if (!user.playStyle && !user.currentGoal && !user.themeInPractice) {
          return;
        }
        _this.profileUpdatedUserList.push(user);
      });
      _this.profileUpdatedUserList = _this.profileUpdatedUserList.reverse();
    });
  },
  methods: {
    moveToIndividualRecords(user) {
      this.$router.push({ path: "/individualRecords", query: { userId: user.key, userName: user.name } });
    },
    unixtimeToDateTime(unixtime) {
      return dayjs(unixtime * 1000).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
<style></style>
