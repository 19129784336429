const RESULT_OPTIONS = [
  {
    value: "",
    text: "",
    shortText: "",
  },
  {
    value: 1,
    text: "勝ち",
    shortText: "○",
  },
  {
    value: 0,
    text: "負け",
    shortText: "×",
  },
  {
    value: -1,
    text: "指導",
    shortText: "指導",
  },
  {
    value: -2,
    text: "一人取り",
    shortText: "一人",
  },
  {
    value: -3,
    text: "タイムアップ",
    shortText: "T.U.",
  },
];
const ROUND_OPTIONS = [
  {
    value: 1,
    text: "1回戦",
  },
  {
    value: 2,
    text: "2回戦",
  },
  {
    value: 3,
    text: "3回戦",
  },
  {
    value: 4,
    text: "4回戦",
  },
  {
    value: 5,
    text: "5回戦",
  },
  {
    value: 6,
    text: "6回戦",
  },
  {
    value: 7,
    text: "7回戦",
  },
  {
    value: 8,
    text: "8回戦",
  },
  {
    value: 9,
    text: "9回戦",
  },
  {
    value: 10,
    text: "10回戦",
  },
  {
    value: 11,
    text: "11回戦",
  },
];

const RANK_OPTIONS = [
  {
    text: "未選択",
    value: "",
  },
  "A",
  "B",
  "C",
  "D",
  "E",
];

const DAN_OPTIONS = [
  {
    text: "未選択",
    value: "",
  },
  "10",
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2",
  "初",
  "無",
];

const COMMENT_TYPE_OPTIONS = [
  {
    value: "public",
    text: "公開",
  },
  {
    value: "private",
    text: "自分のみ",
  },
];

const PRACTICE_TYPE_OPTIONS = [
  {
    value: "uncategorized",
    text: "通常練",
  },
  {
    value: "beginner",
    text: "初心者練",
  },
  {
    value: "experienced",
    text: "経験者練",
  },
];

const READ_MODE_OPTIONS = [
  "",
  "読み",
  "ありあけ(西田)",
  "ありあけ(和家)",
  "ありあけ(吉川)",
  "ありあけ(稲葉)",
  "ありあけ(廣本)",
  "ありあけ(楠田)",
  "ありあけ(芹野)",
  "ありあけ(山下)",
  "ありあけ(五味)",
  "ありあけ(横谷)",
  "ありあけ(木村)",
  "わすらもち",
];

const PLACE_OPTIONS = [
  "",
  "和館",
  "かるた記念大塚会館",
  "白山東会館",
  "白山交流館",
  "大塚地域活動センター",
  "駕籠町会館",
  "芭蕉記念館",
  "用賀地区会館",
  "代田地区会館",
  "九品仏地区会館",
  "仲町区民集会所",
  "野毛区民集会所",
  "烏山区民センター",
  "古石場文化センター",
  "太子堂区民センター",
  "柏蔭舎",
];

export default Object.freeze({
  RESULT_OPTIONS: RESULT_OPTIONS,
  ROUND_OPTIONS: ROUND_OPTIONS,
  RANK_OPTIONS: RANK_OPTIONS,
  DAN_OPTIONS: DAN_OPTIONS,
  COMMENT_TYPE_OPTIONS: COMMENT_TYPE_OPTIONS,
  PRACTICE_TYPE_OPTIONS: PRACTICE_TYPE_OPTIONS,
  READ_MODE_OPTIONS: READ_MODE_OPTIONS,
  PLACE_OPTIONS: PLACE_OPTIONS,
});
