<template>
  <div class="container">
    <HeaderComponent />
    <h3 class="mb-3">メールアドレス変更</h3>
    <validation-observer ref="obs" v-slot="ObserverProps">
      <b-form-group label="現在のメールアドレス" description="">
        <validation-provider name="email" rules="required">
          <b-form-input v-model="user.email" disabled></b-form-input>
        </validation-provider>
      </b-form-group>
      <b-form-group label="新しいメールアドレス" description="~@~.ac.jp形式のメールアドレスは使用できません。">
        <validation-provider name="email" rules="required|email|isNotAcademicEmail">
          <b-form-input v-model="newEmail"></b-form-input>
        </validation-provider>
      </b-form-group>
      <b-row>
        <b-button
          variant="success"
          size="sm"
          class="ml-auto"
          :disabled="ObserverProps.invalid || loading"
          @click="updateEmail()"
        >
          <b-spinner v-if="loading == true" small></b-spinner>
          <span v-else>変更</span>
        </b-button>
      </b-row>
    </validation-observer>
    <hr />
    <h5>auth/email-already-in-useというエラーが表示された場合</h5>
    <p>
      新しいメールアドレスが使用済みの状態になっています。未使用状態に修正する作業を実施しますので。管理者（奥田）に新しいメールアドレスを教えてください。
    </p>
  </div>
</template>
<script>
import HeaderComponent from "../components/Header.vue";
import { firebaseApp } from "../main";
import { getAuth, updateEmail } from "firebase/auth";
import { getDatabase, ref, update } from "firebase/database";
import { ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
extend("required", required);
extend("email", email);
extend("isNotAcademicEmail", {
  validate(value) {
    // valueが~@~.ac.~または~@~.ed.~でない場合はtrueを返す
    return !value.match(/.+@.+\.ac\..+/) && !value.match(/.+@.+\.ed\..+/);
  },
});

export default {
  name: "EmailSettingPage",
  components: {
    HeaderComponent,
    ValidationProvider,
  },
  data() {
    return {
      user: {},
      hasEnterYear: false,
      newEmail: "",
      loading: false,
    };
  },
  created: function () {
    const auth = getAuth(firebaseApp);
    this.user["email"] = auth.currentUser.email;
  },
  methods: {
    updateEmail() {
      this.loading = true;
      const auth = getAuth();
      updateEmail(auth.currentUser, this.newEmail)
        .then(() => {
          this.loading = false;
          const database = getDatabase();
          update(ref(database, "users/" + auth.currentUser.uid), { email: this.newEmail });
          this.$router.push("/records");
          alert("メールアドレスを変更しました。");
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
          alert(
            "メールアドレスを変更できませんでした。\n一度ログアウトして、元のメールアドレスで再度ログインしてから変更してください。" +
              error
          );
        });
    },
  },
};
</script>

<style>
.label {
  margin-bottom: 0rem;
}
</style>
