<!-- TODO: デカすぎるのでコンポーネントに分割する -->
<template>
  <div>
    <div class="record-pagination-wrapper top">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="pagenated-record-list"
      ></b-pagination>
    </div>

    <div id="pagenated-record-list">
      <div v-for="(dataRound, index) in paginatedRecordList" :key="index">
        <b-table
          striped
          bordered
          hover
          :items="dataRound.dataList"
          :fields="fields"
          caption-top
          @row-clicked="setFocusRecord"
        >
          <template #table-caption>
            <!-- 日付の境界には区切り線を挿入する -->
            <hr
              v-if="index > 0 && paginatedRecordList[index].date !== paginatedRecordList[index - 1].date"
              class="mb-4"
            />

            <!-- 1回戦のみ日付を表示する -->
            <h6 v-if="index == 0 || paginatedRecordList[index].date !== paginatedRecordList[index - 1].date">
              {{ unixtimeToDate(dataRound.date) }}
            </h6>

            <!-- 同じ回戦のうち、一番最初に出てくるpracticeTypeのテーブルの前にのみ、会場・読手情報を表示する -->
            <div v-if="index == 0 || paginatedRecordList[index].round !== paginatedRecordList[index - 1].round">
              {{ dataRound.round }}回戦
              <b-row>
                <b-col class="my-auto pl-0">
                  <div v-for="(contentOfReadPlaceInfo, index3) in dataRound.readPlaceInfo" :key="index3">
                    <div style="font-size: 14px">
                      <dl>
                        <dt v-if="dataRound.readPlaceInfo.length > 1">会場{{ index3 + 1 }}：</dt>
                        <dd>
                          {{ contentOfReadPlaceInfo.place
                          }}{{ show40cardsKaruta(contentOfReadPlaceInfo.is40cardsKaruta) }}
                          <br />
                          <span v-if="contentOfReadPlaceInfo.readMode">
                            {{ contentOfReadPlaceInfo.readMode }}:
                            {{ contentOfReadPlaceInfo.reader }}
                          </span>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col align-self="end" class="text-right pr-0">
                  <b-button
                    class="mt-1 mb-3"
                    size="sm"
                    variant="success"
                    @click="
                      setMeibo();
                      $bvModal.show('editPlaceAndReader');
                      setParamsOfPlaceAndReaderModal(
                        -paginatedRecordList[index].date,
                        dataRound.round,
                        dataRound.readPlaceInfo
                      );
                    "
                  >
                    会場・読手を更新
                  </b-button>
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-col class="text-center">
                <div style="font-size: 16px" class="text-middle">
                  <b>
                    {{ dataRound.practiceType }}
                  </b>
                </div>
              </b-col>
            </b-row>
          </template>
          <template #[`cell(player1Name)`]="data">
            <div class="text-center">
              <div v-if="data.item.player1Uid == ''">
                <span style="white-space: pre-line" v-text="changeLine(data.item.player1Name)" />
                <font-awesome-icon size="sm" :icon="['fas', 'user-alt-slash']" />
              </div>
              <div v-else>
                {{ data.item.player1Name }}
              </div>
            </div>
          </template>
          <template #[`cell(player2Name)`]="data">
            <div class="text-center">
              <div v-if="data.item.player2Uid == ''">
                <span style="white-space: pre-line" v-text="changeLine(data.item.player2Name)" />
                <font-awesome-icon size="sm" :icon="['fas', 'user-alt-slash']" />
              </div>
              <div v-else>
                {{ data.item.player2Name }}
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div class="record-pagination-wrapper bottom">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="pagenated-record-list"
        @change="scrollToTopPagination"
      ></b-pagination>
    </div>

    <b-modal :id="'edit'" hide-header hide-footer modal-center>
      <Record :record="focusRecord" :practice-type-list="practiceTypeList" modal-name="edit" :user="user" />
    </b-modal>
    <b-modal :id="'editPlaceAndReader'" hide-header hide-footer modal-center>
      <div v-for="(placeAndReader, index) in placeAndReaderList" :key="placeAndReader.index" class="mb-3">
        <h6>会場{{ index + 1 }}：</h6>
        <b-row>
          <b-col class="my-auto px-0">
            <b-form-input v-model="placeAndReader.place" placeholder="会場" list="placeOptions"></b-form-input>
            <datalist id="placeOptions">
              <option v-for="place in placeOptions" :key="place">
                {{ place }}
              </option>
            </datalist>
          </b-col>
          <b-col class="my-auto px-0">
            <b-form-checkbox v-model="placeAndReader.is40cardsKaruta" class="ml-1">40枚かるた</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="my-auto px-0">
            <b-form-input
              v-model="placeAndReader.readMode"
              placeholder="読み形式"
              list="readModeOptions"
            ></b-form-input>
            <datalist id="readModeOptions">
              <option v-for="readMode in readModeOptions" :key="readMode">
                {{ readMode }}
              </option>
            </datalist>
          </b-col>
          <b-col class="my-auto px-0">
            <b-form-input v-model="placeAndReader.reader" placeholder="読手/操作者" list="meibo"></b-form-input>
            <datalist id="meibo">
              <option v-for="member in meibo" :key="member">
                {{ member }}
              </option>
            </datalist>
          </b-col>
        </b-row>
      </div>
      <div class="text-right">
        <b-button class="my-2 mx-2" variant="outline-success" size="sm" @click="addPlaceAndReaderRow()">
          行を追加
        </b-button>
        <b-button
          class="my-2 mx-2"
          size="sm"
          variant="success"
          @click="
            updatePlaceAndReader(minusUnixTimeOfPlaceAndReaderModal, roundOfPlaceAndReaderModal, placeAndReaderList);
            $bvModal.hide('editPlaceAndReader');
          "
        >
          更新
        </b-button>
      </div>
      <p style="font-size: 14px">
        ※ある会場の情報を削除したい場合は、その行の内容を全部空白にして更新してください。40枚かるたの☑はどちらでもOKです。
      </p>
    </b-modal>
  </div>
</template>
<script>
import Record from "../components/Record.vue";
import { getDatabase, ref, onValue, set } from "firebase/database";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import OPTIONS from "../constants/options.js";

export default {
  name: "RecordTable",
  components: {
    Record,
  },
  props: {
    recordList: { type: Array, default: () => {} },
    practiceTypeList: { type: Array, default: () => {} },
    user: { type: Object, default: () => {} },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      focusRecord: {},
      fields: [
        {
          key: "player1Name",
          label: "名前",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 30%",
        },
        {
          key: "player1Result",
          label: "勝敗",
          formatter: "formatResultToShortText",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "cardNumber",
          label: "枚数",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "player2Result",
          label: "勝敗",
          formatter: "formatResultToShortText",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "player2Name",
          label: "名前",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 30%",
        },
      ],
      minusUnixTimeOfPlaceAndReaderModal: new Number(),
      roundOfPlaceAndReaderModal: new Number(),
      placeAndReaderList: [],
      readModeOptions: OPTIONS.READ_MODE_OPTIONS,
      placeOptions: OPTIONS.PLACE_OPTIONS,
    };
  },
  computed: {
    totalRows() {
      return this.recordList.length;
    },
    paginatedRecordList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.recordList.slice(start, end);
    },
  },
  methods: {
    changeLine(playerName) {
      return playerName.replace(/\(|（/, "\n(").replace(/\)|）/, ")");
    },
    setFocusRecord(record) {
      this.setMeibo();
      this.focusRecord = record;
      this.$bvModal.show("edit");
    },
    setMeibo() {
      let meiboHash = new Object();
      const database = getDatabase();
      const meiboRef = ref(database, "meibo");
      onValue(meiboRef, (snapShot) => {
        if (snapShot) {
          snapShot.forEach((data) => {
            let meiboMemberByYear = data.val();
            for (let member in meiboMemberByYear) {
              meiboMemberByYear[member]["year"] = data.key;
            }
            meiboHash = Object.assign(meiboHash, meiboMemberByYear);
          });
        }
      });
      this.meibo = Object.keys(this.sortMeiboHash(meiboHash));
    },
    sortMeiboHash(meiboHash) {
      let meiboArray = new Array();
      for (let person in meiboHash) {
        let kana = meiboHash[person].kana;
        let personObj = {
          kana: kana,
          name: person,
          value: meiboHash[person],
        };
        meiboArray.push(personObj);
      }
      meiboArray.sort(function (a, b) {
        if (a.kana < b.kana) {
          return -1;
        } else {
          return 1;
        }
      });
      let meiboObj = new Object();
      for (let i in meiboArray) {
        meiboObj[meiboArray[i].name] = meiboArray[i].value;
      }
      return meiboObj;
    },
    formatResultToShortText(num) {
      switch (num) {
        case 1:
          return "○";
        case 0:
          return "×";
        case -1:
          return "指導";
        case -2:
          return "一人";
        case -3:
          return "T.U.";
        case "":
          return "";
      }
    },
    setParamsOfPlaceAndReaderModal(minusUnixTime, round, readPlaceInfo) {
      this.minusUnixTimeOfPlaceAndReaderModal = minusUnixTime;
      this.roundOfPlaceAndReaderModal = round;
      this.placeAndReaderList = [];
      for (let index in readPlaceInfo) {
        //firebase上で""(空白文字)のデータがこれによって"記載なし"に書き換わる・・・
        this.placeAndReaderList.push({
          place: readPlaceInfo[index].place,
          readMode: readPlaceInfo[index].readMode,
          reader: readPlaceInfo[index].reader,
          is40cardsKaruta: readPlaceInfo[index].is40cardsKaruta,
        });
      }
    },
    addPlaceAndReaderRow() {
      // 会場と読手の行を追加
      const result = {
        place: "",
        readMode: "",
        reader: "",
      };
      this.placeAndReaderList.push(result);
    },
    updatePlaceAndReader(minusUnixTimeOfPlaceAndReaderModal, roundOfPlaceAndReaderModal, placeAndReaderList) {
      let placeAndReaderObj = new Object();
      for (let i in placeAndReaderList) {
        let placeAndReaderInfo = placeAndReaderList[i];
        if (placeAndReaderInfo.place == "" && placeAndReaderInfo.readMode == "" && placeAndReaderInfo.reader == "")
          continue;
        placeAndReaderObj[i] = placeAndReaderInfo;
      }
      const database = getDatabase();
      const placeAndReaderRef = ref(
        database,
        "records/" + minusUnixTimeOfPlaceAndReaderModal + "/" + roundOfPlaceAndReaderModal + "/placeInfo"
      );
      set(placeAndReaderRef, placeAndReaderObj);
    },
    scrollToTopPagination() {
      const topPagination = document.querySelector(".record-pagination-wrapper.top");
      if (topPagination) {
        topPagination.scrollIntoView({ behavior: "smooth" });
      }
    },
    show40cardsKaruta(is40cardsKaruta) {
      if (is40cardsKaruta == true) return "(40枚かるた)";
      if (is40cardsKaruta == false) return;
    },
    unixtimeToDate(unixtime) {
      return dayjs(unixtime * 1000).format("YYYY/MM/DD (dd)");
    },
  },
};
</script>
<style scoped>
.record-pagination-wrapper {
  display: flex;
  justify-content: center;
}
</style>
