<!-- src/views/PlayerList.vue -->
<template>
  <div class="container">
    <HeaderComponent />
    <PlayerManagement />
  </div>
</template>

<script>
import PlayerManagement from "@/components/PlayerManagement.vue";
import HeaderComponent from "@/components/Header.vue";

export default {
  name: "PlayerList",
  components: {
    PlayerManagement,
    HeaderComponent,
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 64px;
}
</style>
