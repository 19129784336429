<template>
  <div class="container">
    <HeaderComponent />
    <b-row>
      <div v-if="isMatchFormVisible == false" class="mx-auto">
        <b-button v-b-toggle.matches variant="success" class="my-2 mx-1" size="sm" @click="setMeibo()">
          <font-awesome-icon size="lg" :icon="['fas', 'pencil-alt']" />
          対戦を記録
        </b-button>
      </div>
      <div v-else class="mx-auto">
        <b-button v-b-toggle.matches variant="success" class="my-2" size="sm">閉じる</b-button>
      </div>
    </b-row>
    <b-collapse id="matches" v-model="isMatchFormVisible">
      <MatchForm :meibo="meibo" :practice-type-list="practiceTypeList" />
    </b-collapse>
    <b-sidebar id="collapseSearch" v-model="collapseSearch" right shadow backdrop>
      <div>
        <validation-observer ref="obs" v-slot="ObserverProps">
          <b-row class="mb-2">
            <b-col class="col-12">
              <validation-provider name="field1" rules="required|compareStartDateAndEndDate:field2">
                <div class="text-center">開始日</div>
                <b-form-datepicker
                  v-model="searchParam.startDate"
                  name="field1"
                  lacale="ja"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                ></b-form-datepicker>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col class="col-12">
              <validation-provider vid="field2" name="field2" rules="required">
                <div class="text-center">終了日</div>
                <b-form-datepicker
                  v-model="searchParam.endDate"
                  name="field2"
                  lacale="ja"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                ></b-form-datepicker>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col class="col-12">
              <div class="text-center">名前</div>
              <b-form-input v-model="searchParam.name1" name="name1" placeholder="1人目"></b-form-input>
              <b-form-input
                v-if="searchParam.name1 != ''"
                v-model="searchParam.name2"
                name="name2"
                placeholder="2人目"
                class="mt-2"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-12">
              <div class="text-center">曜日</div>
              <div class="d-flex justify-content-center"></div>
              <b-form-checkbox-group v-model="searchParam.selectedDays" class="justify-content-center">
                <b-form-checkbox
                  v-for="day in days"
                  :key="day.value"
                  :value="day.value"
                  button-variant="primary"
                  class="mx-2"
                >
                  {{ day.text }}
                </b-form-checkbox>
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  class="mx-2"
                  @click="searchParam.selectedDays = [0, 1, 2, 3, 4, 5, 6]"
                >
                  全選択
                </b-button>
                <b-button variant="outline-secondary" size="sm" class="mx-2" @click="searchParam.selectedDays = []">
                  全解除
                </b-button>
              </b-form-checkbox-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="col-12">
              <div class="d-flex justify-content-end">
                <b-button variant="success" class="my-2" size="sm" :disabled="ObserverProps.invalid" @click="search">
                  絞り込み実行
                </b-button>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
    </b-sidebar>
    <div v-if="isLoading" class="text-center mt-4">
      <b-spinner variant="success"></b-spinner>
    </div>
    <b-card v-else no-body class="mt-2">
      <!-- b-card-headerを、中の要素が右揃えになるように追加 -->
      <b-card-body class="d-flex justify-content-end pt-2 pb-0" style="background-color: rgba(0, 0, 0, 0.03)">
        <b-button v-model="collapseSearch" v-b-toggle.collapseSearch variant="success" size="sm">
          <font-awesome-icon size="lg" :icon="['fas', 'sliders']" />
          絞り込み
        </b-button>
      </b-card-body>
      <b-tabs card nav-class="small" fill>
        <b-tab title="対戦結果" active>
          <div>
            {{ message }}
          </div>
          <RecordTable :record-list="recordList" :practice-type-list="practiceTypeList" :user="user" />
        </b-tab>
        <b-tab :title="'コメント(' + commentList.length + ')'">
          <CommentTable
            :comment-list="commentList"
            :table-type="'normal'"
            :practice-type-list="practiceTypeList"
            :user="user"
          />
        </b-tab>
        <b-tab title="ランキング">
          <RankingTables :record-list="recordList" :user="user" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import HeaderComponent from "../components/Header.vue";
import MatchForm from "@/components/MatchForm.vue";
import RecordTable from "../components/RecordTable.vue";
import CommentTable from "../components/CommentTable.vue";
import RankingTables from "../components/RankingTables.vue";
import { firebaseApp } from "../main";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, off, orderByKey, query, startAt, endAt } from "firebase/database";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);
extend("compareStartDateAndEndDate", {
  params: [
    {
      name: "str",
      isTarget: true,
    },
  ],
  validate(value, { str }) {
    if (!value || !str) return false; // 空チェック
    if (value > str) return false; // 大小チェック
    return true;
  },
});
import OPTIONS from "../constants/options.js";

export default {
  name: "RecordsPage",
  components: {
    HeaderComponent,
    MatchForm,
    RecordTable,
    CommentTable,
    RankingTables,
    ValidationProvider,
  },
  data() {
    return {
      isLoading: true,
      matchList: [],
      result: {
        date: dayjs(new Date()).format("YYYY-MM-DD"),
        player1Name: "",
        player2Name: "",
        player1Result: "",
        player2Result: "",
        cardNumber: "",
      },
      form: {
        name: "",
      },
      recordList: [],
      readPlaceList: [],
      readModeOptions: OPTIONS.READ_MODE_OPTIONS,
      placeAndReaderList: [],
      practiceTypeList: [],
      practiceTypeFromRecordSet: new Set(),
      numberOfRecords: 0,
      fields: [
        {
          key: "player1Name",
          label: "名前",
          thClass: "text-center",
          tdClass: "text-center records-name",
        },
        {
          key: "player1Result",
          label: "勝敗",
          formatter: "formatResultToShortText",
          thClass: "text-center",
          tdClass: "text-center records-result",
        },
        {
          key: "cardNumber",
          label: "枚数",
          thClass: "text-center",
          tdClass: "text-center records-cardNumber",
        },
        {
          key: "player2Result",
          label: "勝敗",
          formatter: "formatResultToShortText",
          thClass: "text-center",
          tdClass: "text-center records-result",
        },
        {
          key: "player2Name",
          label: "名前",
          thClass: "text-center",
          tdClass: "text-center records-name",
        },
      ],
      readPlaceFields: [
        {
          key: "date",
          label: "日付",
          thClass: "text-center",
          tdClass: "text-center records-name",
        },
        {
          key: "round",
          label: "回戦",
          formatter: "formatResultToShortText",
          thClass: "text-center",
          tdClass: "text-center records-result",
        },
        {
          key: "readMode",
          label: "読み/ありあけ",
          thClass: "text-center",
          tdClass: "text-center records-cardNumber",
        },
        {
          key: "reader",
          label: "読み/押し",
          thClass: "text-center",
          tdClass: "text-center records-result",
        },
        {
          key: "place",
          label: "会場",
          thClass: "text-center",
          tdClass: "text-center records-name",
        },
      ],
      user: Object,
      uid: String,
      currentPage: 1,
      message: "",
      focusRecord: {},
      searchParam: {
        startDate: dayjs(dayjs().subtract(14, "day")).format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        name1: "",
        name2: "",
        selectedDays: [0, 1, 2, 3, 4, 5, 6],
      },
      readPlaceArrayOfPlaceAndReaderModal: [],
      meibo: [],
      isMatchFormVisible: false,
      inputType: "single",
      commentList: [],
      commentTowardMeList: [],
      recordRef: null,
      days: [
        { value: 1, text: "月" },
        { value: 2, text: "火" },
        { value: 3, text: "水" },
        { value: 4, text: "木" },
        { value: 5, text: "金" },
        { value: 6, text: "土" },
        { value: 0, text: "日" },
      ],
      collapseSearch: false,
    };
  },
  created: function () {
    const auth = getAuth(firebaseApp);
    this.user = auth.currentUser;
    this.uid = auth.currentUser.uid;

    const database = getDatabase();
    const currentUserRef = ref(database, "users/" + this.uid);
    onValue(currentUserRef, (snapshot) => {
      if (!snapshot) return;
      snapshot.forEach((data) => {
        if (data.key == "email") return;
        this.user[data.key] = data.val();
      });
    });
    this.search();
  },
  methods: {
    search() {
      this.isLoading = true;
      let _this = this;
      this.message = "";
      if (this.recordRef) off(this.recordRef);

      const database = getDatabase();
      let startDate = dayjs(_this.searchParam.startDate);
      let endDate = dayjs(_this.searchParam.endDate);
      const recordsRef = query(
        ref(database, "records/"),
        ...[orderByKey(), startAt(String(-endDate / 1000)), endAt(String(-startDate / 1000))]
      );
      this.recordRef = recordsRef;
      onValue(recordsRef, (querySnapshot) => {
        _this.commentList = [];
        _this.commentTowardMeList = [];
        _this.recordList = [];
        _this.practiceTypeFromRecordSet = new Set();

        const searchResult = querySnapshot.val();
        if (!searchResult) {
          _this.message = "練習記録が存在しないようです。期間を変更してみてください。";
          return;
        }

        // 日付、回戦ごとにデータを整形して格納
        for (let reversedUnixtime in searchResult) {
          // 同一日付のデータは、最終回戦から降順に格納する
          // MEMO: searchResult[reversedUnixtime]にN回戦分のデータが格納されている場合、
          //   [empty, {1 : {1回戦のデータ}}, {2 : {2回戦のデータ}}, {3 : {3回戦のデータ}}, ..., {N : {N回戦のデータ}}]
          //   というN+1個の要素を持つ配列になるので、（0-indexで）indexの上限はN, 下限は1となる（emptyが0番目の要素として入る理由がよく分からないが）
          for (let round = searchResult[reversedUnixtime].length - 1; round >= 1; round--) {
            // FIXME: 1回戦と3回戦のデータだけが登録されている場合、
            //    [empty, {1 : {1回戦のデータ}}, empty, {3 : {3回戦のデータ}}]
            //   という配列になるので、roundが2のときにundefinedになってしまうので、スキップする必要がある
            if (searchResult[reversedUnixtime][round] == undefined) continue;

            _this.formatAndAddData(-reversedUnixtime, round, searchResult[reversedUnixtime][round]);
          }
        }

        // 投稿日時の降順にコメントをソート
        _this.commentList.sort((a, b) => (a.unixtime > b.unixtime ? -1 : 1));

        // 練習分類リストを再代入
        _this.practiceTypeList = [..._this.practiceTypeFromRecordSet];
        _this.isLoading = false;
      });
      this.collapseSearch = false;
    },
    formatAndAddData(unixtime, round, recordsInRound) {
      let recordsGroupByPracticeType = new Object();
      let placeAndReaderInfoArray = new Array();

      // 回戦・練習タイプで分類する
      for (const [recordKey, value] of Object.entries(recordsInRound)) {
        if (recordKey == "placeInfo") {
          // 記録が当該回戦の読み・会場についての記録の場合は、placeAndReaderInfoArrayに格納しておく
          Object.keys(value).forEach((element) => {
            const placeAndReaderInfo = {
              date: unixtime,
              round: round,
              readMode: value[element]["readMode"],
              reader: value[element]["reader"] ? value[element]["reader"] : "記載なし",
              place: value[element]["place"] ? value[element]["place"] : "記載なし",
              is40cardsKaruta: value[element]["is40cardsKaruta"] ? value[element]["is40cardsKaruta"] : false,
            };
            placeAndReaderInfoArray.push(placeAndReaderInfo);
          });
        } else if (this.isSatisfyingSearchCondition(value)) {
          // 検索条件に合致する場合

          // recordsGroupByPracticeTypeのキーにvalue.practiceTypeがなければkey-valueを作っておく
          if (!recordsGroupByPracticeType[value.practiceType])
            recordsGroupByPracticeType[value.practiceType] = new Array();

          // recordsGroupByPracticeTypeの対応するkeyのvalue(array)に格納
          recordsGroupByPracticeType[value.practiceType].push(value);

          // コメントがある場合に格納
          if (value.comments) {
            Object.keys(value.comments).forEach((commentKey) => {
              let comment = value.comments[commentKey];
              comment["reversedUnixtimeOfRecord"] = -unixtime;
              comment["player1Name"] = value.player1Name;
              comment["player2Name"] = value.player2Name;
              comment["key"] = recordKey;
              comment["round"] = round;
              if (this.isVisibleComment(comment)) this.commentList.push(comment);
              if (this.isTowardMeComment(value, comment)) this.commentTowardMeList.push(comment);
            });
          }
        }
      }

      // _this.recordListに練習タイプごとに格納
      for (let practiceType in recordsGroupByPracticeType) {
        this.recordList.push({
          date: unixtime,
          round: round,
          readPlaceInfo: placeAndReaderInfoArray,
          practiceType: practiceType,
          dataList: recordsGroupByPracticeType[practiceType],
        });
        this.practiceTypeFromRecordSet.add(practiceType);
      }
    },

    isSatisfyingSearchCondition(record) {
      // record.dateの曜日がthis.searchParam.selectedDaysに含まれていなければNG
      let date = dayjs(record.date);
      if (!this.searchParam.selectedDays.includes(date.day())) return false;

      // 名前1が空の場合は全てOK
      if (this.searchParam.name1 == "") return true;

      // 名前1が指定あり・名前2が空の場合は、どっちかが名前1と合致すればOK
      if (this.searchParam.name2 == "") {
        return (
          record.player1Name.includes(this.searchParam.name1) || record.player2Name.includes(this.searchParam.name1)
        );
      }

      // 名前1・名前2の両方が指定されている場合は、両方合致したらOK
      return (
        (record.player1Name.includes(this.searchParam.name1) && record.player2Name.includes(this.searchParam.name2)) ||
        (record.player2Name.includes(this.searchParam.name1) && record.player1Name.includes(this.searchParam.name2))
      );
    },

    setMeibo() {
      let meiboHash = new Object();
      const database = getDatabase();
      const meiboRef = ref(database, "meibo/");
      onValue(meiboRef, (snapShot) => {
        if (snapShot) {
          snapShot.forEach((data) => {
            let meiboMemberByYear = data.val();
            for (let member in meiboMemberByYear) {
              meiboMemberByYear[member]["year"] = data.key;
            }
            meiboHash = Object.assign(meiboHash, meiboMemberByYear);
          });
        }
      });
      this.meibo = Object.keys(this.sortMeiboHash(meiboHash));
    },

    sortMeiboHash(meiboHash) {
      let meiboArray = new Array();
      for (let person in meiboHash) {
        let kana = meiboHash[person].kana;
        let personObj = {
          kana: kana,
          name: person,
          value: meiboHash[person],
        };
        meiboArray.push(personObj);
      }
      meiboArray.sort((a, b) => {
        if (a.kana < b.kana) return -1;
        return 1;
      });
      let meiboObj = new Object();
      for (let i in meiboArray) {
        meiboObj[meiboArray[i].name] = meiboArray[i].value;
      }
      return meiboObj;
    },
    isTowardMeComment(record, comment) {
      if (record.player1Name !== this.user.name && record.player2Name !== this.user.name) return false;
      if (comment.name == this.user.name) return false;
      if (!this.isVisibleComment(comment)) return false;
      return true;
    },
    isVisibleComment(comment) {
      if (comment.commentType == "public") return true;
      if (comment.commentType == "private" && this.userName == comment.name) return true;
      if (comment.commentType == "seniorMemberOnly" && this.user.enterYear <= comment.upperLimitOfViewersEnterYear)
        return true;
      if (comment.commentType == "directMessage" && this.user.name == comment.name) return true;
      if (comment.commentType == "directMessage" && this.user.uid == comment.towardUserId) return true;

      return false;
    },
  },
};
</script>
<style scoped>
.searchButton {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

label.searchLabel {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media screen and (max-width: 574px) {
  .searchButton {
    margin-left: auto !important;
  }

  label.searchLabel {
    margin-top: 0.5rem !important;
    margin-bottom: 0rem !important;
  }
}

@media screen and (max-width: 991px) {
  .container {
    margin-bottom: 64px;
  }
}

.table th,
.table td {
  padding: 0.25rem;
}

.table td.records-name {
  width: 30%;
}

.table td.records-result {
  width: 13%;
}

.table td.records-cardNumber {
  width: 14%;
}

.modal-content {
  top: auto;
}
</style>
