<template>
  <div>
    <b-card no-body>
      <b-card-body class="px-1">
        <validation-observer ref="obs" v-slot="ObserverProps">
          <b-row class="mb-2">
            <b-col cols="7">
              <validation-provider name="date" rules="required">
                <b-form-datepicker
                  v-model="match.date"
                  locale="ja"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                ></b-form-datepicker>
              </validation-provider>
            </b-col>
            <b-col cols="5">
              <b-form>
                <b-form-select v-model="match.round" :options="roundOptions"></b-form-select>
              </b-form>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="7">
              <b-form inline>
                <b-form-input
                  v-model="match.practiceType"
                  list="practiceTypeList"
                  placeholder="練習の分類"
                ></b-form-input>
                <datalist id="practiceTypeList">
                  <option v-for="practiceType in practiceTypeList" :key="practiceType">{{ practiceType }}</option>
                </datalist>
              </b-form>
            </b-col>
            <b-col cols="5" class="my-auto">
              <b-form-checkbox v-model="match.is40cardsKaruta">40枚</b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="7">
              <b-form-checkbox v-model="isReaderVisible">読み/会場を入力</b-form-checkbox>
            </b-col>
            <b-col cols="5">
              <b-form-checkbox v-model="isResultVisible">結果を入力</b-form-checkbox>
            </b-col>
          </b-row>
          <hr v-if="isReaderVisible" />
          <b-row v-if="isReaderVisible" class="mb-2">
            <b-col cols="6">
              <b-form-select v-model="match.readMode" :options="readModeOptions"></b-form-select>
            </b-col>
            <b-col cols="6">
              <b-form-input
                v-model="match.reader"
                placeholder="読み/押しの人"
                list="utsusemiUserListReader"
              ></b-form-input>
              <datalist id="utsusemiUserListReader">
                <option v-for="member in meibo" :key="member">{{ member }}</option>
              </datalist>
            </b-col>
          </b-row>
          <b-row v-if="isReaderVisible">
            <b-col cols="12">
              <b-form-input v-model="match.place" placeholder="会場" list="placeOptions"></b-form-input>
              <datalist id="placeOptions">
                <option v-for="place in placeOptions" :key="place">{{ place }}</option>
              </datalist>
            </b-col>
          </b-row>
          <hr />
          <div v-for="(item, index) in matchList" :key="index" class="my-2">
            <b-row class="text-center mb-2">
              <b-col>
                <b-form-input
                  v-model="item.player1Name"
                  placeholder="1人目"
                  list="utsusemiUserList1"
                  autocomplete="off"
                  @blur="addRowIfNeeded"
                  @update="setUid(1, item.player1Name, index)"
                ></b-form-input>
                <datalist id="utsusemiUserList1">
                  <option v-for="member in meibo" :key="member">{{ member }}</option>
                </datalist>
                <b-row v-if="item.player1Kyu != '' || item.player1Dan != ''" class="danRow">
                  <b-col class="mx-0 px-0 danCol d-flex align-items-center">
                    <div class="my-auto align-middle" style="font-size: 16px; padding-left: 15px">
                      {{ item.player1Kyu }}級{{ item.player1Dan }}段
                    </div>
                    <b-badge
                      class="danBadge ml-auto"
                      @click="
                        $bvModal.show('editRankAndDan');
                        setEditParams(item.player1Name);
                      "
                    >
                      修正
                    </b-badge>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-form-input
                  v-model="item.player2Name"
                  placeholder="2人目"
                  list="utsusemiUserList2"
                  autocomplete="off"
                  @blur="addRowIfNeeded"
                  @update="setUid(2, item.player2Name, index)"
                ></b-form-input>
                <datalist id="utsusemiUserList2">
                  <option v-for="member in meibo" :key="member">{{ member }}</option>
                </datalist>
                <b-row v-if="item.player2Kyu != '' || item.player2Dan != ''" class="danRow">
                  <b-col class="mx-0 px-0 danCol d-flex align-items-center">
                    <div class="my-auto" style="font-size: 16px; padding-left: 15px">
                      {{ item.player2Kyu }}級{{ item.player2Dan }}段
                    </div>
                    <b-badge
                      class="danBadge ml-auto"
                      @click="
                        $bvModal.show('editRankAndDan');
                        setEditParams(item.player2Name);
                      "
                    >
                      修正
                    </b-badge>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="isResultVisible" class="text-center">
              <b-col>
                <b-form-select
                  key="input-result"
                  v-model="item.player1Result"
                  :options="resultOptions"
                  @input="setOpponentResult('player2', index)"
                ></b-form-select>
              </b-col>
              <b-col>
                <validation-provider v-slot="{ errors }" name="枚数" rules="min_value:1|max_value:48">
                  <b-form-input
                    key="input-result"
                    v-model="item.cardNumber"
                    placeholder="枚数"
                    type="number"
                  ></b-form-input>
                  <p class="error">{{ errors[0] }}</p>
                </validation-provider>
              </b-col>
              <b-col>
                <b-form-select
                  key="input-result"
                  v-model="item.player2Result"
                  :options="resultOptions"
                  @input="setOpponentResult('player1', index)"
                ></b-form-select>
              </b-col>
            </b-row>
            <hr v-if="isResultVisible" />
            <b-row v-else class="text-center"></b-row>
          </div>
          <div class="float-right">
            <b-button variant="success" class="my-2 mx-2" size="sm" @click="addMatchToMatchList()">行を追加</b-button>
            <b-button
              v-b-toggle.matches
              variant="success"
              class="my-2 mx-2"
              :disabled="ObserverProps.invalid"
              size="sm"
              @click="submit()"
            >
              投稿
            </b-button>
          </div>
        </validation-observer>
      </b-card-body>
    </b-card>
    <b-modal :id="'editRankAndDan'" hide-header hide-footer modal-center>
      <b-row>
        <b-col>
          <label>{{ memberEditParams.name }}さんの段と級を変更します。</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form inline>
            <b-form-select v-model="memberEditParams.rank" :options="rankOptions"></b-form-select>
            <label for="inline-form-input-kyu">級</label>
            <b-form-select v-model="memberEditParams.dan" :options="danOptions"></b-form-select>
            <label for="inline-form-input-dan">段</label>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="success"
            class="my-2 mx-2 float-right"
            size="sm"
            @click="
              editMeiboMember(memberEditParams);
              $bvModal.hide('editRankAndDan');
            "
          >
            変更
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import "dayjs/locale/ja";
import dayjs from "dayjs";
import { firebaseApp } from "../main";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, push, update } from "firebase/database";
import { ValidationProvider, extend } from "vee-validate";
import { required, min_value, max_value } from "vee-validate/dist/rules";
extend("required", required);
extend("min_value", {
  ...min_value,
  message: "枚数は1以上の値を入力してください",
});
extend("max_value", {
  ...max_value,
  message: "枚数は48以下の値を入力してください",
});
import OPTIONS from "../constants/options.js";

export default {
  name: "MatchForm",
  components: {
    ValidationProvider,
  },
  props: {
    meibo: { type: Array, default: () => {} },
    practiceTypeList: { type: Array, default: () => {} },
  },
  data() {
    return {
      utsusemiUserList: [],
      meiboHash: {},
      match: {
        date: dayjs(new Date()).format("YYYY-MM-DD"),
        round: 1,
        readMode: "",
        reader: "",
        place: "",
        is40cardsKaruta: false,
        practiceType: "",
      },
      matchList: [],
      readModeOptions: OPTIONS.READ_MODE_OPTIONS,
      placeOptions: OPTIONS.PLACE_OPTIONS,
      resultOptions: OPTIONS.RESULT_OPTIONS,
      roundOptions: OPTIONS.ROUND_OPTIONS,
      isResultVisible: false,
      isReaderVisible: false,
      memberEditParams: {
        name: "",
        rank: "",
        dan: "",
      },
      rankOptions: OPTIONS.RANK_OPTIONS,
      danOptions: OPTIONS.DAN_OPTIONS,
    };
  },
  created: function () {
    this.addMatchToMatchList();
    this.user = getAuth(firebaseApp).currentUser;
    let _this = this;
    const database = getDatabase();
    const meiboRef = ref(database, "meibo");
    onValue(meiboRef, (snapshot) => {
      if (snapshot) {
        snapshot.forEach((data) => {
          let meiboMemberByYear = data.val();
          for (let member in meiboMemberByYear) {
            meiboMemberByYear[member]["year"] = data.key;
          }
          _this.meiboHash = Object.assign(_this.meiboHash, meiboMemberByYear);
        });
      }
    });

    const usersRef = ref(database, "users");
    onValue(usersRef, (snapshot) => {
      if (!snapshot) return;
      let userList = [];
      snapshot.forEach((data) => {
        let user = data.val();
        user["uid"] = data.key;
        userList.push(user);
      });
      _this.utsusemiUserList = userList;
    });
    _this.meiboHash = this.sortMeibo();
  },
  methods: {
    addRowIfNeeded() {
      let hasBlankMatch = false;
      for (const match of this.matchList) {
        if (!match.player1Name && !match.player2Name) {
          hasBlankMatch = true;
          break;
        }
      }
      if (!hasBlankMatch) this.addMatchToMatchList();
    },
    submit() {
      // 1回の記録投稿をするときに、試合間で共通の属性(回戦、日付、練習会形式など)をまとめて宣言
      // 40枚かるたフラグもここに入れてよいかも
      let round = this.match.round;
      let date = this.match.date;
      let practiceType = this.match.practiceType;
      let readMode = this.match.readMode;
      let reader = this.match.reader;
      let place = this.match.place;
      let is40cardsKaruta = this.match.is40cardsKaruta;
      let unixtime = -dayjs(this.match.date).unix();

      // Realtime Databaseのデータについて、
      // 日付(unixtime)と回戦(round)を指定した状態の参照窓口(recordsPostRef)を宣言する
      const database = getDatabase();
      const recordsPostRef = ref(database, "records/" + unixtime + "/" + round);
      for (let i = 0, l = this.matchList.length; i < l; i++) {
        if (this.matchList[i].player1Name == "" && this.matchList[i].player2Name == "") {
          // matchListで、選手の名前が両方入っていない行はスキップ
          continue;
        }
        // いまから投稿する試合データに対応する、Realtime Database側のkeyを事前に取得する(このキーはランダム生成)
        var newPostKey = push(recordsPostRef).key;

        // 投稿用のデータを準備
        // this.matchList[i]に40枚かるたフラグが入っていればそのままでOKだが、
        // もし入っていなければ、obj[newPostKet].is40cardsKaruta = this.is40cardsKarutaとして、明示的に属性追加する必要ある
        let obj = {};
        obj[newPostKey] = this.matchList[i];
        obj[newPostKey].date = date;
        obj[newPostKey].round = round;
        obj[newPostKey]["key"] = newPostKey;
        obj[newPostKey].practiceType = practiceType;
        obj[newPostKey].is40cardsKaruta = is40cardsKaruta;

        // 参照窓口にobj(投稿用データ)を投げる→更新完了
        update(recordsPostRef, obj);

        // 個人別記録への投稿処理その1 ←40枚かるたフラグの追加が明示的に必要
        if (this.matchList[i].player1Uid !== "") {
          var userRecordsPostRefForPlayer1 = ref(
            database,
            "userRecords/" + this.matchList[i].player1Uid + "/" + unixtime + "/" + round
          );
          let player1Obj = {};
          player1Obj[newPostKey] = {
            date: date,
            round: round,
            is40cardsKaruta: is40cardsKaruta,
            opponent: this.matchList[i].player2Name,
            opponentId: this.matchList[i].player2Uid,
            opponentKyu: this.matchList[i].player2Kyu,
            opponentDan: this.matchList[i].player2Dan,
            result: this.matchList[i].player1Result,
            key: newPostKey,
            cardNumber: this.matchList[i].cardNumber,
          };
          update(userRecordsPostRefForPlayer1, player1Obj);
        }

        // 個人別記録への投稿処理その2 ←40枚かるたフラグの追加が明示的に必要
        if (this.matchList[i].player2Uid !== "") {
          var userRecordsPostRefForPlayer2 = ref(
            database,
            "userRecords/" + this.matchList[i].player2Uid + "/" + unixtime + "/" + round
          );
          let player2Obj = {};
          player2Obj[newPostKey] = {
            date: date,
            round: round,
            is40cardsKaruta: is40cardsKaruta,
            opponent: this.matchList[i].player1Name,
            opponentId: this.matchList[i].player1Uid,
            opponentKyu: this.matchList[i].player1Kyu,
            opponentDan: this.matchList[i].player1Dan,
            result: this.matchList[i].player2Result,
            key: newPostKey,
            cardNumber: this.matchList[i].cardNumber,
          };
          update(userRecordsPostRefForPlayer2, player2Obj);
        }
      }

      // 読み・会場情報の更新処理
      let placeObj = {};
      placeObj["readMode"] = readMode;
      placeObj["reader"] = reader;
      placeObj["place"] = place;
      placeObj["is40cardsKaruta"] = is40cardsKaruta;
      if (readMode != "" || reader != "" || place != "") {
        const placeInfoRef = ref(database, "records/" + unixtime + "/" + round + "/placeInfo");
        onValue(
          placeInfoRef,
          (snapShot) => {
            var isFoundSameReader = false;
            snapShot.forEach(function (element) {
              if (
                element.val().readMode == readMode &&
                element.val().reader == reader &&
                element.val().place == place
              ) {
                isFoundSameReader = true;
              }
            });
            const index = snapShot.val() ? Object.keys(snapShot.val()).length : 0;
            if (isFoundSameReader) return;
            const nextPlaceInfoRef = ref(database, "records/" + unixtime + "/" + round + "/placeInfo/" + index);
            update(nextPlaceInfoRef, placeObj);
          },
          { onlyOnce: true }
        );
      }

      this.matchList = [];
      this.addMatchToMatchList();
      this.match.readMode = "";
      this.match.reader = "";
      this.match.is40cardsKaruta = false;
    },
    addMatchToMatchList() {
      //対戦組合せリストに1行追加する
      const result = {
        date: dayjs(new Date()).format("YYYY-MM-DD"),
        round: "",
        player1Name: "",
        player1Uid: "",
        player1Kyu: "",
        player1Dan: "",
        player2Name: "",
        player2Uid: "",
        player2Kyu: "",
        player2Dan: "",
        player1Result: "",
        player2Result: "",
        cardNumber: "",
      };
      this.matchList.push(result);
    },
    setOpponentResult(opponent, index) {
      // 決着がついていない場合はResultを同じにする
      if (this.matchList[index].player1Result !== 0 && this.matchList[index].player1Result !== 1) {
        if (opponent == "player2") {
          this.matchList[index].player2Result = this.matchList[index].player1Result;
        } else {
          this.matchList[index].player1Result = this.matchList[index].player2Result;
        }
      } else {
        //プレイヤー1（2）の勝敗を設定したときに、プレイヤー2（1）の勝敗を設定する
        if (opponent == "player2") {
          this.matchList[index].player2Result = 1 - this.matchList[index].player1Result;
        } else {
          this.matchList[index].player1Result = 1 - this.matchList[index].player2Result;
        }
      }
    },
    setUid(playerSide, playerName, index) {
      //アプリ利用者の場合、Uidを設定する。段位も設定してもらう
      for (let i = 0, l = this.utsusemiUserList.length; i < l; i++) {
        if (playerName == this.utsusemiUserList[i].name) {
          if (playerSide == 1) {
            this.matchList[index].player1Uid = this.utsusemiUserList[i].uid;
          } else {
            this.matchList[index].player2Uid = this.utsusemiUserList[i].uid;
          }
          break;
        } else {
          if (playerSide == 1) {
            this.matchList[index].player1Uid = "";
          } else {
            this.matchList[index].player2Uid = "";
          }
        }
      }

      if (this.meibo.includes(playerName)) {
        if (playerSide == 1) {
          this.matchList[index].player1Kyu = this.meiboHash[playerName].kyu;
          this.matchList[index].player1Dan = this.meiboHash[playerName].dan;
        } else {
          this.matchList[index].player2Kyu = this.meiboHash[playerName].kyu;
          this.matchList[index].player2Dan = this.meiboHash[playerName].dan;
        }
      } else {
        if (playerSide == 1) {
          this.matchList[index].player1Kyu = "";
          this.matchList[index].player1Dan = "";
        } else {
          this.matchList[index].player2Kyu = "";
          this.matchList[index].player2Dan = "";
        }
      }
    },
    sortMeibo() {
      let meiboArray = new Array();
      for (let person in this.meiboHash) {
        let kana = this.meiboHash[person].kana;
        let personObj = {
          kana: kana,
          name: person,
          value: this.meiboHash[person],
        };
        meiboArray.push(personObj);
      }
      meiboArray.sort(function (a, b) {
        if (a.kana < b.kana) {
          return -1;
        } else {
          return 1;
        }
      });
      let meiboObj = new Object();
      for (let i in meiboArray) {
        meiboObj[meiboArray[i].name] = meiboArray[i].value;
      }
      return meiboObj;
    },
    setEditParams(name) {
      this.memberEditParams.name = name;
      this.memberEditParams.rank = this.meiboHash[name].kyu;
      this.memberEditParams.dan = this.meiboHash[name].dan;
      this.memberEditParams.year = this.meiboHash[name].year;
    },
    editMeiboMember(memberEditParams) {
      let kyu = memberEditParams.rank;
      let dan = memberEditParams.dan;
      const database = getDatabase();
      const meiboDetailRef = ref(database, "meibo/" + memberEditParams["year"] + "/" + memberEditParams["name"]);
      update(meiboDetailRef, { dan: dan, kyu: kyu });
    },
  },
};
</script>
<style>
span.description {
  display: inline-block;
}

.danRow {
  padding-top: 3px;
  height: auto;
}

.danCol {
  height: auto;
}

.danButton {
  height: 30px;
}

.danLabel {
  border: 1px solid;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 7px;
  padding-right: 7px;
}

.danBadge {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
</style>
