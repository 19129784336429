<template>
  <b-navbar class="header px-0" fixed="bottom" type="light" variant="light" style="z-index: 50">
    <div class="col-1-5 mx-1 p-0">
      <div class="text-center nav-link">
        <router-link to="/records">
          <font-awesome-icon icon="clipboard-list" />
          <br />
          一覧
        </router-link>
      </div>
    </div>
    <div class="col-2-5 mx-1 p-0">
      <div class="text-center nav-link">
        <router-link to="/players">
          <font-awesome-icon icon="hand-fist" />
          <br />
          他会対策
        </router-link>
      </div>
    </div>
    <div class="col-3-5 mx-1 p-0">
      <div class="text-center nav-link">
        <router-link
          :to="{
            name: 'individualRecords',
            query: { userId: uid, userName: user.name },
          }"
        >
          <font-awesome-icon icon="id-card" />
          <br />
          <span>自分</span>
        </router-link>
      </div>
    </div>
    <div class="col-4-5 mx-1 p-0">
      <div class="text-center nav-link">
        <router-link to="/recentlyUpdatedProfiles">
          <font-awesome-icon icon="bullseye" />
          <br />
          <span>皆の目標</span>
        </router-link>
      </div>
    </div>
    <div class="col-5-5 mx-1 p-0">
      <div class="text-center nav-link">
        <a v-b-toggle.sidebar-1>
          <font-awesome-icon icon="bars" />
          <br />
          <b>その他</b>
        </a>
      </div>
      <b-sidebar id="sidebar-1" width="60%" right shadow backdrop>
        <div class="px-3 py-2">
          <div class="text-center">
            <b-img :src="user.photoURL" width="80px" class="align-items-center mb-2"></b-img>
            <br />
            <h4>
              {{ userName }}
            </h4>
          </div>
          <hr />
          <div class="nav-link">
            <router-link to="/individualRecords">個人ごとの記録</router-link>
          </div>
          <div class="nav-link">
            <router-link to="/commentBackup">コメントのバックアップ</router-link>
          </div>
          <div class="nav-link">
            <router-link to="/shokuhoe">職域でほえろ！過去ログ</router-link>
          </div>
          <div v-if="canReadAdvancedDocument">
            <div class="nav-link">
              <router-link to="/shidoNoShido">指導のための指導書</router-link>
            </div>
            <!-- TODO: 手引きも追加する -->
          </div>
          <div class="nav-link">
            <b-link href="https://www.karutalog.com/read" target="_blank">読み札アプリ</b-link>
          </div>
          <hr />
          <div class="nav-link">
            <b-link href="https://line.me/R/ti/g/vQW-ZU2WzF" target="_blank">練習会用LINEグループ</b-link>
          </div>
          <div class="nav-link">
            <b-link href="https://liff.line.me/1645278921-kWRPP32q/?accountId=599eqluj" target="_blank">
              ゲスト連絡LINE
            </b-link>
          </div>
          <div class="nav-link">
            <b-link
              href="https://docs.google.com/spreadsheets/d/14CoqITSohj7CnGZKVc3mYy1wccsdjks46clja4fnf4o/edit?usp=drivesdk"
              target="_blank"
            >
              ゲスト一覧
            </b-link>
          </div>
          <div class="nav-link">
            <b-link href="https://line.me/ti/g2/HlVPctBYjusnjL_ZwX4VKu9UEow_7nBBF87Dmg" target="_blank">
              大会実況オープンチャット
            </b-link>
          </div>
          <hr />
          <div class="nav-link">
            <router-link to="/userManage">ユーザー一覧</router-link>
          </div>
          <div class="nav-link">
            <router-link to="/emailSetting">メールアドレス変更</router-link>
          </div>
          <hr />
          <div class="nav-link">
            <b-link href="https://empty-salsa-619.notion.site/82f4d0913a8a40229fdd135d06b306f2" target="_blank">
              空蝉について
            </b-link>
          </div>
          <div class="nav-link">
            <a href="" style="padding-left: 0px" @click="logout">ログアウト</a>
          </div>
        </div>
      </b-sidebar>
    </div>
    <router-view />
  </b-navbar>
</template>
<script>
import { firebaseApp } from "../main";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue } from "@firebase/database";
import "dayjs/locale/ja";
import dayjs from "dayjs";
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

export default {
  name: "HeaderMenu",
  data() {
    return {
      user: {},
      uid: "",
      userName: "",
      showCollapse: false,
      canReadAdvancedDocument: false,
    };
  },
  watch: {
    $route() {
      this.showCollapse = false;
    },
  },
  created: function () {
    const currentUser = getAuth(firebaseApp).currentUser;
    this.uid = currentUser.uid;

    let _this = this;
    const database = getDatabase();

    const userRef = ref(database, `users/${currentUser.uid}`);

    onValue(userRef, (snapshot) => {
      if (snapshot) {
        _this.user = snapshot.val();
        // 利用申請する前はGoogleアカウントの表示名を出す
        if (this.userName == null) {
          _this.userName = currentUser.displayName;
        }
      }
    });
    const fiscalYear = this.getFiscalYear(dayjs().unix());

    const now = dayjs(); // 現在の日時を取得
    const isMarch = now.isBetween(dayjs("2022-03-01"), dayjs("2022-03-31"), null, "[]");

    // ユーザーの入会年度が現在の年度よりも小さい（上級生である）場合、または現在日付が3月1日から4月1日の間（執行の代替わり後）である場合は、上級生向けのドキュメントを表示する
    if (this.user.enterYear < fiscalYear || isMarch) {
      this.canReadAdvancedDocument = true;
    }
  },
  methods: {
    logout() {
      const auth = getAuth(firebaseApp);
      auth.signOut();
    },
    // TODO: 共通化する
    getFiscalYear(unixtime) {
      let year = dayjs(unixtime * 1000).format("YYYY");
      let month = dayjs(unixtime * 1000).format("M");
      if (month < 4) return year - 1;
      else return year;
    },
  },
};
</script>
<style scoped>
.nav-link {
  padding: 0px;
}

.nav-link a {
  font-weight: bold;
  color: #2c3e50;
}

.nav-link a.router-link-exact-active {
  color: #28a745;
}

.col-1-5,
.col-2-5,
.col-3-5,
.col-4-5,
.col-5-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 20vw;
  float: left;
}
</style>
