<template>
  <div>
    <!-- コメント一覧の検索条件 -->
    <b-row>
      <b-col sm="6" class="my-1">
        <b-form-group
          label="公開範囲"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select id="filter-input" v-model="filter" :options="commentTypeOptions"></b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6" class="my-1">
        <b-form-group
          label="フィルタ"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="投稿者、名前、内容で絞り込み"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" class="my-1">
        <b-button class="float-right" size="sm" :disabled="!filter" @click="filter = ''">クリア</b-button>
      </b-col>
    </b-row>

    <div class="comment-pagination-wrapper top">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="pagenated-comment-list"
      ></b-pagination>
    </div>

    <!-- テーブル本体 -->
    <b-table
      id="pagenated-comment-list"
      :items="paginatedCommentList"
      :fields="fields"
      :filter="filter"
      stacked
      @row-clicked="setFocusRecord"
    >
      <template #cell(commentType)="data">
        <div v-if="data.item.commentType == 'public'">公開</div>
        <div v-if="data.item.commentType == 'seniorMemberOnly'">
          <span>上級生のみ</span>
          <span>({{ data.item.upperLimitOfViewersEnterYear }}年入学まで)</span>
        </div>
        <div v-if="data.item.commentType == 'private'">自分のみ</div>
        <div v-if="data.item.commentType == 'directMessage'">DM</div>
      </template>
      <template #cell(upperLimitOfViewersEnterYear)="data">
        {{ data.item.upperLimitOfViewersEnterYear }}
      </template>
      <template #cell(unixtime)="data">
        {{ unixtimeToDateTime(data.item.unixtime) }}
      </template>
      <template #cell(reversedUnixtimeOfRecord)="data">
        {{ reversedUnixtimeToDate(data.item.reversedUnixtimeOfRecord) }}・{{ data.item.round }}回戦
      </template>
      <template #cell(text)="data">
        <p style="white-space: pre-line" class="text-break">
          {{ data.item.text }}
        </p>
      </template>
    </b-table>

    <div class="comment-pagination-wrapper bottom">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="pagenated-comment-list"
        @change="scrollToTopPagination"
      ></b-pagination>
    </div>

    <b-modal :id="editModalName" hide-header hide-footer modal-center>
      <Record :record="focusRecord" :modal-name="editModalName" :practice-type-list="practiceTypeList" :user="user" />
    </b-modal>
  </div>
</template>
<script>
import Record from "../components/Record.vue";
import { getDatabase, ref, onValue } from "firebase/database";
import dayjs from "dayjs";
import OPTIONS from "../constants/options.js";

export default {
  name: "CommentTable",
  components: { Record },
  props: {
    commentList: { type: Array, default: () => {} },
    tableType: { type: String, default: "" },
    practiceTypeList: { type: Array, default: () => {} },
    user: { type: Object, default: () => {} },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      focusRecord: {},
      fields: [
        {
          key: "unixtime",
          label: "投稿日時",
        },
        {
          key: "name",
          label: "投稿者",
        },
        {
          key: "commentType",
          label: "公開範囲",
        },
        {
          key: "reversedUnixtimeOfRecord",
          label: "日時",
        },
        {
          key: "player1Name",
          label: "名前1",
        },
        {
          key: "player2Name",
          label: "名前2",
        },
        {
          key: "text",
          label: "内容",
        },
      ],
      minusUnixTimeOfPlaceAndReaderModal: new Number(),
      roundOfPlaceAndReaderModal: new Number(),
      placeAndReaderList: [],
      readModeOptions: OPTIONS.READ_MODE_OPTIONS,
      commentTypeOptions: OPTIONS.COMMENT_TYPE_OPTIONS,
      userName: "",
      userEnterYear: "",
      filter: null,
      editModalName: "editModalFromCommentTable",
    };
  },
  computed: {
    totalRows() {
      return this.commentList.length;
    },
    paginatedCommentList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.commentList.slice(start, end);
    },
  },
  created: function () {
    let _this = this;
    const database = getDatabase();
    const userRef = ref(database, "users/" + this.user.uid);
    onValue(userRef, (snapshot) => {
      if (snapshot) {
        const user = snapshot.val();
        _this.userName = user.name;
        _this.userEnterYear = user.enterYear;
      }
    });
    this.editModalName = this.editModalName + this.tableType;
  },
  methods: {
    setFocusRecord(comment) {
      let record = {};
      const database = getDatabase();

      const recordRef = ref(
        database,
        "records/" + comment.reversedUnixtimeOfRecord + "/" + comment.round + "/" + comment.key
      );
      onValue(recordRef, (snapshot) => {
        if (snapshot) record = snapshot.val();
      });

      this.focusRecord = record;
      this.$bvModal.show(this.editModalName);
    },
    scrollToTopPagination() {
      const topPagination = document.querySelector(".comment-pagination-wrapper.top");
      if (topPagination) {
        topPagination.scrollIntoView({ behavior: "smooth" });
      }
    },
    unixtimeToDateTime(unixtime) {
      return dayjs(unixtime * 1000).format("YYYY/MM/DD HH:mm:ss");
    },
    reversedUnixtimeToDate(reversedUnixtime) {
      return dayjs(reversedUnixtime * 1000 * -1).format("YYYY/MM/DD");
    },
  },
};
</script>
<style>
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  width: auto;
}

.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  width: auto;
}
</style>
<style scoped>
.comment-pagination-wrapper {
  display: flex;
  justify-content: center;
}
</style>
