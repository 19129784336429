<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center flex-column align-items-center" style="height: 100dvh">
      <b-card no-body title="Card title" sub-title="Card subtitle">
        <b-card-body>
          <b-img :src="require('../assets/logo.png')" fluid center rounded="circle" width="100%" class="mb-4" />
          <b-card-title class="text-center">空蝉</b-card-title>
          <b-card-sub-title class="text-center mb-2">東京大学かるた会 練習記録アプリ</b-card-sub-title>
          <div id="firebaseui-auth-container"></div>
          <p class="text-center">※LINEからこの画面を開いた場合は、SafariやGoogle Chromeで開き直してください。</p>
          <hr />
          <b-btn v-b-toggle.accordion1 block variant="info">
            <span class="when-open">
              <font-awesome-icon icon="chevron-down" />
            </span>
            <span class="when-closed">
              <font-awesome-icon icon="chevron-right" />
            </span>
            はじめてログインする方へ
          </b-btn>

          <b-collapse id="accordion1" class="py-2" role="tabpanel">
            大学のメールアドレス（~@~.ac.jpおよび~@~.ed.jpなど）でのログインは行えないようにしています。
            <br />
            私用のメールアドレスでのログインをお願いします。
            <br />
            Sign in with Googleをタップしたあとに、私用のGoogleアカウントを選んでください。
          </b-collapse>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import firebaseConfig from "@/config/firebaseConfig";

// Firebase version 8
// NOTE: firebaseuiはv8でしか動かない
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// FirebaseUI
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  name: "LoginPage",
  data() {
    return {};
  },
  mounted() {
    firebase.initializeApp(firebaseConfig);

    // 認証設定
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function () {
          // 認証種類判定
          return true;
        },
      },
      // 認証時に同一ウィンドウで表示
      signInFlow: "popup",
      // ログイン後リダイレクト先
      signInSuccessUrl: "/records",
      // 各認証
      signInOptions: [
        // firebase.auth.emailAuthProvider.PROVIDER_ID,
        // Google認証
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      // 利用規約へリンク
      // tosUrl: '/termsofuse',
      // プライバシーポリシーリンク
      // privacyPolicyUrl: '/privacypolicy'
    };

    // 認証UI表示
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>
<style scoped>
.collapsed > .when-open {
  display: none;
}
.not-collapsed > .when-closed {
  display: none;
}
</style>
