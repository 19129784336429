<template>
  <div class="container">
    <b-modal
      id="modal-1"
      v-model="isModalOpen"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      :fullscreen="true"
    >
      <p class="text-center">
        現在、以下のGoogleアカウントででログインしています。
        <br />
        <b>{{ user.email }}</b>
      </p>
      <p class="text-center">当てはまるものを選んでください。</p>
      <b-container>
        <h5>空蝉を初めて使う方</h5>
        <b-row class="justify-content-center">
          <b-col cols="12" class="text-center my-2">
            <b-button
              variant="outline-success"
              @click="
                hideModal();
                isGoingToSignUp = true;
              "
            >
              利用申請画面に進む
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <h5>空蝉を既に使っている方</h5>
        <b-row class="justify-content-center">
          <b-col cols="12" class="text-center my-2">
            <b-button
              variant="outline-secondary"
              @click="
                hideModal();
                isWrongGoogleAccount = true;
              "
            >
              空蝉に未登録の
              <br />
              アカウントで
              <br />
              ログインしてしまった
            </b-button>
          </b-col>
          <b-col cols="12" class="text-center my-2">
            <b-button
              variant="outline-secondary"
              @click="
                hideModal();
                isUnauthorized = true;
              "
            >
              空蝉に登録済の
              <br />
              アカウントで
              <br />
              ログインしたのに
              <br />
              この画面が表示される
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <div v-if="isWrongGoogleAccount" class="d-flex justify-content-center align-items-center" style="height: 100vh">
      <b-container class="text-center" style="position: absolute; top: 50%; transform: translateY(-50%)">
        <p>
          空蝉に未登録のGoogleアカウント（
          <b>{{ user.email }}</b>
          ）でログインしている状態なので、このアカウントの情報を削除して、ログイン画面に戻ります。
        </p>
        <b-button v-b-modal.remove-user-modal variant="outline-secondary">ログイン画面に戻る</b-button>
      </b-container>
    </div>
    <div v-if="isUnauthorized" class="d-flex justify-content-center align-items-center" style="height: 100vh">
      <b-container class="text-center" style="position: absolute; top: 50%; transform: translateY(-50%)">
        <p>なんらかの理由でユーザのステータスが利用不可状態になっている可能性があります。執行の方にご連絡ください。</p>
        <b-button variant="outline-secondary" @click="$router.push('/')">ログイン画面に戻る</b-button>
      </b-container>
    </div>
    <b-modal id="remove-user-modal" hide-footer>
      <p>
        現在のGoogleアカウントは、
        <b>{{ user.email }}</b>
        です。
      </p>
      <p>このアカウントで空蝉を使っていない場合は、下記の「データを削除してログイン画面に戻る」を押下してください。</p>
      <b-row>
        <b-button class="mx-auto" variant="danger" @click="removeFirebaseAccount">
          データを削除してログイン画面に戻る
        </b-button>
      </b-row>
    </b-modal>
    <validation-observer v-if="isGoingToSignUp" ref="obs" v-slot="ObserverProps">
      <b-form-group
        label="名前（漢字）"
        description="名前の漢字表記が存在しない場合は、半角アルファベット表記を入力してください。"
      >
        <validation-provider v-slot="{ errors }" name="name" rules="required|isNoSpace">
          <b-form-input v-model="user.name" placeholder="東大太郎"></b-form-input>
          <p class="text-danger mt-2 mb-0 small">{{ errors[0] }}</p>
        </validation-provider>
      </b-form-group>
      <b-form-group label="名前（かな）" description="">
        <validation-provider v-slot="{ errors }" name="kana" rules="required|isHiragana">
          <b-form-input v-model="user.kana" placeholder="とうだいたろう"></b-form-input>
          <p class="text-danger mt-2 mb-0 small">{{ errors[0] }}</p>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="かるた会に入った年度"
        description="入会年度を間違えて申請してしまった場合は、執行経由で管理者にご連絡ください。"
      >
        <validation-provider name="enterYear" rules="required">
          <div v-if="hasEnterYear">
            <b-form-input v-model="user.enterYear" number maxlength="4" placeholder="1983" disabled></b-form-input>
          </div>
          <div v-else>
            <b-form-input v-model="user.enterYear" number maxlength="4" placeholder="1983"></b-form-input>
          </div>
        </validation-provider>
      </b-form-group>
      <b-form-group label="メールアドレス" description="">
        <validation-provider name="email" rules="required">
          <b-form-input v-model="user.email" disabled></b-form-input>
        </validation-provider>
      </b-form-group>
      <b-button
        variant="success"
        size="sm"
        class="my-2 mx-2 float-right"
        :disabled="ObserverProps.invalid"
        @click="updateUser()"
      >
        利用申請
      </b-button>
    </validation-observer>
  </div>
</template>
<script>
import { auth, firebaseApp, functions } from "../main.js";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, update, set } from "firebase/database";
import { httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import dayjs from "dayjs";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);
extend("isNoSpace", {
  validate(value) {
    return value.match(/^[^\s]*$/);
  },
  message: "スペースなしで入力してください。",
});
extend("isHiragana", {
  validate(value) {
    return value.match(/^[ぁ-んー]*$/);
  },
  message: "ひらがな、スペースなしで入力してください。",
});

export default {
  name: "ApplyingPage",
  components: {
    ValidationProvider,
  },
  data() {
    return {
      user: {
        name: "",
        kana: "",
      },
      hasEnterYear: false,
      isModalOpen: true,
      isGoingToSignUp: false,
      isUnauthorized: false,
      isWrongGoogleAccount: false,
    };
  },
  created: function () {
    const auth = getAuth(firebaseApp);
    const database = getDatabase();
    const userRef = ref(database, "users/" + auth.currentUser.uid);
    onValue(userRef, (snapshot) => {
      if (snapshot) {
        snapshot.forEach((data) => {
          if (data.key == "enterYear") {
            this.hasEnterYear = true;
          }
          this.user[data.key] = data.val();
        });
      }
    });
    this.user["email"] = auth.currentUser.email;
  },
  methods: {
    sendEmail() {
      if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "localhost", 5001);
      }

      const sendRegistrationNotificationToAdmin = httpsCallable(functions, "sendRegistrationNotificationToAdmin");
      sendRegistrationNotificationToAdmin({ email: auth.currentUser.email, name: this.user["name"] })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateUser() {
      const now = dayjs();
      this.user["lastUpdateDate"] = now.unix();

      // 名前、かなの空白を削除
      this.user["name"] = this.user["name"].replace(/\s+/g, "");
      this.user["kana"] = this.user["kana"].replace(/\s+/g, "");

      const auth = getAuth(firebaseApp);
      const database = getDatabase();
      update(ref(database, "users/" + auth.currentUser.uid), { ...this.user, authorized: false });
      this.sendEmail();
      alert("申請ありがとうございます。管理者が承認するまでお待ちください。");
      this.$router.push("/");
    },
    hideModal() {
      this.$bvModal.hide("modal-1");
    },
    removeFirebaseAccount() {
      const auth = getAuth(firebaseApp);
      const database = getDatabase();
      const userRef = ref(database, "users/" + auth.currentUser.uid);
      set(userRef, null);

      auth.currentUser
        .delete()
        .then(() => {
          alert("ログイン画面に戻ります。");
          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
          alert("ログイン画面に戻ります。");
          this.$router.push("/");
        });
    },
  },
};
</script>
<style>
.label {
  margin-bottom: 0rem;
}
</style>
