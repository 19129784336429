<template>
  <div class="container">
    <HeaderComponent />
    <div>
      <b-button v-b-toggle.threads variant="success" size="sm" class="my-2 mx-1">スレッド一覧</b-button>
      <b-collapse id="threads">
        <div>
          <div v-for="(thread, thread_id) in sortedBbsData" :key="thread_id">
            <a :href="'#' + thread.thread_id">
              {{ thread.thread_id }}. {{ thread.title }}({{ Object.keys(thread.data).length }})
            </a>
          </div>
        </div>
      </b-collapse>
    </div>
    <hr />
    <b-button
      variant="success"
      size="sm"
      style="position: fixed; right: 10px; bottom: 74px; z-index: 1000"
      @click="scrollToTop"
    >
      <font-awesome-icon size="lg" :icon="['fas', 'arrow-up']" />
    </b-button>
    <div>
      <div v-for="(thread, thread_id) in sortedBbsData" :key="thread_id">
        <b-card :id="thread.thread_id">
          <b-card-title>{{ thread.thread_id }}. {{ thread.title }}</b-card-title>
          <b-card-text>
            <div v-for="(post, post_id) in thread.data" :key="post_id">
              <b>{{ post_id }}:{{ post.author }} {{ post.date }}</b>
              <br />
              <p style="white-space: pre-line; word-wrap: break-word">
                {{ post.content }}
              </p>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "../components/Header.vue";
import bbsData from "../assets/bbs.json";

export default {
  name: "ShokuhoePage",
  components: {
    HeaderComponent,
  },
  computed: {
    sortedBbsData() {
      let array = new Array();
      for (let key in bbsData) {
        let obj = bbsData[key];
        obj["thread_id"] = key;
        array.push(obj);
      }
      return array.reverse();
    },
  },
  methods: {
    scrollToTop() {
      window.scroll({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style scoped>
.label {
  margin-bottom: 0rem;
}
</style>