<template>
  <div class="container">
    <HeaderComponent />
    <b-button
      v-b-toggle.shido-no-shido-sidebar
      style="position: fixed; right: 10px; bottom: 74px; z-index: 1000"
      variant="success"
    >
      目次を開く
    </b-button>
    <b-sidebar id="shido-no-shido-sidebar" title="目次" shadow backdrop>
      <div class="px-3 py-2">
        <b-nav vertical>
          <h5>第一部 参考資料と理論</h5>
          <b-nav-item href="#h3-id-1">参考資料と歴史的背景</b-nav-item>
          <b-nav-item href="#h3-id-2">田口さんの著作について補足。</b-nav-item>
          <b-nav-item href="#h3-id-3">「激攻めかるた指導」と「普通の攻めかるた指導」の違いは？</b-nav-item>
          <b-nav-item href="#h3-id-4">かるたを見て学べ</b-nav-item>
          <b-nav-item href="#h3-id-5">「スタイル」確立と「マニュアル」</b-nav-item>
          <b-nav-item href="#h3-id-6">コラム：指導システムと経験者</b-nav-item>
          <b-nav-item href="#h3-id-7">コラム：コーチ理論・・・ティーチングとコーチング</b-nav-item>
          <b-nav-item href="#h3-id-8">コラム：A級の位置付け？</b-nav-item>
          <b-nav-item href="#h3-id-9">コラム：体育会的指導？技術指導？</b-nav-item>

          <h5 class="pt-4">第二部 具体的な指導法</h5>
          <b-nav-item href="#h3-id-10">どの程度の時間の指導が必要か</b-nav-item>
          <b-nav-item href="#h3-id-11">良い練習とは、良い『癖』を身体につけること。</b-nav-item>
          <b-nav-item href="#h3-id-12">基本的心構え</b-nav-item>
          <b-nav-item href="#h3-id-13">かるたは速度ではなく加速度。フォロースルーが重要。</b-nav-item>
          <b-nav-item href="#h3-id-14">身体に覚えさせる。その１イメージのレール。素振りはリハーサル。</b-nav-item>
          <b-nav-item href="#h3-id-15">身体に覚えさせる。その２札を狙って早く取らせる</b-nav-item>
          <b-nav-item href="#h3-id-16">狙った一字以外の札、空札。・・・無反応には終わらない。</b-nav-item>
          <b-nav-item href="#h3-id-17">手が上空で止まってしまう人・・・そのまま手を引かない。</b-nav-item>
          <b-nav-item href="#h3-id-18">新人にやる気を出させる・・・送り止めなどの工夫。</b-nav-item>
          <b-nav-item href="#h3-id-19">勝ち負けについて</b-nav-item>
          <b-nav-item href="#h3-id-20">一年生どうしの対戦について</b-nav-item>
          <b-nav-item href="#h3-id-21">お手つきなしルール・定位置作成について</b-nav-item>
          <b-nav-item href="#h3-id-22">自陣について</b-nav-item>
          <b-nav-item href="#h3-id-23">初心者にとっての自陣の取り</b-nav-item>
          <b-nav-item href="#h3-id-24">コラム：狙い・暗記・把握</b-nav-item>
          <b-nav-item href="#h3-id-25">暗記・狙いについて</b-nav-item>
          <b-nav-item href="#h3-id-26">感じの鍛え方</b-nav-item>
          <b-nav-item href="#h3-id-27">複数箇所に散った札のとり方</b-nav-item>
          <b-nav-item href="#h3-id-28">聞き分けの鍛え方</b-nav-item>
          <b-nav-item href="#h3-id-29">構え、払いについて</b-nav-item>
          <b-nav-item href="#h3-id-30">送りについて</b-nav-item>
          <b-nav-item href="#h3-id-31">定位置について</b-nav-item>
          <b-nav-item href="#h3-id-32">戦略について</b-nav-item>
          <b-nav-item href="#h3-id-33">経験者への指導について</b-nav-item>
          <b-nav-item href="#h3-id-34">終わりに</b-nav-item>
        </b-nav>
      </div>
    </b-sidebar>
    <div class="py-2">
      <h1>指導のための指導書</h1>
      <div>
        <h2 id="introduction" class="pt-4">Introduction</h2>
        <p>
          今回、初心者指導のやり方をまとめてみました。構想自体は以前からあったのですが、正直書いていいものかどうか悩んでいました。ＯＢがあからさまに現状を否定したり口を出したりすることは、ときに現役生のやる気をそぐことになると心配したからです。しかし現役生と話をしてみると、思っていた以上に「自分もどう指導していいのか分からず悩んでいるのでぜひアドバイスが欲しい」という声が多かったのです。そこでまずは、ごく初期（Ｅ級からＣ級）の選手向けの指導書をつくってみました。指導の仕方が具体的にわからない・・・という人は、ぜひ参考にしてみてください。ただし、マニュアルの上っ面だけを教えるのではなく、指導の本質を捕らえてよく教えてください。
        </p>
        <p>
          まずこの指導書を読む前に、川村氏の掲示板のコラム「東大かるたの成長過程」をよく読んでみることをお薦めします。初心者に指導すべきことは山のようにあるため全てを最初から一回の試合に全て教えきるのは不可能で、順を追って１つづつ教える必要があります。川村掲示板の「東大かるたの成長過程」は新入生にマスターさせる指導の順番を、１ページにコンパクトにまとめてあります。何から教えていいものか分からないために、結局札の決まり字と送りだけを教えるだけになってしまってた人は必読です。ぼくが書くこの著作はその詳細説明版と思ってください。
        </p>
        <p>
          「東大かるたの成長過程」を読んだ後は、ぼくのサイトにアップしてある早大・慶應・暁星の指導書をまず読んで見てください。これらを上の「・・成長過程」と比べてみると、どこの会でもほぼ同じことを強調していることが分かります。攻め方・定位置・狙い方・感じの育て方・暗記の仕方・構えと払い・Ｂ級以前やＢ級やＡ級それぞれで何をするか・・といったことが非常に良く分かります。ぜひ読んでください。とくに、早大の指導書を読むとかつての東大の指導を褒め称えていますが、その中には今の東大に欠けてしまっているものもあるかもしれません。じっくり検討してみてください。
        </p>
        <p>
          ぼくはなるべく、これらの４つの指導書が書ききれなかった細かい方法論、特に百枚覚える前からＣ級くらいまでの初心者を対象にした指導の仕方、つまり指導の初歩の初歩をまとめてみたつもりです。上記の過去の著作はみな「指導書」でしたが、これは「指導のための指導書」です。第一部に全体的な考え方、第二部に具体論が書いてあります。
        </p>
        <p>
          どんなスポーツでもそうですが一度悪い癖がつくと直すのに苦労をします。かるたでもワンテンポ遅く取る癖や、自陣に頼る癖を一度つけてしまうと、なかなか脱出に苦労します。のちのち本人が意図的にものすごく禁欲的な努力をする必要があります。だから、最初の最初の指導の方向性が肝心なのです。無論、選手本人の努力が一番大事なのは当然で、指導が良かったからといってみなが強くなるとは限りませんし、逆に指導の仕方が多少悪くても自分で努力して強くなっていく人もいるでしょう。でも選手各自の努力のみに任せていたのでは、他大学に差をつけることは出来ません。かるた推薦入学のない東大は、その分、選手育成力で他大学に大きく上回らねば他と勝負できません。それが十分可能であることは何度も実証されています。重要となるのは、初心者に対してなすべき指導を、他大学よりも如何に徹底的に行うか、ということになります。
        </p>
        <p>
          指導で大事なのは、指導内容に確信を持ち、自信を持って教えるとともに、初心者もそれを確信できるよう熱意を込め説明に工夫を加えることだと思います。東大の指導をしっかり実践すれば必ず誰もが強くなるという信念と、後輩を強くしようという熱意と愛情があれば、指導する側がD級であっても着実に名指導者になれます。是非、みなで東大を素晴らしい会にしていきましょう。
        </p>
      </div>
      <div>
        <h2 id="part1" class="pt-4">第一部 参考資料と理論</h2>
        <h3 id="h3-id-1" class="pt-4">〇参考資料と歴史的背景</h3>
        <p>
          以下の各会の指導書は本当によくできています。どれも非常に良いことが書いてあり、一言一言に含蓄があるので、何度も何度も読んで欲しいと思います。また、月日を置いて改めて読むとまた違ったことを教えられるので、座右の書としてことあるごとに読むとよいでしょう。３は、早大内部限定資料で他大学には絶対見せるな、とされていたものを小林が個人的にもらったものです。したがって、ぼくが個人的に責任の持てる範囲（東大の現役生）のみに絞って見せることとします。この早大の指導書が東大で知れ渡っていることを知ったら早大の人は快くは思わないかもしれませんので、その点は十分注意のほどお願いします。新しい人に見せたいときは必ず小林まで連絡ください。
        </p>
        <p>※以下、文書名をクリックするとPDFを閲覧できます。パスワードは景虎の共通パスワードです。</p>

        <b-list-group>
          <b-list-group-item>
            <h4 class="pt-4">
              1. 東京東会波多野俊氏
              <a
                href="https://www.dropbox.com/s/hchqe4oqktur00c/%E3%81%8B%E3%82%8B%E3%81%9F%E3%81%AB%E5%BC%B7%E3%81%8F%E3%81%AA%E3%82%8B%E3%81%9F%E3%82%81%E3%81%AB.pdf?dl=0"
                target="_blank"
              >
                かるたに強くなるために
              </a>
            </h4>
            <p>
              波多野氏は高校時代にかるたを始めていたものの、本格的に取り組んだのは早大入学後。全国選抜優勝、全日本選手権優勝、東日本代表の経験がある。自分が強くなるだけでなく、早大時代も東会移籍後も多くの後輩を育てた。なお、松川会長は次期東会会長は波多野氏と名言している。
            </p>
            <p>
              この著作では、攻め・狙い・払い・暗記といった項目を非常に分かりやすくコンパクトにまとめている。必読の書である。
            </p>
          </b-list-group-item>
          <b-list-group-item>
            <h4 class="pt-4">
              2. 慶應かるた会種村貴史永世名人
              <a
                href="https://www.dropbox.com/s/8qf8lo0cjgvgyfq/%E8%B6%85A%E7%B4%9A%E3%81%B8%E3%81%AE%E9%81%93.pdf?dl=0"
                target="_blank"
              >
                超Ａ級への道
              </a>
            </h4>
            <p>
              言わずと知れた種村永世名人は、大学からかるたを始めて在学中に名人になった。自分が強くなるだけでなく、やはり多くの選手を育てた。筆者も現役時代は毎月種村永世名人主催の練習で世話になった。
            </p>
            <p>
              この著作を読むと、Ａに上がってから勝つためにどうするか、そしてそのためにはＡに上がるまでに何をしておかなければならないか、がよく分かる。
            </p>
          </b-list-group-item>
          <b-list-group-item>
            <h4 class="pt-4">
              3. 暁星かるた会田口貴志氏
              <a
                href="https://www.dropbox.com/s/yje33cpc1waec74/%E6%97%A9%E7%A8%B2%E7%94%B0%E5%A4%A7%E5%AD%A6A%E7%B4%9A%E3%81%AB%E3%81%AA%E3%82%8D%E3%81%86%E5%AE%A3%E8%A8%80.pdf?dl=0"
                target="_blank"
              >
                早稲田大学Ａ級になろう宣言
              </a>
            </h4>
            <p>
              これも有名な田口先生。早稲田大学でかるたを始めて卒業後準名人になった。氏自身はいろいろとやかく言われるが（笑）、それはさておき選手育成理論に関しては業界髄一といえよう。
            </p>
            <p>
              この著作は
              1995,6年ころに書かれたが、後で詳しく書くようにその当時は、東大が指導のやり方を確立し毎年Ａ級を多数輩出するようになった一方、早大は攻めかるた指導が壊れ年に
              2,3人しかＡ級に昇級できなくなっていた。そんな時代に早大の後輩向けにかかれた指導書である。このように当時の早大の昇級者数の少なさは、今の東大と似ているといってよいだろう。
            </p>
          </b-list-group-item>
        </b-list-group>
        <p>
          ４つの文献をみると、学生かるた会で攻めがるた指導で実績を残した人は、東大でなくても実は同じことを教えている、ということが分かっていただけると思います。つまり、かるたを始めて誰もが最も効果的に強くなるための基礎的なトレーニングは普遍的なものです。「東大かるた」が東大独自のものであると思い込んでいる人もいるかもしれませんが、実はそうではないことが分かると思います。では、東大かるたとは何か？そう、東大の優れた点は、要するにその
          <b>普遍的な基礎的方法論を、徹底的に繰り返し身に付ける</b>
          ということにあったのです。
          <b>
            特に、良い癖をつけるのに最も効果的なごく初心者の時期に、先輩全員が指導者になって徹底して基礎を後輩に叩き込む、その指導システムこそが東大の最大の武器であったといえる
          </b>
          と思います。
        </p>
        <h3 id="h3-id-2" class="pt-4">〇田口さんの著作について補足。</h3>
        <p>
          田口さんの著作の意図を理解するには、当時の状況をちょっと知っておく必要があると思うのでここで補足しましょう。
        </p>
        <p>
          上にも書いたように、この著作が書かれた90年代半ばは、いわば東大の“全盛期”でした。現役生は初心者から始めた選手ばかりでしたが、それでもA級選手が25人ほどいました。その大学でかるたを始めた現役生でも5-6人が現役のうちにA級優勝し、準名人、名人戦東日本代表、全国選抜優勝、と言った選手が次々と東大の現役生から出ていた時代でした
        </p>
        <p>
          一方当時早大は、“かるた推薦入学”によりクイン戦東日本代表、全国選抜優勝者、全日本選手権優勝者、といった全国最高レベルの選手が職域早大Aチームに並んでいて職域の優勝を東大と争っていました。が、その一方、早大は初心者が全然育たず、年に2，3人しかA級に昇級しないというひどい状況だったのです。
        </p>
        <p>
          東大と早大の初心者育成力に大きな差が出た原因は明らかで、それが初心者指導システムの差でした。90年代の東大は、それ以前の東大と比べても現在の東大と比べてもとにかく徹底度の高い激攻め指導システムを作り上げていたのです。先輩全員が指導者となり厳しく鬼攻めを新入生に学ばせていました。
        </p>
        <p>
          このことに非常に大きな危機感を持ったのが当時すでに早大OBだった田口氏です。氏の現役時は早大も指導が行き届き、東大と同じように毎年7，8人のA級昇級者がちゃんと出ていたのです。が、氏の卒業後に、指導体制が崩壊しました。そこで後輩に向けてこの指導書を記したのです。
        </p>
        <p>こうした当時の時代背景を知っていると、この田口氏の著作の意図も分かると思います。</p>
        <p>
          この田口氏の指導書のうち早大についての記述と東大についての記述を下に拾ってみました。当時の東大の指導のうち、今抜けてしまったものはないだろうか？あるいは当時の早大の記述に、
        </p>
        <p>
          現在の東大と当てはまってしまっているものはないだろうか？チェックしながら読んでみてほしいと思います。もし少しでもあれば、先輩が責任を持って改善してください。
        </p>
        <h4 class="pt-4">＜田口氏の指導書のなかにある当時の東大についての記述＞</h4>
        <p>
          ★（早大は）後発でありながら、マニュアル化された指導方法を持つ東京大学かるた会に、まずA級選手養成面に圧倒的に遅れをとった。当然A級選手そのもののレベル、団体戦の伝統、名人・クィーンの養成などについても、（A級選手の数自体で劣っているのだから〉東京大学の後塵を拝していることを自覚する日も来るのであろう。（実際彼等はそれらについてもすでに議論を公的に始めているのである。）
        </p>
        <p>
          ★（早大は）後発でありながら、マニュアル化された指導方法を持つ東京大学かるた会に、まず A
          級選手養成面に圧倒的に遅れをとった。当然 A
          級選手そのもののレベル、団体戦の伝統、名人・クィーンの養成などについても、（ A
          級選手の数自体で劣っているのだから〉東京大学の後塵を拝していることを自覚する日も来るのであろう。（実際彼等はそれらについてもすでに議論を公的に始めているのである。）
        </p>
        <p>
          ★（一般的には）感じを伸ばすことを意識した確実な練習法はあまり確立されていない。強いて挙げれば東大の攻めがるた
        </p>
        <p>
          ★一般に「感じ」と呼ばれる物の中には訓練可能・まだまだ磨けば光る可能性を持つ部分があるはずである．たとえばそれはさっき述べた、詠まれる瞬間に加速度をつけることであり、そのタイミングを研究することや、それが分からなければとりあえず詠まれた瞬間（敵陣に向かって？）とにかく手を動かし始めることを心がけることなどで、訓練できる。敵陣方向（？）に手を押し出して、ちゃんと聞けて、暗記が完全ならば、普通の上級者（
          B
          級程度を含む）ならば、その後、敵陣の出札に向かって手が運ばれるのではないか？（いわゆる東大でいう「払いに感じをのっける」）
        </p>
        <p>★攻めがるた中心の東大を見てわかるとおり、攻めていて A 級になれない人はむしろ少数派である．</p>
        <p>
          ★攻めがるたは、特に技術が未熟なほど、出札に左右される．その上自分の陣は、どの人もたいがい取りやすいのに、その取れる札を我慢して敵陣を取ろうとすることは、精神的に辛いことである．（その点、まだどこも取れない初心者のうちに攻めを教える東大は合理的である．）
        </p>
        <p>★東大のように、練習では常に攻めがるた自体がテーマであり、攻めがるたを意識させて練習させる</p>
        <p>
          ★三字札の取り方には大きく二種類あり、ひとつは決まり字が詠まれるまでに札に近づいておく「貯金型」、いまひとつは決まり字が詠まれた瞬間札へと猛烈に飛び込む「加速型」である．前者は仁弘さん
          ( 望月元名人 ) や東大勢の選手に見られ、
        </p>
        <p>
          ★東大生でも、 B ， C 級の頃は、三字札の上空で手が止まっていたことも多く見受けられるが、 A
          級の強い選手にもなれば、出札まではゆっくり札に近づき、決まり字で「ドーン」と払う型ができる．
        </p>
        <p>
          ★（感じを鍛えるには）手を、できるだけそれと意識することなく、音を感じる前に動かし始めることである．どのくらいか？ごくわずかでよい．ほんのちょっと相手方向へ、場合によっては手を動かさずに加速度だけ、さらに言えば相手方向でなく、畳から浮かす上方向だけでも良いかもしれない．あるいは攻めがるたが基調であるのだから、極端な話、一気に自陣を飛び越え、敵陣との境目まで、も、一概によくないと言えない．ここまで極端でなくとも、東大の選手は似たようなことをやって強くなっている．
        </p>
        <p>
          ★聞いてないつもりで手を出しても、感じとして認識するよりは早く身体のどこかで感じているもので、先ほどの実験通り出札へと手が進む．東大の攻めがるたが感じを伸ばすのは、あれだけはやく札を取れるのは、この原理が一役かっている．そして時には、例えば
          2 － 1
          で東大生が勝っている時、敵陣しか狙っていないはずの彼等が信じられないはやさで自陣を守ることができるのも、普段からこの練習を積み、身体のどこかで感じることができたからである．
        </p>
        <h4 class="pt-4">＜田口氏の指導書のなかにある当時の早大についての記述＞</h4>
        <p>
          ★
          個々の選手によりかるた自体がバラバラであるため聞く人によって言っていることが違うようであるし、時には明らかに初心者の指導に馴染まないことを指導している場合が見受けられる
        </p>
        <p>★ 上級者同士が、自分のかるたの世界を越え、指導そのものについてすべき議論の欠落が見受けられる．</p>
        <p>
          ★
          「もっと頑張れ！東・慶に負けるな！！」と精神論ばかり強調し、ろくに方法論を提示せず、あげく、はっきり後輩を強くしたという結果を出せない
        </p>
        <p>
          ★
          方法論を語ろうにも、自分の持っている方法論（人によっては自分自身自信のない）のみでは不安である。みんなが認めたものがないのでは教えにくいはずである。
        </p>
        <p>★ 現在の早稲田は一年に 2 ～ 3 人の A 級を育てることしかできない会であるため、 A 級養成が急務である。</p>
        <p>
          ★
          （「感じ」を才能と見なしてしまい感じを育てる練習をせず）「才能の差」で負けたのならば、少なくとも自分の努力不足のみが理由ではなさそうなので、ある程度自分を許せそうな気がするため、敗戦の弁解によく使われる。
        </p>
        <p>
          ★
          もともと早稲田のかるたは伝統的に攻めがるたであり、その方法論も先輩からの指導を通じて伝えられていたようだ．しかし、現在、後輩に攻めがるたを教えるための環境はといえば、整っているとは言えない．
        </p>
        <p>
          ★
          現在、攻めがるたという言葉が全く聞かれないかと言えば、決してそうではない．しかし、「攻め」が、単なる精神論に堕しているように感じる．大会で負けた時、「どうして負けたの？」「攻めが甘かったようです．」「じゃあ次どうする？」「もっと攻めます」という程度の会話しかできないと、次の大会までの具体的な練習方法が分からない。
        </p>
        <p>
          ★ 早稲田では、 B
          級と言えども攻めがるたをどうやって身につけるか分かっていないと、何も先輩から教えてもらえないので、全く身につかないおそれがある．
        </p>
        <p>
          ★
          例えば動かず手もとで「うか」か「うら」を聞き分けて、その札へ一直線に行って取ろうとした場合、自陣は近いので取れ、敵陣も相手が戻るより早くは取れて、なんとなく病みつきになってしまいちである。しかし、一定以上のスピードで攻めてくる選手からは守れず、真摯にスピードを求めて取る攻めがるたの選手から戻られてしまうこともたいへん多い．攻めがるたのできる
          A 級選手ならば、時に技術としてそのような手元で聞く取りを見せることも構わないが、 B
          級レベルでは単なる怠慢、攻めがるたをする上での技術向上の意識の欠如である．この「病気」は、早稲田大学かるた会でたいへんに蔓延している「世紀の奇病」である。あるいはこの病気のために、初心者への指導がガタガタになってしまったのかもしれないとさえ私見する．しかも、攻めがるたの選手からどちらも取るという快感があるため、たいへんたちが悪い．
        </p>
        <p>
          ★ 週 4
          回の練習を設定している早稲田大学かるた会は、決してその技術習得に欠けているとは思えない．個人的になかなか練習にくることが難しい人であっても、チャンスがあれば練習量では、他の会より遥かに有利な環境である．しかしながら、昨今の大会結果を見る限り、その環境を生かしているとはとても思えない惨状であり
        </p>
        <p>
          ★ 「かんばって練習すれば A 級になれる．」「 A
          級になるためにはもっと練習しなきゃ．」と、一見正論に見える精神論に陥りがちであるが、後輩を A
          級にする使命のある先輩としては、選手に練習を要求するだけではあまりにも芸がない．
        </p>
        <p>
          ★ かるたが攻めでもなく、守りでもなく、中途半端な選手というのは、 A
          級になれない理由として意外に多い．特に欠点がないようなのに、試合では決め手なく、中盤から終盤にかけて、ずるずると寄り切られてしまう．早稲田の選手もよく直面する課題である．自由なかるたを標傍している時期もあったようだが、そろそろ攻めがるたの優位性を再認識したい時期である．先輩は自信をもって攻めがるたを後輩に指導して欲しい．（と個人的に希望するし、それが多くの早稲田における競技かるたへの参加者にとって、有意義であると自信を持って思う．）
          C ・ D 級ならともかく、 B 汲優勝は、低い点をまんべんなく並べるより、ズバ抜けた特技があるほうがしやすいし、 A
          級での活躍につながる．
        </p>
        <h3 id="h3-id-3" class="pt-4">〇「激攻めかるた指導」と「普通の攻めかるた指導」の違いは？</h3>
        <p>
          ここでは便宜的に、あえて「激攻めがるた指導」という言葉を定義してみましょう。東大で（または田口さんの著作で）「攻めかるた」と呼ばれるかるたと、世間一般的に「攻めかるた」と言われるかるたとの違いを強調するために、敢えて前者を、ここで「「激攻めがるた」としてみます。
        </p>
        <p>
          さて普通の攻めがるたにとって攻めとは、たとえば攻めることが最も戦略上有利であるという、言わばゲーム的な根拠しかありません。その指導では、田口氏が当時の早大の現状と嘆いてるように、どうやったらスピードが増すかといった技術的指導はほとんどなされず、送り札や、「あの札を抜くべきだったね」といった戦略的指導ばかりになります。そのため、初級者でまだスピードの育たぬのうちから目の前の試合に勝つためだけに手元で聞いて自陣も取ろうとする練習に走ることになりがちです（田口氏のいう当時の早大の「世紀の奇病」）。一方、「激攻めかるた」は、
          <b><u>根本的な取りの強度を強めるために</u></b>
          攻めを利用します。
          <b>
            <u>
              一番遠い札の一字を名人からも抜くべく狙いを定め、敵陣を全部取るべく捨て身の攻撃を繰り返し練習することによって、強くなるために必要な感じの速さ、払いの強度を磨くのです。
            </u>
          </b>
          つまり攻める意義としては、ゲーム的意義よりもスポーツ的な意義が主となります。その指導は、「決まり字前にどんどん札上空まで飛び出せ、空札のときに失速しないようにしよう」といった指導が中心になります。
        </p>
        <p>
          「激攻めかるた」は、敵陣攻め潰しが第一目的ゆえ、自陣は相手の一瞬の攻めこぼしを戻り手で確実に拾えれば十分と考えます。それくらいでないと将来的になかなか
          A
          に上がれないし、上がってもＡ級で通用しません。それゆえ初心者のうちに徹底して基本的な抜きを磨くのが「激攻めかるた」の特徴です。「普通の攻めかるた」は遅い人でも戦略を駆使して強くなれるメリットがありますが、初心者からそのかるたを目指すのは、その選手の可能性を削ぐことになります。なぜなら遅い取りが身に付いてしまっていると、のちのち取りを早くするのはかなり困難だからです。だから、「激攻めかるた」は
          A 級になれるだけでなく A
          級になってから戦略をみにつけることで、さらに伸びる余地があるかるたである、といえましょう。東大かるた会でタイトル候補と呼ばれた選手が全員、上に書いた「激攻めかるたが指導されていた年代」に集中しているのはそれゆえです。
        </p>
        <p>
          <b>
            「普通の攻めかるた」は目の前の試合に勝つためのゲーム戦略。「激攻めかるた」はそれに加えて、将来強くなるためのスポーツトレーニング
          </b>
          といえます。
        </p>
        <p>
          攻めだけに徹して勝つためには生半可な攻めではダメです。徹底して感じを鍛え、徹底して払いや聞き分けを鍛えなくてはいけない。初心者のうちにそこを目指すからこそ、札を取るための一番基礎的な力が伸ばせるのです。逆に、敵陣が取れなくても自陣を守れば勝てると思っていては攻めの力はなかなか伸びないのです。とはいっても、会の指導が徹底しないとそこまで自分をコントロールしにくいものです。その点、
          <b>先輩が後輩に対してストイックに徹底させたところが東大の指導システム</b>
          の長所といえましょう。
        </p>
        <p></p>
        <p>
          断っておきますが、スピードの遅い人が勝つための戦略だって重要です。それがかるたの醍醐味とも言えます。特に、練習量が減ったりしてこれ以上スピードが伸びないとなったベテランはこのような戦略でカバーすることが必要です。そこで「普通の攻めがるた」のセオリーも理解しておいて、将来活用してほしいです。つまり、本著作では「普通の攻めがるた指導」と「激攻めがるた指導」を便宜上分けましたが、将来的にスピードも戦略も兼ね備えた選手を目標とする点で、最終的には両者は同じものです。決して戦略を軽んじているわけではないです。戦略の指導を初心者のうちに受けることがあった時は、ぜひ頭では「そういうこともあるのだな」と理解してもらいたいです。（結局のところは両者に違いがないため、敢えて「激攻めがるた」を定義する必要も本当はないのだが、ここでは初心者指導システムの考え方の違いを強く意識するべきこの言葉を敢えて用いた）
        </p>
        <p>
          しかし、基礎的な敵陣右下段への激攻め・狙い方・手の動かし方・・などを教えずに、初心者のうちから戦略面に指導が偏りすぎてはまずいでしょう。スピードがあるからこそ戦略が生きるのです。伸びのあるストレートと巧みな配球があって初めて変化球を生かすことが出来るとも言えます。トランプで切り札なしでも戦略をめぐらせて勝つ醍醐味はなるほど格別ですが、まず切り札を手にすることが、もっとも勝つための近道なのです。戦略におぼれてはいけません。戦略は後からでも学べます。基礎が出来た後で戦略を学べば、鬼に金棒です。
        </p>
        <p>
          いきなり戦略に走ると基礎がおろそかになるのはどんなスポーツでも同じことです。かるたの練習中に指導できることは時間的に限られるので、戦略よりも重要なことを教えそびれないようにしましょう。
        </p>
        <p>
          ということで、以下の文章で、「攻めかるた」と書いた場合は、世間一般の「攻めかるた」ではなく東大でいうところの「攻めかるた」（＝激攻めかるた）ととらえていただきたいです。
        </p>
        <h3 id="h3-id-4" class="pt-4">〇かるたを見て学べ</h3>
        <p>
          マニュアルどおりやれば誰でも強くなるのか？結論から言うとそれはありえません。上にも書いたように、東大の指導はＡ級になるための効率の良い基礎の習得法として最高のものですが、それだけにしか過ぎません。個々人のスタイルの確立の仕方については語ってくれません。例えば勝負所で精神的に脆い人がどうしたらよいか、といったことは全く語ってくれないのです。
        </p>
        <p>この点にこそ、個々人の研究や、各個人にあったきめ細かい指導が生きます。</p>
        <p>
          したがって、指導マニュアルを理解し実践した先には、さらに指導法をもっと研究をして欲しいものです。後のコラムで書く「コーチング」です。
        </p>
        <p>
          たとえば、どういう心理状態で自分が強いか？かるたがメンタルスポーツである以上一番の研究どころです。結局は自分の感覚でつかむしかありませんが、それをつかませる助言が良き指導です。どういう払いをしたら一番いいのか？練習時間外での払い練などかんがえて（考えさせて）ほしいのです。
        </p>
        <p>強い人はどうして強いのか？他人の試合をみて学んで（学ばせて）欲しいのです。</p>
        <p>自分のスタイルはどう確立するか？自分と似たタイプの人を会の内外で探して欲しいのです。</p>
        <p>他の選手の弱点はどこか？大会で負けた後じっくり試合を見て学んで（学ばせて）欲しいのです。</p>
        <p>
          そのほかにも、たとえば「練習では思うように取れるが大会ではとれない」「Ａ級の強い人に勝てるのにＢ級の大会で弱い（と思った）人に負ける」なんていう人に対してはしっかり原因究明と対策にいそしませてください。研究すべきところはいっぱいある。もちろん、まず初心者には基礎技術的なところを学ばせることを忘れないでください。
        </p>
        <h3 id="h3-id-5" class="pt-4">〇「スタイル」確立と「マニュアル」</h3>
        <p>
          上に「自分のスタイル」と書きましたが、どこまでが共通のマニュアルで、どこからが自分のスタイルなのでしょうか、補足しましょう。
        </p>
        <p>
          攻めを磨くための効率的な練習方法が「マニュアル」ですが、それと、自分の「スタイル」を確立するのは別の話です。マニュアルはあくまで最大公約数の、誰もが身に付ける基礎の基礎のものと考えればよいでしょう。
        </p>
        <p>
          たとえば
          <b><u>きっちり敵陣右下段の</u></b>
          <b><u>1</u></b>
          <b><u>字を抜くスピードをつける、というのはどんな体格の選手でも音の感じ方の選手でも絶対必要なことです。</u></b>
          しかし、体格や得意音には個人差があります。先輩の中には、自分の構えや定位置が一番と信じ込んで教え込もうとする人がたまにいますが、それはあくまでその本人にとって一番、というだけであることを忘れてはいけません。これが自分の「スタイル」となります。実は東大は意外と画一ではなく、払いも定位置もみんな微妙に違います。これがスタイルの違い、の意味するところです。
        </p>
        <p>
          また、「マニュアル」をただ理由もわからず教える、というのは避けるべきです。たとえば杓子定規にどんなときも送り返しはしない、と教えてしまっては、実戦で使えないカルタになってしまいます。重要なことは、マニュアルの根底にある「理由」をしっかり教えることです。なぜ送り返しはしないのか、なぜ「むすめふさほせ」は送らないか、などの理由をしっかり（練習中でなくても）教えましょう。
        </p>
        <h3 id="h3-id-6" class="pt-4">コラム 指導システムと経験者</h3>
        <p style="line-height: 13pt">
          OB で話していると、 90
          年代にしっかりできていた指導体制が崩れていった要因はなんだろう、と話されることがしばしばある。この理由として、経験者は指導が出来ない、だから経験者が増えた最近は指導が廃れたという説を耳にすることがある。確かに東大でかるたを始めた人は自分が習ったように教えればいいが経験者がそうしにくいのは事実だろう。しかし、本当にそれが根本的な原因だろうか？ぼくは全くそうは思わない。経験者が入ったのは最近になって初めてのことではない。例えば
          10
          年ほど前だって柳井氏のように全く攻めかるたとは程遠い高校生Ｂ級選手だったのに、それゆえに東大ネイティブよりもはるかに東大かるたの良い点を知ってよき指導者になった例もある。巳波氏、俊平氏ら暁星勢も非常によく東大かるたを指導していた。最近では稲葉女史は栃木で東大の指導法を実践しているそうだ。他、とくに高校時指導者に恵まれぬ環境で過ごしていた経験者ほど、東大の恵まれた練習環境や指導の良さを認識しているものだ。
        </p>
        <p>
          したがって、指導ノウハウの伝達不足を経験者の存在に帰する風潮は断固否定したい。指導の仕方がはっきりわからない新
          2
          年生がいれば、それが経験者であっても東大ネイティブであっても、上級生がしっかり「指導の指導」でフォローをすべきなのだ。それを怠っておきながら、「経験者が入ってかるたの考え方が変わった。東大の指導は今の時代に合わない」と『経験者が東大の指導体制を壊した』説を述べるのは、責任転嫁といわざるを得ない。言葉が過ぎるかもしれないが、敢えて強く訴えたい。同様に、一年生のかるたがめちゃくちゃなときにそれを全て執行学年のせいにする風潮も反対である。たしかに指導における執行学年の役割は最重要ではあるが、指導は練習に参加する全ての先輩の責任であると考える。もちろん、実際に強くなるかどうかは本人次第であり、後輩が強くならなかったからといって先輩が責任を感じる必要は無いが。
        </p>
        <p>
          結局、指導ノウハウが伝わらなくなったのは、経験者の存在のせいではなく、執行学年だけのせいでもなく、経験者、執行学年、世代環境変化、などを言い訳にして指導法ノウハウを伝えないことを正当化した人の無責任さが原因、といえる。その背景には、「後輩を育てたい」「会を盛り立てたい」という熱意の欠如があるだろう。そして、執行学年や経験者は「おまえ達がよくない」と非難（＝責任転嫁）されたらますます熱意が欠如する、という悪循環になってしまう。非難するのではなく、ノウハウをじっくり伝えよう、つまり、指導の指導をしよう。そして何よりも、かれらが熱意をもつよう、自分の熱意を伝えようではないか。この著作はそう思って作った。
        </p>
        <p>
          経験者もそうでない人も、東大かるた会を盛り上げていこう、後輩をどんどん強くしよう、という熱意と愛情をもつことについては同じはずだ。それがある限り、あとはノウハウだけがあれば大丈夫だと信じている。執行学年や経験者を不必要に悪者にするのはやめにしようよ。また、経験者自身が「自分は東大でかるたを始めてないから指導できない」と思ったのだとしたらそれも勘違いだし、それを言い訳に指導を放棄するのは単なる逃げである。
        </p>
        <h3 id="h3-id-7" class="pt-4">コラム コーチ理論・・・ティーチングとコーチング</h3>
        <p style="line-height: 13pt"></p>
        <p>
          「手取り足取り教えると、本人が自分の頭で考えなくなる。ほったらかしておいて自分で考えさせるほうが、後々強くなるんだ」という言葉を聞くことがたまにある。果たして、しっかり技術を教えるのと、自分で考えさせるのと、どちらが正しいのだろうか？
        </p>
        <p>
          結論から言うと、どちらも正しく、必要なことである。重要なのは選手のレベルによって使い分けることなのだ。このへんの感覚を最初はぼくもうまく説明できずにいたのだが、コーチ理論の本を読むようになってよい概念を見つけた。
        </p>
        <p>それは、ティーチングとコーチングという２つの用語である。</p>
        <p>
          コーチ理論でいうティーチングとは文字通り、教えること、それだけである。一方コーチングとはあくまで選手の側が主体であり、選手本人が自分で動機付けを行い自分で考えスキルアップするよう、手助けすることがコーチングというものだ。あらゆるスポーツの指導に役立つことをコンセプトにしたウェブサイト「スポーツリーダーズネット」（
          http://athlete-web.com/leaders/index.htm ）によいことが書かれているので以下に引用しよう。
        </p>
        <p>
          従来からのコーチングの考え方は，「選手個人やチームに対してスポーツの技術などを実地指導していくこと」というものであったといえます．つまり，指導者が選手に対し一方的にトレーニング（練習）の指示や技術指導を行なうものであり，選手は与えられたことを忠実に実行し学ぶ（身に付ける）というものでした．
        </p>
        <p>
          それにとって代わり，近年ではコーチという言葉の語源からも分かるように，コーチングとは「選手のモチベーションを高め，選手本人の主体的な取り組みを促すことによって，選手のパフォーマンスを向上させていくこと」という考え方が一般的になりつつあります．それに伴い，従来からのコーチングという考え方は，現在では「ティーチング」という考え方に変わりつつあるといえます．
        </p>
        <p>
          しかしながら，全ての選手に対してコーチング，すなわち「選手のモチベーションを高め，選手本人の主体的な取り組みを促すことによって，選手のパフォーマンスを向上させていくこと」が有効であるとは限りません．
        </p>
        <p>特に，初心者やジュニア選手にとっては，ティーチングが重要になります．</p>
        <p>
          初心者やジュニア選手にとってやみくもにコーチングを行なうことは，かえって選手を混乱させることにもなるのです．
        </p>
        <p>
          また，選手がオーバートレーニングに陥ってしまった場合など，トレーニング（練習）のコントロールという意味を含めたティーチングが必要な場合もあります．
        </p>
        <p>
          このようにスポーツ指導者は，選手のレベルや状況に応じてコーチングとティーチングを使い分け，選手をサポートしていかなければならないのです．
        </p>
        <p>
          考えてみれば至極あたりまえのことだ。かるたでも、初心者のうちはとにかくきめ細かい技術指導（＝ティーチング）を行うべきで、やみくもに主体的取り組み（＝コーチング）をさせるのは却って初心者を混乱させることになる。それが
          1995-6 年の早大に起きていて、最近の東大かるた会でも一部で見られている、と言えよう。
        </p>
        <p>
          裏を返せばいつまでもティーチングだけに終わっては不足である。初心者のうちはティーチングに徹するべきとはいえ、いつかは選手に自立してもらいたいものだ。これについては、東大かるた会に限らず、色々なかるた会では古くから「
          A 級になったら好きにしていいから A 級になるまでは指導を聞くように」という言葉が言われてきた。「 A
          級になったら」の是非はともかくとして、ティーチングとコーチングの使い分けは伝統的にきちんとなされてきたと言える。
        </p>
        <p>
          では、 A 級になるまではただ指導を聞いてそのとおりやるだけなのか？もちろんそれだけでは不十分だ。重要なことが 2
          つあると思う。１つ目は、１つ１つの技術指導の目的と意味を良く理解すること。たとえばなぜ共札を分けるのか、なぜ攻めるのか、といったことを理解することだ。
          2
          つ目は、上の「マニュアル」「スタイル」のところに書いたように、東大の技術指導マニュアルを超えるところについては、じっくり本人が考え、先輩もサポートをし・・といった個人ごとのきめ細かいコーチングが必要ということだ。個々人へのコーチングこそ先輩の腕の見せ所であろう。特に、構え・払い・定位置・・といった、個々人の体格や音の感じ方の違い要素が大きく影響する分野ではコーチングの重要性は高い。
        </p>
        <p>
          コーチ書では、ティーチングからコーチングへの推移過程を、指示型→対話型→委任型のプロセスと名づけている。つまり、最初に基礎技術指導すなわちティーチングを受けるだけの指示型から、それぞれの意味を対話によって理解し、個々人のスタイルを作り上げていく対話型を経て、最終的にＡになったら自立して、欲しい時だけにアドバイスを求める委任型に移行していく。このように、選手の成長度と指導項目に応じて、考えさせるか手取り足取り教えるかを使い分けることが重要なのだ。
        </p>
        <p>
          なお本著作は、あくまで万人共通のティーチングだけに的を絞ったものである。初心者（Ｅ～Ｃ級）にはしっかりティーチングをしてほしい。本書にも、所々にコーチングのヒントもちりばめてみたつもりではあるが、繰り返すように本著作は必要最低限なものでしかない。
        </p>
        <p>
          ちなみに、カウンセリングというのもある。一般に使われる言葉そのままの意味。精神的なケアである。かるたマンはプロスポーツ選手でなくぼくらのいる会がサークルである以上、かるたに関してもそれ以外についても様々な精神的負担が加わる。かるたそのものについても悩んではまるひとは多い。ティーチングやコーチングのみならず、カウンセリングも先輩・同輩（・時には後輩）の大事な役割である。この著作ではカウンセリングについては割愛する。
        </p>
        <p>
          蛇足だが、上記の「スポーツリーダーズネット」の Web
          サイトには、その他プラニング、モニタリング、チェッキングといった、スポーツ指導の手法が書かれている。あまりかるたの指導で考えられてこなかったことだが、ぜひ取り入れたいものだと感じている。
        </p>
        <h3 id="h3-id-8" class="pt-4">コラム>A級の位置付け？</h3>
        <p style="line-height: 13pt"></p>
        <p>
          さて、田口氏の指導書でも A 級になろうと説いているし、この著作でも A 級をマイルストーンとしておいている。では A
          級になるまでのかるたと A
          級で通用するためのかるたは全く違うのだろうか？今までとは全く別の指導が必要になるのだろうか？
        </p>
        <p>
          答えは、しっかりとした攻めがるたで A
          に上がれば、その延長にプラスアルファをすればさらに実力を向上できる、ということである。種村永世名人は「超 A
          級への道」でそのことを分かりやすく述べている。種村永世名人の指導書にあるように、定位置を固めて、攻め 100
          ％でも無意識に守れる札を増やすことにより、攻めも守りも出来る選手に脱皮が出来る。既に書いたように、東大の「激攻め指導の時代」に東大超
          A 級選手は集中して育てられている。逆に基礎的なスピードがなく中途半端に攻めたり守ったりで A
          級になれてしまうと、そのままのかるたではなかなか勝てず、別のかるたへのモデルチェンジが必要になる。
        </p>
        <p>
          したがって、 A
          級は一つの目標であるが、あくまでマイルストーンであり、最終目標ではない。むしろスタートラインという人もいる。世の中で
          A 級 A 級とみなが言うからといって A
          に上がることだけを目標にしすぎて、上がった後で気持ちが萎えてしまうことのないようにしたい。
        </p>
        <p>
          全く同じ理由で、 A 級になれなかったからといって選手としてダメだとかそういうことはない。一般会では「 A
          級選手でなければかるたマンに非ず」といった態度の会もあるようだが、そこは誤解の無いようにしたい。 A
          級は大きな到達目標であり同時にスタートラインであり、要するに通過点の一つなのだ。
        </p>
        <p></p>
        <h3 id="h3-id-9" class="pt-4">コラム 体育会的指導？技術指導？</h3>
        <p style="line-height: 13pt"></p>
        <p>
          田口氏・波多野氏・種村氏の指導書でも川村ホームページでも、かるたの技術やかるたの考え方が綺麗にまとめてある。しかし、細かく技術を教えるよりももっと成果を出す指導がある。いわゆる体育会的な指導、というものである。
        </p>
        <p>
          昔話で恐縮だがぼくが入学した時は、とにかく「つべこべ言ってるひまがあったらもっと攻めろ」といわれたものだ。とにかくバカになって攻めまくったものだ。毎日毎日。技術よりも精神論重視で、攻めこぼしなどとても許されないほどの雰囲気だった。初めて練習に来た初心者が自陣を守ったときに蹴り飛ばした先輩もいた！でも、みんなそうしてつよくなって、結局後から思えばその時代がもっともみなが強くなった時代である（蹴られたやつも後に立派な
          A
          級選手になった）。ぼくの同期は４年間でＡ級７人Ｂ級どまり０人、一つ下の代はＡ級９人Ｂ級どまり０人。全て大学でかるたを始めて、である。みな非常にしっかりした攻めのスピードを身に付けた。
        </p>
        <p>時代が下って、技術的な指導は何も無いのにとにかく厳しいという静岡雙葉の指導を聞いて、共通点を感じた。</p>
        <p>
          こまかく頭で理解して考えて教えるよりも、とにかく攻めなら攻めにこだわるだけこだわって厳しい指導をするのも、実は効果的のようだ。ぼく個人は技術面をしっかり説明する指導を好んではいるものの、やはりある程度の厳しさと緊張感と集中力をたもつことはは強くなるために必要だとも十分理解している。ぐちゃぐちゃ試合中に考えすぎると集中力の欠落につながることにも注意する必要があろう。なお、蹴りは、やってはなりませんよ。
        </p>
      </div>
      <div>
        <h2 class="pt-4"><b>第二部 具体的な指導法</b></h2>
        <p>具体的な指導法はすでに著作があることを述べました。全てよいことが書いてあります。ここでは</p>
        <p>田口・波多野・種村著作・川村のページの４つにないことを補足しましょう。</p>
        <p>
          まずは、
          <b>
            川村のページの「東大がるたの成長過程」と田口氏波多野氏種村氏の著作を十分読んで理解してから以下を読んで欲しいです。
          </b>
          <b></b>
        </p>
        <p>
          以下は、本当にごく初期の初期の初心者に対して教える重要なノウハウです。こうしたノウハウに抜けがあって「本人は攻めているつもりなのだが遅くて攻めたことになっていない。」というのが一番まずいパターンです。こうした選手は、東大の人を相手にした練習では敵陣を抜けるのですが、いざ試合では敵陣を守られるため、
          B 級くらいまでは上がれるのですがそこからなかなか上がれない・・ということになってしまいます。
        </p>
        <p>
          <b>
            「この相手からは守れないや」と相手が自陣をあきらめるところまで行かないと、攻めがるたは意味をなしません。
          </b>
        </p>
        <p>なお、以下は右利きの人を基準にしている。左利きへの指導はぼくも詳しくないので該当者に聞いて欲しい。</p>
        <h3 id="h3-id-10" class="pt-4">＜どの程度の時間の指導が必要か＞</h3>
        <p>
          一試合あたり、どの程度の指導が必要でしょうか。もちろんその選手のレベルによって変わってきます。まずはじめに、将来みなが
          A 級になるためには、どのようなレベルの選手にどの程度の指導が必要となるか考えてみましょう。
        </p>
        <p>
          <u>第一に、試合中の指導について。</u>
          試合中の指導を特に入念に行わなければならないのが、かるたを始めて 2 ， 3
          カ月以内のごく初期の選手に対してです。（ひとつの目安としては、お手付きなしルールでもまだ先輩に勝てない程度の選手、だろうか）。
          <b>
            この時期の「良くない」指導の代表例第一は、指導する先輩が試合中一番しゃべっているのが送り札の指導、というケース
          </b>
          です。まだ自陣の並べ替えをする前くらいのレベルの選手に対してしなくてはならないのは、どの程度激攻めするか？一字の感じはどうやって育てるか？どう狙うか？素振りはどう使うか？どう暗記するか？払えなかった時どうするか？あるいは今の取りが良かったのか悪かったのか？悪かったら次からどうすればよいのか？今の手の出し方が十分なのか不十分なのか？・・と言ったことです。一つ一つ試合中に丹念に教えていくべき段階のはずです。少なくとも
          <b>
            この指導書に書いてある内容を身につけてもらおうとすれば、多くの場合はそういった指導にかなりの時間を費やさなくてはいけなくなると思います。送り札は、もちろん余裕があれば教えるべきだろうが、極端な話練習中でなくても教えられるので試合中は優先度が低いのです。もちろん、送りのやりかたも教えなくてはいけない重要事項ですので、試合後の指導やアフターで送りの指導をきちんとやって十分補ってほしいです。
          </b>
          繰り返しますが、試合中の指導内容の大半が送りの方法だけ、というのは避けてください。
        </p>
        <p>
          なお、かるたを始めてまだ決まり字もわからない選手に対しては次の札が読まれるまでの時間にひたすら先輩がしゃべるばかりでも良いかもしれません。が、初心者が上達するにつれ、次第に彼らが「暗記を回す」時間も確保する必要がでてきます。したがって、必要事項を端的につたえ、かつ一試合を通じて適切な内容を伝えきる指導の工夫が重要となります。といってもこれはまだ執行学年になりたての
          2
          年生にはちょっとなれないと難しいというのも確かです。なかなか伝えるべきことを試合中に伝えきれないということも出てきてしまうと思いますが、そんなときは試合後やアフターでフォローしてください。
        </p>
        <p>
          <u>第二に、試合の後の指導について。</u>
          全国どこの会でも、試合後の指導は非常に重要な要素です。したがって、他会よりも強い選手を育てるためには、この「試合後の指導」の充実が絶対不可欠となるのです。
        </p>
        <p>
          一試合が終わってからきちんと指導すると、どうしても 10
          分ほどはかかってしまうものです。さらに、後輩からの質問を受けたり、後輩が理解したかどうかを確認するための例題をだしたり、と言ったことをすれば、すぐに
          15 分 20 分もの時間がたってしまいます。
          <b>
            <u>
              したがって、一試合が終わった後には、会場外のスペースを活用してじっくり時間をかけて指導をするのが重要です。
            </u>
          </b>
          オプションとしては、会場外スペースで指導をした後会場に戻って・・、などももちろんありえます。（例えば後輩の構えの問題点を指摘し、その改善のための良い見本の選手が試合中であれば、会場外で具体的に誰のどのような点を自分と比較して見習うかを指摘してから会場に戻る）
        </p>
        <p>
          <b>
            「良くない」指導の代表例第二は、試合が終わってからその場で漫然と他の試合を眺めるだけで、読み手が百枚詠み終わってから次の試合が始まるまでの時間に指導を与えただけで終わってしまう
          </b>
          ケースです。これでは、他会他大学と同レベルの指導になってしまいます。これだけの指導量で他大学に育成で上回るのは、不可能といえましょう。百枚詠み終わってから次の組み合わせまでのわずかな時間は、会場外から戻ってきて払いや構えの指導を実演しながら行うための数少ないチャンスですから、その目的で有効活用してください。
        </p>
        <p>
          もちろん、試合展開によっては１－１近辺まで行くこともあります（後述するように本当に初心者に対しては送りを止めたりして１－１まで意図的に持っていくことがむしろ重要な場合も多い）。その場合は会場外スペースでゆっくり話をすることはできないので、必ず駅までの帰り道やアフターで補ってください。
        </p>
        <p>
          かなり間違った指導が伝わってしまっているなこの初心者は、と思ったら、試合を途中でやめてじっくり会場外スペースでいろいろ説明するのも良い方法です。僕自身も数回やったことがありますし、かつては他の人もそうして指導していました。
        </p>
        <p>
          <u>第三は、アフター等々での指導。</u>
          結局のところ、試合中や試合後の指導だけでは、技術面などについては十分教えられるものの、その背後にあるものの考え方などは伝えきれません。アフターや遠征、合宿の機会を活用することが重要です。とくに、攻めがるたとはなにか、といったことを本当に理解させずにただ技術だけ習わせても、ぬるい攻めしか見につけられずに終わってしまいます。
          <b>
            <u>
              東大かるたのいわば「心」を伝え理解させるには、アフターや遠征や合宿での指導が絶対に必要不可欠なのです。
            </u>
          </b>
          これまでも、 A
          級選手がまとまって出た代はその選手たちが若いころからアフターでのかるた談義が盛り上がっていました。一年生と接することの多い
          2
          年生が、アフター等で、如何にこうした心を伝えるか、というのが重要で、人によっては執行学年による指導は技術面よりもむしろ、こうした手段で東大かるたの心を伝えるのが大事なのだよ、と説く人も大勢います。
        </p>
        <p>
          <b><u>大会で負けた後も、指導の機会に十分に活用してください。</u></b>
          第一部にも書きましたが、大会は他の選手や自分の会の選手の試合をじっくり見る良い機会です。本当にためになる試合を
          1
          試合見ることは、自らが何十試合何百試合の練習をこなすよりも有益な場合も少なくないのです。誰のどの試合を見るとよいか、そのポイントをぜひ後輩に教えて、必ず後輩にかるたを見させてください。大会で負けたら控室でだらだらとか、試合を見たとしてもただ同会の人の試合を漫然と眺めて応援しているだけ、という人は、ほぼ間違いなく強くはなれません。なお、この「大会を見させて勉強させる」、というのは東大に限らず全国共通の教えで、強豪を排出している一般会では必ず実践されているものであることを付け加えておきましょう。
        </p>
        <h3 id="h3-id-11" class="pt-4">＜良い練習とは、良い『癖』を身体につけること。＞</h3>
        <p class="MsoNormal"></p>
        <p>
          初心者が遅く取るくせをつけてしまうと、あとで直すのが非常に大変です。早い選手、遅い選手、の差はこういったことが徐々に蓄積されて生まれていくのです
          <b>
            。
            <u>敵陣最速の抜きを鍛えるためにはかるたを始めて最初の数ヶ月がとても重要です</u>
            。
          </b>
        </p>
        <p class="MsoBodyText">
          かるたも、スポーツも、音楽も、「練習とは、よい癖をつけること」です。逆に
          <b><u>悪い癖をつける練習は、やればやるほど、むしろ逆効果</u></b>
          です。特に、音に遅く反応する癖がつくと、遅くしか反応できないままになります。一度ついた悪いくせがすぐ直る人もいますが、なかなかそれができない人も少なくありません。高校以前の経験者で、結局
          B 級や A 級下位で伸び悩むものが多いのは、これが理由である場合も多いと思います。
        </p>
        <p>
          <b>
            さらに、どこもそれなりに速いが、どこもそれほど速くない選手は、伸び悩みます。攻めがるたなら、とにかく敵陣だけでも全部抜く速さがあれば、自陣は相手の攻めこぼしの拾いに徹するだけでも
          </b>
          <b>A</b>
          <b>級優勝レベルにまでなれます</b>
          。（余談だが、守りがるたであれば逆に守りきるだけの守りのスピードを身に付けるべきです。静岡雙葉のように）
        </p>
        <p>
          ということで、
          <b>まず最初のうちは、とにかく敵陣（特に右下段）を全部はやく払って抜くことを目指させること。</b>
          <b>0.5</b>
          <b>字で払おう。お手つきなしルールをしっかり生かして、どんどんお手つきしてでも早く取らせることが重要です。</b>
          <b></b>
        </p>
        <p>
          何回か書いたように、
          <b>
            送り札と決まり字だけ教えてそれ以外なにも試合中に言わない、という人を最近見ますが非常によくないです。ゆっくり初心者にこちらの陣を抜かれて何も言わず、というのでは、初心者はそれくらいのしょぼい抜きでも良いと思い込まれしまいます。
          </b>
          初心者が必死に集中して敵陣に素振りをして頭と身体に暗記を入れ、音に反応して敵陣を払うという、絶対不可欠なスポーツトレーニングをしなくなってしまいます。初心者がぽけっとしていて挙句の果てに自陣を見て暗記が入ってしまうのでは、最悪としか言いようがないのです。
        </p>
        <p>
          当然の重要なこととして、どの札も早く取るためにはまず、自分から一番遠いところの札（敵右下段）に対して最も早く反応したり早く払ったりできる必要があります。こうして遠い右下段を確実に早く取る良い癖をつけさせれば、最初の一歩は成功です。その後、
          <b>
            たとえ名人が相手でも敵陣右下段を軸にかるたを組み立てることが出来ます。攻めかるたをする全てのどんなスタイルの選手にも、これだけは必須なのです。
          </b>
          自陣は、 A
          級になるまでは取れればラッキーだし、敵がきちんとこっちを抜いたらそれは自分は取れなくても構わないのですから。逆に、
          <b>
            しっかり敵陣にプレッシャーをかければ自陣は意識せずともゆっくり戻れます。自陣を意識すると当然無意識にも敵陣の攻めが甘くなるし、攻めて戻るほうがその逆よりもはやいです
          </b>
          。
        </p>
        <p class="MsoBodyText">
          言うまでもありませんが自陣を意識して中途半端な攻めかるたになってしまってはいけないのです。練習で、攻めている人から守れることを快感におもっては、弱くなるばかりです。
        </p>
        <p>
          では、以下に具体的な方法論に入っていきましょう。かるたに強くなるには、感じ、構え・払い、狙い・暗記、聞き分け、戦略やメンタルコントロール、スタミナなども必要になります。
          <b>
            その中で初心者のうちに見につけなくてはいけないのは、基本的な取りの力である「狙った敵陣右下段一字札の抜き」です。
          </b>
          これには、狙い・感じ・払いの三要素が重要になります。だからまずこの３つ鍛えるための練習をしましょう。次に狙っていない札を含めた敵陣の確実な抜きが必要です。これには上の
          3 つに加えて、暗記・聞きわけの練習が必要です。これら 5
          つの要素についてそれぞれ細かく習得法を書いてみましょう。
        </p>
        <h3 id="h3-id-12" class="pt-4">＜基本的心構え＞</h3>
        <p>
          言うまでもなく、ごくごく最初の初心者に指導する時の基本的心構えは、「敵陣を全部最速で取ることを目指そう！」というものです。最初の指導の時、
          <b>次の２点は必ず試合前に初心者に強調して教える必要があります</b>
          。
        </p>
        <p>
          ①一つ目は、全部の札を最も効率よく取るには、毎回配置が異なる敵陣を狙うのが最も有効であること。そして自分から遠い位置にある敵陣（特に右下段）をしっかりとるようにすること。自陣はいつも同じ自分の定位置であるから、敵陣だけ意識していても将来無意識に取れるようになるのだ、ということ。また、こっちがしっかり攻めて相手の手を止めれば、こちらは敵陣から戻って自陣を拾えるのだということ。したがってまず自陣は全く意識せず敵陣のみを意識してしっかり全部抜けるように練習するのだということ。
          <b><u>たとえ２５－１で負けていても狙いは敵陣のラスト１枚！ということ。</u></b>
          こうしたことを、まずとくにしっかり初心者に試合前に伝えましょう。試合中も何度も繰り返しましょう。
        </p>
        <p>
          ②二つ目は、敵陣の札を早く取るには、覚えた札を探して取るお座敷カルタからの脱却を目指すこと。試合前に教えましょう。狙って暗記をいれて１字目で（少なくとも決まり字が読まれる前には）出札の上空に手をしっかりもっていき、
          <b>
            あったと思ったら
            <u>探さずに</u>
            あったと思ったところをばしっと思いっきり払う
          </b>
          ことにトライさせましょう。
          <b>
            スピードを上げる練習ですから、早いお手つきは大歓迎であることを試合前に言おう。一枚でもよいから、まずは完璧なタイミングで敵陣を取ることを目標に取らせましょう。あったと思ったら探さずに払うのですから、むしろ自陣の札を記憶してしまうことは無駄なお手つき（＝自陣にある札なのに敵陣を払ってしまう）や記憶の混乱に繋がることをしっかり強調しましょう。
          </b>
          <b></b>
        </p>
        <p>
          この練習の過程で、「相手に送られた札なのに元あった場所を払う」お手付きが生じる場合がありますが、それがきっちりのタイミングでのお手付きならば、最初はまったく気にしなくてよいことを伝えましょう。後述する「暗記まわし」の方法をきっちり実践すればこれを回避できることを伝えましょう。
        </p>
        <h3 id="h3-id-13" class="pt-4">＜かるたは速度ではなく加速度。フォロースルーが重要。＞</h3>
        <p>
          「スピードを育てるには？」
          <b>まず、飛び出すこと。</b>
          そして一字目の瞬間に
          <b>出ながら</b>
          聞くこと。そして決まり字前に出札上空まで手をもっていき、手を止めずに加速して払うこと。言葉で書くと簡単です。重要なのは
          <b><u>加速</u></b>
          することです。
          <b>
            飛び出した手は、空札で加速してしっかりよけるか、出札で加速してはらうかのどちらかである。最初のうちは空札でそのままお手つきでもいい。むしろ、決まり字前の早いお手つきは、習い始めた初期は歓迎される。どんどんやって欲しいです。ごく初期の段階で早くてお手つきの多い練習をすると後で伸びるが、遅くてお手つきをしない練習をしていると遅く取る癖がついてしまいます。
          </b>
          <b></b>
        </p>
        <p>
          <b>加速するための練習法</b>
          として、必ず払ったら、払った
          <b>後も</b>
          手のスピードを緩めずむしろ
          <b>加速して、必ず自分の後方に</b>
          しっかり着地させましょう。つまり畳を勢いよくたたくことになります。
          <b>空中でとまったり失速してそっと畳に手がつくというのは最悪です。</b>
          札を後ろにひっぱる感覚です。初心者だと
          <b><u>前方に札を突いてしまいがちですが、必ずこれは直させましょう。</u></b>
          前につく癖がついてしまうと、将来的に敵陣飛び出すだけで自陣に戻れない（戻ろうとすると敵陣の取りが遅くなる）かるたになって、非常に苦しむ羽目になります。
        </p>
        <p>
          とにかく、
          <b>飛び出すこと、引っ張って加速すること、この</b>
          <b>2</b>
          <b>点を後輩に教えよう</b>
          。必ず
          <b>素振りのときでも空札のときも出札のときも</b>
          、敵陣の方向まで出した手は必ず失速せずに加速して自分の右または左
          <b>後方の</b>
          畳までもってくることを、最初のうちから口をすっぱくして教えて欲しいものです。（経験者にもこの加速・引っ張りを教えると伸びる場合が多いです）。とくに女性には時たま、思いっきり払って畳を叩くのを恥ずかしがる人がいます。悪い癖が固まる前に、払い連などを通して必ず改善させましょう。
        </p>
        <p>
          さて以下には、身体の動きをしっかりさせるための練習法と、暗記したとおりに飛び出すための練習法２つを書きます。
        </p>
        <h3 id="h3-id-14" class="pt-4">＜身体に覚えさせる。その１イメージのレール。素振りはリハーサル。＞</h3>
        <p>
          上の動作を頭で覚えるのではなく、
          <b>身体に覚えさせましょう。素振りはリハーサルです。</b>
          実際に音をどう聞いて、どう反応して、どう手を伸ばして、どう払って、どう着地するかまでをしっかり身体に覚えさせるのです。加速して着地するまでをしっかりイメージするところがポイントです。
        </p>
        <p>
          たとえば、楽譜を読めるようになってもいきなり初めての曲を弾くのは初心者には困難です。楽譜を見てそのとおりに弾けるようになるまで、なんどもなんどもその楽譜の音を弾き、頭よりむしろ身体に動作を染み込ませる。それと同じことです。つまり、個々の札を理想的なタイミングで取る体験をなんどもしつづけるのが重要なのです。狙った札について音を聞いて勝手に体が動くまでなんども頭の中で音を唱え、素振りをして身体に覚えさせましょう。リハーサルをなしにいきなり本番を迎えても（つまり素振り全く無しでいきなり出札の音が聞こえても）、まともに取れるものではないのです。
        </p>
        <p>
          最近では新入生の素振りが減っているようなのが気がかりです。ただ読まれた札を聞いてからゆっくり取りに行くだけの練習をしてはなかなか早く取れるようにはなりません。
        </p>
        <p>
          なお、素振りのときは、下段の札に対しては
          <b><u>札のさらに一枚外側に札を意識</u></b>
          し、そこを払う動作を身体に覚えさせましょう。なおこの、「一枚外側を払う」払いは、得てして手が失速気味の経験者にも有効な場合が多いです。
        </p>
        <p>
          初心者のうちは素振りはいっぱいして良いと思います。ただし、無駄に振り回しているだけではスタミナの消耗です。上に書いたように、頭の中で余韻を意識し、一音目、決まり字までの音、決まり字、をリハーサルし、身体に染み込ませることです。
          <b>波多野さんは「理想の取りのイメージのレールをたくさん引く」と言う表現をしています。</b>
          良い表現ですね。初心者のうちはどんなに集中してレールを引いても引きすぎることはありません。暗記時間中、そして試合中も、いかにこのイメージのレールを身体に覚えこませるかが、札をしっかり取るための秘訣です。波多野さんは
          <b>素振りもそのための一手段と書いています。</b>
          なお、そうしてレールを引き続ける以上、自陣に注意を払う余裕はもちろんありえないです。
        </p>
        <p>
          慣れてくれば、実際に素振りをしなくても身体に覚えこませることが出来るようになります。素振りを節約して体力を温存することも出来るようになります。無駄な素振りよりも暗記のほうに集中できるようになります。これは楽器の練習を積めば、初めての曲でもいきなり楽譜を見ながら（難曲でなければ）弾けるようになるのと似ているように思います。しっかりした練習をすれば、自然とこの段階まで行きます。それまでは、イメージのレールを固める、すなわち身体に暗記を固めるため、素振りを効果的に使いましょう。
        </p>
        <p class="MsoBodyTextIndent2">
          渡り手や囲い手も突然はなかなか出来ません。しっかりイメージのレールを固め、リハーサルを何度もしましょう。
        </p>
        <p>
          また、これらの操作を効果的に行うには、構えや払いをしっかり固めておくほうが良いです。それについては後述します。
        </p>
        <h3 id="h3-id-15" class="pt-4">＜身体に覚えさせる。その２札を狙って早く取らせる＞</h3>
        <p>
          初心者にはとくに強調して、敵陣の数枚を狙わせる習慣をつけさせましょう。最初の目的は右下段狙った１字の札。
          <b>読み手が読む全ての札で、狙った札が読まれるつもりでいましょう。</b>
          それが「第一優先」という意味です。技術が向上すると、「第二優先」「第三優先」ができます。それを
          <b>初心者に体得させるためには、こちら（先輩側）の右下段になるべく一字を残すように配置する</b>
          のがより良いです。
        </p>
        <p>
          初心者が最初からびしっと早く取れることはなかなかないものです。初心者は最初つい札を探してしまいます。つい自分の暗記が不安なので、読まれたと思っても払いに繋がらないのです。
          <b>
            初心者は、まずこの「札を探してしまう」という壁を取り除き、音に対して反射したら札を探すことなくとにかく思いっきりはらうというコツを体得することが、かるたの腕を磨く第一歩
          </b>
          です。
        </p>
        <p>
          そこで重要なのは、初心者が
          <b>たまに早く（特に右下段）取ったとき、そのイメージを忘れさせないようにすることです。</b>
          つまり札を早くとること（特に右下段）を体験したら、そのタイミングを身体に経験値として覚えこませることが一番効果的です。だから、たった一枚でもいい。右下段の一字札を１字で完璧で払えるように目指させましょう。
          <b>もしもきちんと取れたら、先輩は「今のよかったよ。その感覚を忘れないように」と声をかけてあげること</b>
          。こうして、新入生はどれだけ札を早く取ればいいかが分かってくるのです。
        </p>
        <p>
          だが、このベストな一枚は、普通初心者はすぐには出てこない。（すぐ出てくる初心者はそのままで良い。）出ない場合どうするか？
          <b>
            このたった一枚を早い時期に出させるために、ぼくはとにかく最初の最初は狙わせた右下段の１字めがけて聞かずに飛び出し、一字のタイミングでその札を思いっきり払う練習をすすめます。
          </b>
          まずどれだけ早く取るべきかを認識させるために、最初は聞かずに飛び出すのが有効です。札直でなくたって全く構わない。初心者はつい札を探してしまう悪い癖があるから、その呪縛を断ち切るために、まずは飛び出してとりあえずあったと思えばとにかくあったと感じたところを思い切って払うのが、重要なのです。聞かずに飛び出して払っても構いません。払い残しでも最悪構いません。払った瞬間を見てなくても最初だけ
          h 構いません。お手つきでも構いません
          <b>
            。とにかく、どんなやり方でもいいから、ベストなタイミングで札を払う習慣をつけさせ、そのイメージを体得させることです。
          </b>
        </p>
        <p class="MsoBodyTextIndent2">
          加えて、こちら（先輩）がそちらの陣に手を出す前にこちらに手を出すように、といって練習させよう。実際に出せたときには誉めましょう。
        </p>
        <p>
          そうして、新入生が聞かずに狙った札に飛び出してすごく早く払えたとき、「今のぐらいに早く取れるようになるといいね」と言おう。お手つきでもいいのです。むしろどんどんお手つきしてくれ、と新入生に伝えましょう。
          <b>
            お手つきでも思いっきり早いタイミングで払えたら誉めよう。先輩にそう誉められたら、それが彼らがこれから自分で目標とする早さ、であります。
          </b>
        </p>
        <p>
          繰り返すが、一見乱暴に見えるこの練習の目的は、狙った一字（特に右下段）をとにかくベストのタイミングで思いっきり払う感触を一日も早く身体に染み込ませることが目的なのですから、お手つきだろうが札直じゃなかろうがまったく構わないのです。
        </p>
        <p>
          この「読みと共に一字のタイミングでバッチリ払う」練習を怠ると、とくに女性で多いが、ふにゃっと手を出してふにゃっと押えてとることしかできないままで、初心者としての時間を過ごし、最終的に怪しげな押え手かるたになったり、申しわけ程度に払うセミ押え手かるたになったりするのです。
        </p>
        <p>
          上にも書いたように初心者のうちに一番いけないのは、聞いて認識してから手を出すことで、遅くしか出られない癖がついたり、反応してもそこから払うまでのタイムラグが大きくなる癖がついてしまうことです。一度これらの癖がついたら直すためには本人の大変な努力が要る。田口氏の著作にあるが、聞かずに手を出しているつもりでも実はビデオで見ると実は聞いてから出てしまっているものなのだ。
        </p>
        <p class="MsoBodyTextIndent2">
          東大かるた会で初心者がかるたを始めるにあたり格言として伝えられるのは、「自陣は一枚も取らなくてもいいからまず敵陣を全部取れ」「右下段と１字決まりは絶対に一枚も守られないように最速で抜け」ということです。お手つきしてもいいし聞かずに飛び出してもいいので、まずはこれらの格言の実現、つまり一字決まりと右下段を全部抜かせる習慣をつけさせましょう。
        </p>
        <p>
          ただ、注意しておくが、もちろん、これらの動作が出来るようになったら、
          <b>次のステップはただ飛び出すだけでなく、聞きながら出る</b>
          <b>or</b>
          <b>
            出ながら聞くことです。出ながら聞いて、なおかつそれまでに体得した（聞かずに払う）タイミングで札を払えるようになることを目標に練習させましょう。
          </b>
          <u>聞かずにただ手を出すことが誉められるのはごくごく最初だけ、おそらくかるたを始めて最初の数試合だけ</u>
          です。狙った札への最速の速さに加え確実性を身に付けたり、狙ってない札を手の動きを修正して早くとったり、札際や手元でしっかり聞き分けたり、といった「次の段階の」指導は次項以降に述べていきます。
        </p>
        <h3 id="h3-id-16" class="pt-4">＜狙った一字以外の札、空札。・・・無反応には終わらない。＞</h3>
        <p class="MsoNormal"></p>
        <p>
          さて、初心者が、お座敷カルタから脱却して敵陣に飛び出してお手つきでもなんでも一字のタイミングでバシバシ払うことができたら、次は正確性を増す練習をさせましょう。まずは、反応の正確性。以下のように
          <b>正しい方向にちゃんと</b>
          一字で反応する練習をさせましょう。
        </p>
        <p>
          狙った一字をまず初心者にとらせようとすると、狙った札以外はなかなかちゃんとは取れない。そういう札はどう練習すれば取れるようになるでしょうか？無論、全部を満遍なく取らせようとすると、狙った札がおろそかになってしまい、意味がないです。
        </p>
        <p>
          練習法として重要なことは、一つ目には、しっかり暗記をすることです。それについては、このあとの「暗記について」で述べなす。
        </p>
        <p>
          二つ目は、
          <b>感じ遅れても、そこで無反応に終わらないことだ。</b>
          考えてみてください、
          <b>大半の場合、読まれた札が敵陣にあるか、あるいは出札と１字目がと同じ札のどちらかが敵陣にある</b>
          ものです。初心者は音に反応できなくてもそれはそれでしかたないです。逆の方向に飛び出してしまっていてもしかたないです。ただし大事なのはその後なのです。手が正しく出なくても、
          <b>
            正しい方向を認識するや否や、しっかりその札に向かって手を伸ばし、しっかり加速して払って（よけて）畳に着地するまでの一連の動作を、自分の意思でやることです。初心者にぜひこれをかならずやらせるようにしましょう。出遅れたときも逆に出た時も、敵陣のあそこにあったと思ったらとにかく自分が暗記した場所を払う！そして、出札のときだけでなく、空札のときも手を出すべき札に対して同様の動作をさせてみましょう。
          </b>
        </p>
        <p>
          この動作も札直でなくても構わないです。札を探させてはいけません。お手つきもまったく構いません。手を動かさないで終わらせるよりずっといいです。この練習を繰り返せば次第に狙い札と狙ってない札の取りの時間差は縮まっていきます。逆に、読まれて空札と分かって手が動かなかったとき
          <b>そのまま何もしないような練習方法では、いつまでたっても決まり字前に正しく手が出るようにならないです。</b>
          最悪なのは、（でもありがちなのだが）、出遅れた札に対して初心者が恥ずかしそうにゆっくりベトっと押え手で取ってしまうことです。一年生の子がそんなふうに取ってしまったらば、もう一度出札を場に並べ直して、びしっと払わせましょう。そして、空札で手をそのまま引っ込めてしまった場合も、その都度、加速してしっかり畳まで勢い良く手を引っ張ってくる動作を何度も繰り返させましょう。
        </p>
        <h3 id="h3-id-17" class="pt-4">＜手が上空で止まってしまう人・・・そのまま手を引かない。＞</h3>
        <p class="MsoNormal"></p>
        <p class="MsoBodyText">
          正確性を増す練習その２。反応と感じを合致させましょう。手が上空で止まらないようにする練習です。
        </p>
        <p>
          これも上と同じです。出札なのについ手が上空で止まってしまうことが初心者ではよくあるのです。
          <b>空札のときも、飛び出した手が失速してしまうのはよくないです。</b>
          なぜなら、空札でとまる癖がついてしまうと、いざ出札のときも止まってしまう悪い癖が出てしまいがちだからです。「出札で反応するんですけど上空で止まっているうちに相手に取られちゃうんですよ。」という人の大半は、空札のときも飛び出した手が失速してしまっています。
        </p>
        <p class="MsoBodyTextIndent3">
          もちろん最初のうちは仕方ないです。しかし、先輩がそこで「上空でとまらないように」というだけではなく、止まらないようにする練習法を教えましょう。
        </p>
        <p>
          つまり、止まったあとの動作が重要なのです。止まった手をそのまま戻すと、止まる癖がついてしまいます。
          <b>
            止まってしまった手を、そのままにしてはいけません。止まったら、止まった次の瞬間から動作を継続すること。止まったその位置からしっかり加速して後ろに引っ張って自分の後方の畳を叩くまで勢い良く手をもってくること。空札のときも出札のときも、常に
          </b>
          それをくりかえしやりましょう。
        </p>
        <p class="MsoBodyTextIndent3">
          実はこれ、ある程度の上級者でも出来ていないことがあるのです。こういう人は、調子がいい時はきちんと払えるけど、調子が悪い時は手が止まりまくってそのまま一試合が終わる、という風になりやすく、好不調の波が大きくなります。相手によっても左右され、きっちり加速する人にも相性が悪くなりがちです。しかも好調の自分が自分の真の実力と思いこんでしまいがちで、自分で気づきにくい欠点なのです。したがってこの加速の練習法は、初心者でなくても、高校からの経験者にも（とくに女性）有効です。経験者で、たまたまタイミングが合ったときにきれいに払えるのに、ちょっとタイミングが合わないと出札上空で手が止まったりする人が意外と多くいます。また、調子が悪くなるとまったく手が反応してくれなくなり、試合の終盤で急にとれなくなるという経験者も意外と多いのです。その人たちは、こうした「動けなかったときにフォローする練習」「手が止まってしまったときにフォローする練習」を怠っている場合が多いのです。
        </p>
        <p>
          ちなみに、ただ反応を遅くすればもちろん止まらずに払えるようになりますが、それでは結局遅く取っているだけになってしまい意味がありません。それを初心者にさせないように注意しましょう。（もちろん、音に反応していながら手元で手を敢えて止めるのであれば問題ない。これは強くなってからの高級テクニックである）
        </p>
        <p class="MsoBodyTextIndent2">
          東大かるた会に昔から伝わる重要な初心者に対する格言がある。手が止まるくらいならお手つきでもいいから払え！という格言だ。これをしっかり初心者に認識させて欲しい。
        </p>
        <h3 id="h3-id-18" class="pt-4">＜新人にやる気を出させる・・・送り止めなどの工夫。＞</h3>
        <p class="MsoNormal"></p>
        <p>
          <b>いたずらにタバで初心者を潰してもまったく新人の練習にならない場合が大半です。</b>
          無論新人によっては、なにくそ、と思ってそれをばねにする子もいるかもしれないし、守って喜んでいる新人がもしいたら
          25
          枚で潰すショック療法も有効なときもあるでしょうから、時と場合を選んで活用して欲しいです。が、基本的にはそれは本意ではありません。
        </p>
        <p class="MsoBodyTextIndent3">
          重要なのは、潰すことではないわけです。
          <b>新人にやる気を出させ、早く札を取りたいという目的意識をもって練習に臨んでもらうこと。</b>
          こちらの陣の右に一字札や短い札を残して、しっかり抜くように試合中言いつづけること。素振りをさせること。狙わせること。そして抜かせること。しっかり抜いたら褒める、など工夫が重要となります。
        </p>
        <p class="MsoBodyTextIndent3">
          だんだん早く抜けるようになったら殊更に誉めましょう。成長の速さは個人差があります。大事なのは、すこしでも以前より早く抜けるようになることなのだ。試合の序盤でなかなかしっかり抜けなかった一年生はふつう終盤になると早く抜けるようになるので、そんなときはしっかり声に出して評価しよう。
        </p>
        <p class="MsoBodyTextIndent3">
          繰り返しになりますが、まずは一試合あたり一枚でもいいから決まり字ぴったりで抜けることを目指させそう。それが出来たら１，２枚・・と早く取れる札を増やしていきましょう。もっと増えてきたら、右の１，２枚の速さを保ちつつ左の短い札も狙わせてみましょう。ただ早いだけでなく、しっかり加速して払うことも必ず教えましょう。試合中常に、どの札を狙うか、どのように身体に覚えさせるかをおしえて、とったあとには今の速さが目標値に対しどの程度のレベルにあるかを、しっかり認識させましょう。そのつど、今の取りが良かったのか惜しかったのかをしゃべって認識させると効果的です。
        </p>
        <p>
          だから最初から初心者相手に 20
          枚くらいタバで潰すことはもってのほか。お互いに練習にならないのです。序盤後輩が暗記の入っていない間に先輩が守りまくって（しかも敵陣のぬきも全然後輩の見本にならないくらいの遅さで）、後輩に大差をつけ、終盤自陣に大量の札を持った後輩が守りに入ってしまい、しかもそこで先輩の抜きがしょぼくて後輩に守られ、慌てて先輩が頑張って攻めて、守りに入っている後輩が先輩陣をしょぼく抜き・・・。これでは果たしてお互いの練習になるのか、じっくり考えて欲しいものです。（この著作を書くきっかけはそういうシーンをみて愕然としたことである）。万が一「ショック療法」のため後輩を敢えてタバでつぶす選択肢を取ったとした場合は、後輩がたとえ守りには入っても守らることなくそのままつぶすこと。そうしないとショック療法にすらなりません。
        </p>
        <p>
          前項で書いたように、初心者にはこちらの札を取らせる体験を蓄積させなくてはいけません。
          <b>早く抜く動作を繰り返すことが一番の練習だから。</b>
          言うまでもなく、送りをとめればいいやと自陣を守りまくってもあまり新人の練習にはならないです。
        </p>
        <p class="MsoBodyTextIndent2">
          相手のお手付き以外に送りをとめるのもよい方法です。とくに１，２字を自陣の右下段になるべく固めるも良いでしょう。最初の最初はこちらの陣は初心者ならべのように一音目が同じ札を同位置に固めるのも有効です。ぼくは良くやります。選手の成長度によっては終盤できるだけ右に札が多めに配置されるように、自陣を工夫することもあります。
        </p>
        <p>
          また、 100
          枚のうちまだ一部しか覚えていない初心者は、最初は知っている札を取りたいでしょう。知っている札が数枚しかない選手に指導する時は、まずは両者
          50
          枚とったあと知っている札を先輩側に集めるよう札を交換してから、それから並べ始めるのもよい方法です。本当に知っている札がゼロに近い選手は、残りの５０枚の札から知っている札を拾い出だして使うのも良いかもしれません。（これはそれほど使われているやりかたではないですが）
        </p>
        <p>
          かつては、ごく初期段階の初心者に指導するときは左上段を置かないという配慮の指導をしてもらっていました。ぼくら一部のＯＢがやる以外は廃れてしまった感があります。ぜひ復活してもらいたいものです。初心者が自陣引っ掛けを気にすることなく、札直であろうがなかろうが思いっきり敵陣を払えるように。
        </p>
        <p>
          <b>以上のように、いかに初心者が札をとることに楽しみを覚えるか、といった工夫を凝らしてみましょう</b>
          。
        </p>
        <p>
          逆に、最初に初心者と取るときはこちらをゆっくりでも抜かせてあげて、ある程度の取りが身についた選手に対しては、（または終盤で札が少なくなったときは、）
          <b>
            相手がちょっと遅れたときに守ってあげられる、というのが理想です。後輩は、先輩に守られないようにしようと頑張れる。こうしたさじ加減が出来るのが良い指導者です。
          </b>
          とはいっても大学二年になりたての最初だとちょっと難しいと思いますので急には完璧にできないと思います。二年生が自分の戻り手の練習にもなると思ってちょっとずつトライして習得してみてください。
        </p>
        <h3 id="h3-id-19" class="pt-4">＜勝ち負けについて＞</h3>
        <p class="MsoNormal"></p>
        <p>
          かるたはやはりスポーツゲームの１つであって勝ち負けが大事です。勝ち負けは初心者同士では関係ないけれど、初心者が
          <b>先輩とやるときは、しっかり抜いて先輩に勝つようにぜひハッパをかけましょう。</b>
          お手つきなしルールなら初心者はかるたを始めてひと月もあれば、たとえ先輩が本気で取ったとしても出札がよければ勝てるはずです。暗記して、あったと思ったらひたすら敵陣を払いまくればいいのですから。それを目指させるよう指導しましょう。
          <b>
            お手つきなしルールで先輩と対等に勝負できれば新人はかるたが楽しくなってきます。逆にいえば、早くそれくらいのレベルにまでなって、１日も早く送り止めルールやお手つきなしルールを卒業するように、とはっぱをかけよう。
          </b>
        </p>
        <p class="MsoBodyTextIndent3" style="text-indent: 0mm">
          本書のやり方の指導であれば、そのレベルにホントひと月弱で到達します。そうなれば先輩も本気で後輩相手に臨めるので先輩の練習にもなります。勝ち負けの機微を楽しめるというものです。
        </p>
        <p>
          （後述しますが、最近は攻めの強い急成長の１年生がいても無意味にお手つきなしルールをつづけて、お手付きを繰り返す初心者にあっさりドタバ負けする例もあるようです。始めたばかりの一年生をドタバでつぶすことが無意味なのと同様、これもお互いの練習になりません。後輩がお手付きしまくって先輩に勝てるようになるまで成長したら、その次は、札をしっかりよけさせることを覚えさせましょう）
        </p>
        <h3 id="h3-id-20" class="pt-4">＜一年生どうしの対戦について＞</h3>
        <p class="MsoNormal"></p>
        <p>
          １年生が、練習で 1 年生に勝とうと思えば、自陣を守って、適当に相手陣をゆっくりぬけばいい。
          <b>したがって、初心者同士との対戦を組むのはデメリットが大きいのです。なるべく避けるのは道理です。</b>
          万が一組んだときは、勝ち負けは関係ないことを強調する必要がありますが、それですぐに納得できない一年生がしばしばあらわれてしまいます。やはり、一年生同士の対戦は避けたいものです。
        </p>
        <p>
          「スタートラインが同じ新人どうしで対戦して勝敗を競ったほうが、新人のやる気が出る」という意見もしばしば耳にします。それも一理あるとおもいます。つまり、
          <b>
            かるたの成長の方向性をしっかり認識した新人どうし（つまり先輩と練習する時と同様の攻め１００％を繰り出す新人どうし）あれば、新人どうしの練習もありだろうと思います。時には先輩とやるよりもずっと効果的でしょう。
          </b>
        </p>
        <p>
          そこで、一年生同士の対戦が組まれたときは最初は横で（あるいは詠みながら）先輩がしっかり見て、横から逐一指導する、という方法が一般的です。一年生が目先の勝ちに走って攻めが鈍くならないようきっちりチェックして、もし鈍くなったときにはしっかり厳しく注意しましょう。どうしてもそうなる一年生がいたら、そんな一年生はどうしても他の一年生との対戦を避けなくてはいけません。（そういうことがないことを望みたいものですが）
        </p>
        <h3 id="h3-id-21" class="pt-4">＜お手つきなしルール・定位置作成について＞</h3>
        <p class="MsoNormal"></p>
        <p class="MsoBodyTextIndent3">
          たしかに、合宿からお手つきありというのは昔からの基準ですが、あくまでひとつの目安でしかありません。もし、「合宿まではお手つきなし。合宿からは全員敵陣お手つきあり。」ということがルール化してしまっているようであれば、それは改める必要があります。
        </p>
        <p>
          なぜなら当然個々人によって習得度に差があるため、合宿のころになってもまだ一字目の飛び出しのスピードを上げるべき段階の人が、いてもおかしくないからです。そのような人が「お手付きあり」ルールのもと、お手つきを恐れてしまったら、ますますスピードがつかない最悪の事態に陥ります。一字のスピードがしっかりつくまでお手つきオッケーでどんどんお手つきをして一字の速度を伸ばし、１字がある程度取れるようになって２，３字目の聞き分けをそだてる段階になったら、お手つきありにする。というのがもともとの「お手付きなし」ルールの目的ですから。したがって裏を返せば、合宿以前であっても習熟度の速い初心者はお手付きありルールになってしかるべきです。日頃良く取る先輩が初心者に、君はそろそろお手付きありでもいいんじゃないかな、と指摘してあげましょう。つまり、
          <b>人によって５，６月にお手つきありの場合もあれば、合宿になってもお手つきなしの人がいたって当然なのです。</b>
          <b></b>
        </p>
        <p>
          「合宿からお手つきなしの規則化」がもし起きてしまっているのであれば、まさに東大のマニュアル指導が文章の上っ面だけ伝わって本質の目的が伝わっていない一例ではないかと思います。
        </p>
        <p>
          もうひとつ、初心者の定位置をいつ作るか、というのも時々相談を受けることがあります。以前から一般的なのは、 5
          月の吉野会大会くらいまでは初心者並べで出場するけれどそのあとに定位置を作って、その年に入学した一年生が大体 5-7
          月で定位置完成、 7
          月の初心者大会には全員自分の定位置で出場する、というものでしょう。合宿時点でもし定位置が出来ていない学生があれば合宿中に完成させるというのも一般化しています。特にこのままでも良いと思います。が、早くできるのであればどんどん早く並べ替えをした方が良いことは道理です。当然、自分の定位置づくりは早ければ早いほどよいのです。周りの先輩の練習にもなるし、もし大会に出場するなら当然初心者並べでは不利になるのですから。なによりも初心者自身にとって、次の項に書くように、自陣を戻り手で取れるような体験を少しずつでも積んでいくためには、早く自陣定位置を作るに越したことはないのです。
        </p>
        <p>
          ではいつ頃になったら定位置を作るべきでしょうか。
          <b>定位置を作るために必要な知識は、当然百枚の札の上の句がスラスラ出て来て、かつ、</b>
          <b>100</b>
          <b>枚の札のいわゆる「決まり字の体系」を構造的にすべて頭に入っていること。この</b>
          <b>2</b>
          <b>点です。</b>
          札落としが 2 分程度でできるようになり、 100 枚の札を決まり字に全部分類してすらすら言えるようになれば OK
          でしょう。したがって、「 100
          枚の決まり字体系構造分類表」は、現状やっているように初心者がかるた会にきた最初の日に、確実に渡しておくのが重要となります。初心者が会に定着しそうだったら札を渡して家での札落としを薦めてみるのも昔から行われているとおり絶対不可欠の効果的な指導です。
        </p>
        <p>
          蛇足ながら筆者が初心者のときは、 4 月半ばにかるたを始めて、 5 月半ばに自分の定位置を作って、そのころから一部 C
          級の先輩とはお手付きありルールで試合をするようになりました。合宿からは A
          級の先輩とも完全にお手付きありで対戦するようになりました。
        </p>
        <h3 id="h3-id-22" class="pt-4">＜自陣について＞</h3>
        <p class="MsoNormal"></p>
        <p>
          自陣を取ってはいけない。という言い方は確かに語弊がありましょう。しかし、初心者はまず敵陣を全部最速で抜くことを目指す以上、
          <b>
            必死に身体に覚えさせ、飛び出し、聞きそびれたときのフォローをし、ひたすら暗記を回し、といったことに試合中ずっと集中しているはずであるから、自陣を取ろうとする余裕はないはずです。
          </b>
          当然、指導する先輩の役割は、始終狙い札を狙わせイメージのレールを何度も何度も引かせて、とにかく早く取るための所作を繰り返させることです。つまり厳しい言い方をすれば、
          <b>
            新人が守りに入る余裕があったと言うことは、それだけ先輩が「どうやったら攻めが早くなるか」の指導を怠っていると言うことにもなります。
          </b>
          送り札だけ教えて何もしないとそういうことになってしまいます。また、
          <b>アフターなどで攻めがるたをする意義をしっかり理解させておかないと、</b>
          初心者がとくに深く考えず自陣を守りに行ってしまいかねません。
        </p>
        <p>
          上にも書いたように、とにかく札を早く取るタイミングを身に付けさせるためには、最初「暗記を繰り返し、その暗記の中に含まれる札が読まれたら札を探さずその場所を思いっきり払えるようになる」練習を繰り返します。こうすることで「音→払い」のタイミングが刷り込まれていきます。
          <b>
            したがって、自陣の札に暗記が入ってしまった場合、自陣の札が読まれたとき無意識に敵陣を払ってしまう危険性が非常に高いのです。敵陣で札をしっかり目で見つけてからゆっくり取るのであれば自陣の札に暗記が入っていてもお手つきはないでしょうが、それでは、結局どの場所も遅い選手になるだけです。
          </b>
          このことはしっかり初心者に教えて認識させましょう。
        </p>
        <p>
          一番悪いのは、先輩がしょぼい攻めで一年生の陣をしょぼく抜いてそのくせ自陣をばりばり守り一年生に全然抜かせない、ということ。それでは一年生も守りに入りたくなります。わざとそうする人はいないと思いますが、先輩が自分の抜きのしょぼさに気づいていないとこんなことが起こりえます。ご注意を。
        </p>
        <p>
          こちらの陣の 1
          字をしっかり抜けもしないのに自陣を狙って守るような一年生が居たら、その場ですぐ、それはよくないということを伝えましょう。（かつてけりを入れるほどのやつもいた（笑）。蹴りは明らかにやってはいけないことが、それだけの真剣さをもって攻めを教えていた、ということは理解して欲しい。）。そして、ただダメと言うのではなく、試合後にでもしっかりその理由を説明することが重要です。
        </p>
        <p>
          余談ですが、静岡富士高の嶋先生という廃部寸前の富士高カルタ部を 2
          年で全国優勝させた先生がいました。その時の生徒に聞くと嶋先生は、初心者がカルタをやっているとき、一方が右下段の一字決まりをバシッと守ったとき、「まもったほうに、てめえ守ってんじゃねえ、と蹴りをいれ、守られたほうに、てめえ守られてんじゃねえよ」、とけりを入れたらしいです。
        </p>
        <h3 id="h3-id-23" class="pt-4">＜初心者にとっての自陣の取り＞</h3>
        <p class="MsoNormal"></p>
        <p>
          １００％激攻めをしながら守れるのが攻めかるたです。田口氏の指導書にあるように、自陣を意識して取ろうとしてはいけません。種村永世名人の指導にあるように、攻めがるたは自陣を取ろうとせずに取れるからこそ強いのです。取ろうとしなくても取れるようになるための練習が大事です。敵陣からしっかり出札を後ろに引っ張って自分の後方の畳を叩くまでスピードを加速しつづける払いや、２枚以上札があるときの渡り手や、共札をくっつけて置くことや、定位置をしっかり固定して動かさないことや、「むすめふさほせ」を送らないこと・・これらは、
          <b>
            １００％攻めに意識を置きながらも、戻りや無意識の反応で自陣を取る札を増やすことにつながる練習なのです。きっちり攻めを教えながらも、実は将来自陣を取るための技術的な「布石」を最初からしっかり打っているのです。初心者にそれを教えて、
          </b>
          今の段階で攻めに集中する意味を認識させましょう。なかなか練習だけでは伝えきれない場合もおおいですので、練習中以外での指導が、本当に重要となります。
        </p>
        <p>
          「自陣は暗記するといけないって上に書いている。覚えていないのをどうやって取るの？」という疑問をもった初心者については、この後のコラム狙い・暗記・把握・を参照して説明すると分かりやすいと思います。
        </p>
        <p>
          したがって、新入生が自陣を取ろうとせずに、しかし結果的に自陣を取れたのであればそれは大歓迎です。たとえば、しっかり攻めて戻るとか、先輩の攻めこぼしを確実に拾うとか、あるいは、敵陣に１字目で反応すべき札があるときはしっかり反応して、そういう札がないとき無意識に自陣の定位置に反応して守ったとか、リーチをかけられた後敵陣の一枚を激攻めして手を出してブロックして自陣を拾った・・、といったことであれば、一年生と言えど大歓迎です。そういう時は褒めるくらいでもいいでしょう。そういうことのないよう、しっかり先輩は攻めて欲しい。
          <b>
            初心者が大会に出れば攻めの弱い人と当たることも多いです。その場合、初心者が自陣を意識せずに激攻めで相手の手を止めて自分の定位置に戻れて拾えたのならそれは全く差し支えないし、良いことです。どんどんやって欲しい。
          </b>
          練習では先輩がちゃんと攻めてくるので初心者は自陣を取る余裕が全く無いでしょう（先輩がしっかり攻めて来れば、というのが前提だが）。
        </p>
        <p>
          余談ですが、攻めは完璧で申し分ないが全く自陣に戻れないＢ級選手に対して、練習対戦時敢えてたまに敵陣の出札を早く取らぬようにし、相手に「拾い」の練習をさせている先輩もいました。初心者に対してこうする必要は無いが、Ｂ級選手への指導としては参考になる例だとおもいます。
        </p>
        <p>
          もちろん、種村氏の著作にあるように、
          <b>
            戻り手の練習に励むのはＢ級以上でよいです。さらにＢになってからも戻り手の快感におぼれるとせめ手が甘くなります。
          </b>
          種村永世名人の言は本当にいいことなので改めて引用しましょう。
          <u>
            「攻めることにより相手にプレッシャーを与え、自陣の札を『拾う』ことができることがあります。攻めがるたで苦しい時は、共札が自陣しか出ない場合ですが、攻めがきぴしい時は、もどって取れることがあります。もどって取ろうと意識しすぎると、うまくいかないことが多いので、むずかしい（中略）。もどる喜びにおぼれて、攻めが甘くならないようにすることです。あくまでも攻めが基本。きびしい攻めが、もどることの助けになっていることを忘れずに。もどって取れた札は、ボーナスだと思いましょう。」
          </u>
        </p>
        <p>
          最後に注意しておくことが一点。
          <b>
            例えば別れ札があるとき、敵陣に手が中途半端にしか出なかったため結果的に自陣に戻れてしまう人がいます。それはよくない。最悪です。
          </b>
          初心者がそれを「あ、自分は戻り手が出来る！」と勘違いしないようにしっかり先輩は教えましょう。（田口氏の書く早大の「世紀の奇病」）。中途半端にしか手を出さないと結局しっかり攻めてくる人には両方とも取られてしまいます。また、構えや手の出し方が悪くなってしまっているむしろ自陣が拾いやすくなっている初心者もいます。これも非常に良くないのです。とくに最近の東大かるた会ではかつてより右狙いの指導が徹底しなくなったせいなのか、敵陣左と自陣右が取りやすい構えや手の出し方になってしまっている人が見受けられます（左右均等に攻めようとすると、右利きの人は自然と距離が近いところが取りやすくなり、そのようになってしまう）。構えたときの重心が後方に落ちて、敵陣の下段で失速したり突き気味に取ってしまう一方一方自陣が取りやすい構えになっている人ももしかしたらいるかもしれません（とくに小中学生時代にかるたを習った経験者はこうした構えになっている場合が多く、攻めがるたをやるなら矯正が必要になります）。
          <b>
            この場合は、たとえ無意識に自陣右に手が出たとしても勘違いして喜んではいけません。自陣右で１枚得した分敵陣右で５枚損することになっているのですから。
          </b>
          そうなってしまっていた場合はまず、自陣右うんぬん以前に、自陣をしっかり完全に捨てて構えや祓いを残して敵陣右を鍛えなおすべきです。
        </p>
        <p>
          もしも意識して守る後輩がいたら、
          <b>
            それでも守らせないほどしっかり抜いておいてその上で、今の時点で自陣に意識があるとなぜダメか、をしっかり言い含めるのが一番だ。
          </b>
          蹴っちゃダメだよ（笑）
        </p>
        <h3 id="h3-id-24" class="pt-4">＜ コラム ：狙い・暗記・把握＞</h3>
        <p>以下は、川村のＢＢＳに投稿した自分の文章である。そのまま引用しよう。</p>
        <p>
          暗記の説明は難しい。というのは、「狙い」、「暗記」、といった言葉をどういうニュアンスで使うかが人によって場面によって微妙に異なるからです。
        </p>
        <p>
          初心者の誤解を招く場合がしばしばあると思います。例えば、「自陣の暗記をしよう」と言われた初心者が、敵陣の暗記と同じ感覚で自陣に暗記を回してしまうと、当然攻めは鈍るしミスは多くなるし構えや払いが壊れてなかなか直らなくなってしまうことになります。
        </p>
        <p>そこでぼくは、「把握」という言葉を使う場合がよくある。あくまで自分なりの定義づけを書くと、</p>
        <p>
          〇「暗記」：札の場所を覚えるだけでなく、①その音に手が感じる、②札の上空ぎりぎりまで手を伸ばす、③決まり字の瞬間で払う、④着地する、
        </p>
        <p>
          という一連の動作が音にあわせて出来るように、そのイメージを身体に意識的に刷り込むこと。人によっては「意識的な暗記」ともいう。
        </p>
        <p>
          〇「狙い」：「暗記」の中でもとくに優先順位を高くし、とくに強く音を意識すること。また、近くにある他の札の暗記や、払いや飛び出しのタイミングをコントロールするために軸とする暗記のこと。
        </p>
        <p class="MsoBodyText">
          〇「把握」：人によっては「無意識の暗記」ともいう、メタレベルの「暗記」。札の場所や決まり字だけは意識して覚えるが、音の感じ方やそれを取る動きを身体に意識して刷り込むことはしない。
        </p>
        <p>
          という具合でしょうか。要するに 3 つの違いは、
          <b>意識の程度差</b>
          です。高い順に、狙い、暗記、把握、となります。もちろん、３者は厳密に区別できない場合もあるし、 3
          者それぞれの中で札によって意識度に差があるのが普通です。
        </p>
        <p>
          大事なことは、意識の強い札ほど枚数を多く作れない、ということです。個人差はありますが「狙う」札は数枚です。逆に「把握」だけなら場の全部が可能です。
        </p>
        <p>
          暗記のキャパシティはその中間ですが、練習を積むことでそのキャパ自体を拡大できますし、また狙い札を上手につくることでキャパを効率よく使うことが出来ます。
        </p>
        <p>
          狙い札を最速で取りなおかつ他の札を取ろうとする練習をすることで暗記のキャパは拡大できるでしょう。もちろん、暗記はそのときの集中力で暗記の入り方も違ってきます。
        </p>
        <p>だからこそ、暗記の入れ方回し方が非常に重要なわけです。</p>
        <p>さて、ではより効率的な暗記の回し方とは？</p>
        <p>
          まず第一に、狙う札、狙わないけど暗記する札、把握する札、を振り分けることです。場合によっては、それぞれの中でもさらにある程度は細かい順列をおき、狙いや暗記の強弱をつけることもあるでしょう。暗記が得意な人というのは、全部を満遍なく暗記するのではなく、意識的に（あるいは無意識に）こうしたメリハリをつけているのだと思います。
        </p>
        <p>
          初心者のうちは、とにかく１字を狙う。他の札は「把握」が精一杯、「暗記」はゼロ、という状態となりますが、それでいいと思います。
        </p>
        <p>
          第二に、狙い札をうまく作ることでしょう。狙い札により構え払い手の出し方が狙い札に引っ張られます。ですので、基本は自分が一番取りにくい右下段の１字を第一に狙い、飛び出すタイミングそこにチューニングすることで、他の札も速く取れるようになります。また、狙い札を置くパターンは常に一定にするのがいいでしょう。右中・下段に２枚、左に１枚の計３枚。といった具合に。それによって自分の構えや払いや飛び出しが良いものになっていきます。一度遅く出る癖がついてしまうとなかなか治らない、というのは体感した人は多いと思います。大事なことは狙い札をうまく作ることで、暗記しているが狙ってない札も狙ったのと同じくらいに早くとれるように狙い札を作る、ということでしょう。
        </p>
        <p>
          第三に、暗記札をうまく作る。上にも書いたように、暗記のキャパを増やしたり、限られたキャパを効率よく使うのが、かるたの練習で大事な点です。繰り返しになりますが、身体の動きを連動してインプットすることが重要と思います。基本は、自陣は「把握」のみ、敵陣はしっかり暗記、ということになると思います。自陣の暗記を入れてしまうと敵陣の暗記とごっちゃになり、ミスの原因にもなりますし、敵陣に手が伸びなくなります。そして、暗記を効率よく回すには、順番を決めて、迷うことなく短時間で数多く回せるようパターンを決めることだと思います。また、暗記の強さにも場面場面で優先順位が生じると思います。
        </p>
        <p>
          なお、僕の場合は、読手が読んでいない間には、上記の「札の振り分け、優先順位の決定」「音の反応のタイミングや身体の動きのインプット」を行い、読みが始まってからは２字単独の札と１字札を、優先順位の低い順からその音を意識し、最後狙っている２
          , ３枚を入れるとちょうど余韻に入る、といったことをやっています。
        </p>
        <p>
          第四に、把握だけしている札をうまく取れるように練習する。これは A
          級に上がる前後からでしょうか。相手の取りこぼしをひろう。単独の３字以上の札に無意識に手が出るようにする。敵陣を攻めて、「じゃない」で戻る、などです。
          A 級上位になると、だんだん把握と暗記の差は縮まるような気がします。
        </p>
        <h3 id="h3-id-25" class="pt-4">＜暗記・狙いについて＞</h3>
        <p>
          狙いの意義・狙い方は波多野氏の著作に詳しい。ぜひ学んで欲しいものです。暗記についても波多野氏らの著作にくわしいです。何度も書いたように、ただ頭に覚えるだけ無く、
          <b>
            音を聞きながら札を払って、さらに加速して自分の身体の後方で畳を叩くまでの動作を身体に暗記させる（波多野氏のいうイメージのレールをたくさん敷く）ことが重要です。その意味では、一連の動作全てを身体にインプットして初めて暗記したといえます。
          </b>
          一枚読まれるごとに同じ札に対して何度もレールを敷く。
          <b>
            そして狙っていない札にもイメージのレールどおりに無意識にスムーズに手が出てしっかり払えるようになれば成功です。
          </b>
          音を意識しながら素振りするのはこの「レール」を固めるためのものです。出札や空札で一音で反応できなかったとき必ずその後一秒でも早く自分の意志でフォローの動作をするということも、無意識にレールに乗った動きができるようになるための非常に重要な練習なのです。同様に、空札のとき空中で失速してしまっても
          <b>
            そのまま手を引っこめず一度止った手を止った位置から必ずしっかり振りなおすべし、というのも、このレール通りに手を動かすための練習です。
          </b>
        </p>
        <p class="MsoBodyTextIndent3">
          新入生に対しては最初とにかく短い札（とくに右）を狙わせて飛び出させることを述べました。まずは狙った札に狙いどおりに反応することを練習させて、だんだんできるようになってきたら
          <b>狙ってない札を暗記で取ることを教えましょう。</b>
          <b></b>
        </p>
        <p>
          また、細かいことであるが、暗記のまわしかたは大事です。初心者に何も教えないと、たとえば「たまのをよ」を「たま」としか暗記を回さないという事態が生じてしまいがちです。初心者のうちは特に、決まり字以降の文字つまり「たまのおよ」まで音を回すのが重要です。先輩には当たり前のことでもしっかりこのことを初心者に伝えましょう。
          <b>
            ２字でとるタイミングは身体に覚えさせ、頭では５文字まで覚えさせよう。初心者に、「この札は何？」と尋ねたとき「たま」としか答えが帰ってこなかったら、「たまのをよの、たま」と頭で唱えるのだよ、と確認させよう。
          </b>
          <b></b>
        </p>
        <p class="MsoBodyText">
          理由は、上記の「決まり字ちょうどで反応し切れなかったとき」に対応するためです。初心者が「たま」しか暗記していないと、「たま」ちょうどで反応できなかったときに、もうどうにもならないのです。決まり字以降まで暗記を入れて初めて、決まり字に反応が遅れたときも対処しうるのです。この暗記法をしないと、「早い札は早いけど、遅い札を拾うのがおそい」ままになる危険性が高く、将来「狙ってない札が取れない。」「一瞬タイミングが遅れると上空で手が止まる」「相手が取りこぼした自陣が拾えない」と、苦しむことになります。
        </p>
        <p>
          なお、狙い方は常に一定させるように教えるのがセオリーです。右を狙ったり左を狙ったりするのではなく、右を重めにした一定の比重（例えば第一優先狙いは「右３枚左１枚」というように）にすることです。理由は「払いと構え」のところで述べます。一番やってはいけない指導は、
          <b>
            右が左より早い初心者に「左をもっと早く取れるようにしよう」といって、左より右が早くなったら「右を早く取れるようにしよう」といたずらな指導をすることです。
          </b>
          結局左右どちらも遅い中途半端な選手を育てる最大要因となってしまいます。初心者は「左が遅い」といわれれば左をより取ろうと意識して狙ってしまうでしょうから。右が早いが左が遅い選手に対する指導では、あくまで、狙いの比重を右に置くことを変えさせてはいけません。狙った右をより早くしつつ、その上で同時に、狙っていない左の札を暗記でフォローさせる訓練（レールを敷く訓練や狙い札を選ぶ訓練や渡り手をスムーズにやる訓練、右を狙いつつ左を拾える構え方の訓練、払いの練習、等）をさせることです。指導時に、十分表現に気をつけて欲しい。
        </p>
        <p></p>
        <h3 id="h3-id-26" class="pt-4">＜感じの鍛え方＞</h3>
        <p class="MsoBodyTextIndent3">
          感じの鍛え方、については田口氏、波多野氏の文章にも良くかかれているので読んで欲しいです。
          <b>感じは生まれついてのものではない。しっかり鍛えましょう。とくに</b>
          <b>1</b>
          <b>字目と同時に（田口氏曰く、読まれる直前に！）飛び出す練習は、感じを鍛える目的にはとても効果的です。</b>
          感じを伸ばす訓練をしたことがない上級生は、下級生に対してもこの訓練をついついおそろかに教えがちになってしまいます。しっかり感じを伸ばす訓練をさせましょう。
        </p>
        <p class="MsoBodyTextIndent3">
          <b>加えて重要なのは、感じと払いを連動させること（払いに感じを乗せること）です。</b>
          つまり手を出しながら音を聞くよう意識する、音に反射して払えるように繰り返し練習する、イメージのレールを敷く、空札でもしっかり手を出して加速してよけて後ろまで手をもってくる、上空で手が止まってもそのまま手を引かず振り切って後ろまで手をもってくる・・・・というのは、払いに感じを乗せるための有効な練習方法です。
        </p>
        <p class="MsoBodyTextIndent3">
          結局、カルタのスピードを上げるためには、反応を早くする→払いをそれにあわせる→また反応をさらに早くする・・・というたゆまぬ努力の繰り返しなのです。
        </p>
        <p>
          なお、純粋に音に対する反応を鍛える練習として、波多野氏が「体の中のアンテナ・センサーみたいなものを育てる」と、良いことを書いています。東大でも一時期会内で一番感じが早いといわれた中原氏の練習法は、全ての子音を子音の最初に聞こえる音で４つのグループに分け、どのグループかが分かった瞬間に、そのグループの予め決めておいた一枚を反射的に払う、という練習だそうです。たしか、Ｋ・Ｔ・Ｈ・Ｓ音のように子音の最初に“息が勢い良くでる音”、Ｍ・Ｎのように“子音の最初がこもる音”、Ｙ・Ｉのグループ、それからＡ・Ｕ・Ｏ・Ｗのグループだったと思う。（うろ覚えなので間違っているかもしれませんが）。初心者にいきなりは無理だが、話として「練習次第でそこまで出来るようになるんだよ」ということを説得するにはインパクトがある話だとおもいます。「自分は感じが遅いから攻められないんだ」という人がいたが、そんな新入生がいたら、そうではないことをしっかり説明して自信をつけさせましょう。
        </p>
        <h3 id="h3-id-27" class="pt-4">＜複数箇所に散った札のとり方＞</h3>
        <p class="MsoNormal"></p>
        <p>ここでは便宜的に、一文字目が同じ札が敵陣の複数位置に散っている場合を「複数札」と呼びましょう。</p>
        <p>
          時々「複数札を①一字目で飛び出して渡るべきか、それとも②手元で聞きわけて一直線に取りにいくべきか」という議論がなされてしまうことがあります。どちらが正しいかって？実はこれは不毛な議論だと思います。なぜならその正解は明らかです。「試合展開によって札の配置によって相手のタイプによって、①②を巧みに使い分ける」のが絶対に必要なのですから。この①②の使い分けも相手との駆け引き上重要ポイントであり競技かるたの戦略上の重要部分です。したがって、
          <b><u>将来的には</u></b>
          ①②両方の技術をいつでも繰り出せるよう両方をマスターしなくてはなりません。さらに言えば、①と②の中間的な取りも存在します。つまり一字目で途中まで手を出してから方向転換すること。この場合一字目でどのあたりまで手を出すか、というのをケースバイケースで調整して変えることができ、この調整も重要なテクニックです。
          B 級くらいになると、これをマスターできないとなかなか敵陣全部を抜ききるのが難しく、 A
          に上がれない一つの技術的欠点となります。（飛び出した手を止めずに後方まで振り切るのが重要、とこれまでさんざん書いてきましたが、それができるようになったあとには、敢えて「止め」て方向転換するテクニックも必要になります。）
        </p>
        <p>このように、①②どちらが良いか、というのはそもそも論じる意味がないのです。</p>
        <p>では、初心者は？初心者は複数札をどのようにまず取る練習をすべきでしょうか。</p>
        <p>
          すでに述べましたが、ごく初期の初心者に対しては、こちら（先輩がわ）の札はなるべく１字目が一箇所になるように工夫しましょう（最初の２５枚では難しいが）。つまり、初心者に①②を意識しなくても良いようにするのです。しかし初心者が少し慣れてくると、こちらも普通の定位置のまま練習の相手をするようになります。
        </p>
        <p>
          さて、このときは①が優先順位最初となるでしょう。つまりごく
          <b>初心者には複数に散っているときはまずどれか一方に飛び出るよう教えよう。</b>
          詳しい理由は田口氏の文章するとよいと思います。
        </p>
        <p>
          筆者なりに補足説明すると、上級者であれば単独札なら 1
          字目で飛び出して②複数散った札は飛び出さない。と飛び出しと聞き分けを同時に追求できます。が初心者に両方を要求するのは難しいのです。上記のように、感じの速さを鍛える練習行っている場合は、飛び出しと聞き分けを両方同時に追求することは非常に難しいのです。②複数札のときには飛び出さしてはいけないと意識してしまうと、単独札ときにも飛び出せなくなってしまいます。飛び出しの意識がその分弱まるので、感じの速さが鍛えられず、結局複数札どっちも遅くしか取れなくなってしまいがちです。
        </p>
        <p>
          非常にまずい指導の一例は、一字の反応を鍛え始めたばかりの初心者が複数札に対し一字目で出札とは逆の方向に飛び出してしまったとき、先輩が「ちゃんと正しい方向に手を出すようにしよう」と注意してしまうことです。こうすると初心者は、単独札は飛び出し複数札は飛び出しをやめて聞き分ける、という２つのことを両方同時に追求しなくてはならなくなり、非常に混乱してしまいます。（無論、一字目の飛び出しがある程度できるようになった一年生に対してなら、言っても良い指導なのですが）
        </p>
        <p>
          すなわち、最初のうちは複数札はどちらか一方だけ早く取れればよいし、まずはそれを目指すべきでしょう。ですので初心者には、複数のうちどれを一番メインで意識するか、というのを確認させましょう。どれも均等に漠然と暗記しているだけど、結局どの札も遅くしかとれなくなってしまいます。重要なのは飛び出したのと逆の札がいざ読まれてしまったときも、やはりそのまま無反応に終わらないことです。
          <b>
            逆方向に出てしまっても、違うところに出札があると気づいた瞬間に、遅くてもいいからしっかり正しい札を思いっきり加速して払って渡り手で取らせることです。この払いのカバーを練習しないと、いつまでたっても渡り手が出来るようにならないし、空中で聞きながら正しい出札へ手をもっていくことが出来るようにもなりません。
          </b>
          <b></b>
        </p>
        <p>
          上に「イメージのレール」の事を書いたが、渡り手の練習についても、しっかり音を聞いて渡るイメージのレールを引き、素振りのリハーサルを繰り返すことが重要です。
        </p>
        <p>
          理想は、
          <b>最初に飛び出した札ではない、と分かった瞬間に、もう一方の札に手が渡っていけるようになることです。</b>
          この「じゃない」の取りが出来るようになってくると、将来戻り手にも同じ原理を利用できるようになります。裏を返せば、戻り手の練習はこの「飛び出し」－「じゃない」の感覚が分かってからのほうがよいです。そうしないと、どちらも取れなくなる。「じゃない」の取り方の効用は、波多野氏の著作に乗っている「暗記の効率的な使い方」に分かりやすい説明があります。
        </p>
        <p>
          そして次に書くように、②、つまり、敵陣の複数札を聞き分けて一直線に取る練習もじきに必要となります。
          <b>
            最初は①だけだが、かるたを始めてひと月もすれば、①をやったり②をやったり、と混ぜた練習が必要になってきます。上にも書いたように、目指す目標は臨機応変の①②の使い分けなのです。
          </b>
          とくに「あ」札は、どれか 1
          枚にだけ飛び出しているだけではなかなか取れるようにならず、初心者がまず最初に直面する「壁」となり、解決が望まれます。実は、この①②の練習仕分けは、おそらく
          A
          級になってもつづきます。ちょっと一字が遅いな、と思えば複数札をある程度犠牲にして①を練習し、聞き分けが甘いな、と思ったらちょっと飛び出しをセーブして②を磨くといったように。
        </p>
        <p>
          最後に補足を、例えば左右に分かれた札を②聞き分けてどっちも直線で取ろうとするときに、両方を均等に暗記していては遅くなることは理解しておいて良いでしょう。
          <b>
            かならずどちらかを第一優先で暗記しておく。一音目でそちらに感じていながら、敢えて手は出さずにとめておき、第一優先ではないと思った瞬間に第二優先に行く。
          </b>
          そう、つまり渡り手を手を動かさず目だけでやっているようなものです。
        </p>
        <p>
          ちなみに、上に「初心者のうちから②手元で聞いて両方を取るのは、感じの速さが鍛えられず、どっちも遅くしか取れなくなってしまうので注意しよう。」と書きましたが、時々は、初心者が一字目でしっかり飛び出しつつ②が同時に出来てしまう場合もあるでしょう。それはそれで全く問題はないと思います。慣れるとだんだんできるようになってくるものです。また、ときどき器用な一年生が入会します。彼らは感じが早くて、簡単に一字目で飛び出しつつ②も出来てしまったりします。それも問題はないでしょう。（彼らは器用に自陣も守りに行く癖（＝田口さんのいう世紀の奇病）がついて伸び悩む場合があり、それは絶対に避けねばならないが。）。なお、こうした器用な選手は、感じの速さを伸ばす必要に迫られた経験があまりないため、翌年指導者側に回った時に①を否定してしまいがちです。そうした場合は周囲が、「まず感じの速さを鍛えなければいけない新入生の方が多い」ことや、将来的には①②の両方ができなくてはいけないこと、を理解させましょう。田口氏の著作はそのための良い説明になると思われます。
        </p>
        <p>
          また、複数札の取りはあえて指導しない、という意見も聞いたことがあります。なるほどたしかに結局は①②どちらもできなくてはいけないわけだし、そもそも最初は複数札よりまず単独札をとることが重要ですから、ごく初期に敢えて複数札についてわざわざ言及しない、というのは十分ありうる話だとは思います。しかしその一方、「敵陣を全部抜けと言われているのに、複数に散った札がなかなか取れるようにならない」とか、「複数札を取ろうと飛び出しをやめたら、単独札も遅くなってしまった」などと壁に当たって悩む初心者が多いのが実情で、しばしば相談を受ける悩みなのです。そういう初心者向けの答えは用意しておくのが指導者として絶対必要となるでしょう。（悩んだ経験がない器用な人は「指導しなくてもいい」んじゃない、と言ってしまうのだと思います）。「敵陣全部抜け」と指導する以上、具体的に「どうやったら複数札を含めて全部抜けるか」という方法論を提示できないと、「じゃあ全部抜くなんて無理じゃん。全部抜け、なんていうのは大げさに言っているだけだ」と思われてしまいます。
          <b>
            「敵陣全部抜け」はモノノタトエでも大げさ表現ではなく、本当にそうすることが強くなるために必要なのだ、と新入生に伝えきること、これが攻めがるた指導の一番重要な点です。
          </b>
          <b></b>
        </p>
        <h3 id="h3-id-28" class="pt-4">＜聞き分けの鍛え方＞</h3>
        <p class="MsoNormal"></p>
        <p>
          では聞き分けはどうすれば磨けるか？感じが早いと聞き分けが苦手になる、とか、早く動くとお手つきが多い、とかいう人がいるが、そうではないです。そうなってしまうのは手を出しながら聞く練習が足りないだけです。確かに遅く動けばお手つきは減りますが、それでは無意味だということは既に述べました。
        </p>
        <p>
          <b>感じを鍛える練習と聞き分けを育てることは、一体です。</b>
          上記の著作には無いが、種村氏が
          <b>「早く反応するほどお手つきをしない。なぜなら空中でしっかり２字目、３字目を聞きわける余裕があるから」</b>
          と指導していたのを聞いたことがあります。そのとおりと思います。つまり、レールを固めて、レールのとおりに無意識にも手が動くようになれば、余裕を持って手を動かしながら聞くことができ、聞き分ける余裕にも繋がります。だから、飛び出した後決まり字で払うタイミングをしっかりイメージのレールで固めさせましょう。
        </p>
        <p>
          聞き分けに重要なもう一つのポイントは、
          <b>札に第一優先、第二優先・・としっかり強弱をつけて暗記をしておく</b>
          ことです。
        </p>
        <p>
          初心者に教える順番としては、 ( １ )
          まずはお手つきを気にせずとにかく早く感じたまま払えるようになる（特に右下段）。次に、（２）ちがったときよけられるようになる。次に、（３）第二優先の札に渡れるようになる。（４）手は動かさずに目で第一→第二優先と渡れるようになる（５）敵陣と自陣の札に暗記の強弱（暗記と把握の差）をつけて、敵陣→自陣と聞き分けて戻る・・・・というプロセスが大事です。
          ( １ )
          をやりながら突然聞き分けも両立しようとするから、余裕をもって聞けずにお手つきが増えるのです。また、いきなり自陣と敵陣を全部暗記をいれそれらの強弱がないと、やはりお手つきが増えます。高校生まではこうしたお手つき連発かるたで弱い人がときどきいます。最近東大でも、そこそこ早くて自陣も取れるけど、お手つきが多くてＤ，Ｃですら勝てない、とか、あるいはＡに上がってたまに入賞するけどそこで止まる、というひとを見るようになってしまいました。これをしっかり治すだけで大分東大かるた会の力は上がると思います。
        </p>
        <p>
          繰り返すが、最初のうちはとにかくお手付きを気にせず早く払う癖をつけ、次のステップでそのスピードを保ちつつ聞き分ける練習が大事です。
          <b>お手つきを減らすため、遅く出たり手を止めたりしてしまっては意味が無いのです。</b>
          <b></b>
        </p>
        <p class="MsoBodyText">
          さて一方、３字札の決まり字際の聞き分けは、いかに音を聞く回数を重ねたかだ。という人もいます。三重県かるた協会の太田元選手権者は、たとえば「ながらえば」と「ながからむ」をランダムに何十回も録音したテープを毎日通勤中に聞いて「ながら」だったらつり革を握る手に力を入れる、といった練習をしていたそうです。初心者にいきなりやれとは言うものではないですが、聞き分けを育てたいと願う後輩には１つのアドバイスでしょう。
        </p>
        <p class="MsoBodyText">
          初心者にとって注意するべきは、たとえば「いに」と「いま」の「い」は違う、という上級者の話をなまじっか聞いて、「いに」が２字決まりのときも１字決まりと同じスピードでとれるようになるんだ、と勘違いしてしまうことが時々あること。もちろんそれはありえません。たしかに「いに」と「いま」の「い」は違うが、それは、「い」の音のいわば終わりの部分が変わってくるだけの話です。どんなに聞き分けを鍛えても、「や」「ゆ」「よ」ではなく「い」だと認識できるタイミングよりも、「いま」ではなく「いに」と分かるタイミングが、早くなる、なんてことは絶対ありえません（もしそんな人がいれば、それはただ単にＹ音の聞き分けがダメということ）。初心者が誤解しないように指導者は気をつけましょう。たまに、“「いに」と「いま」は「い」で分かるから「い」で飛び出さないようにしよう”、と指導する人がいるようですが、当然初心者はまず「や」でも「ゆ」でも「よ」でもなく「い」だと認識したらしっかり「いに」の上空まで手をもっていき、そこで「いに」の「い」かそうでないかを聞き分け、違ったら避ける、という練習をしましょう。
        </p>
        <p>
          繰り返すが、手元で聞き分けるのはその次のステップです。既に述べたように、
          <b>将来的には</b>
          手元で「い」を聞き分けたり、逆に「い」一字で飛び出したりと、試合展開や相手や札の配置によって、聞き分けと渡りを使い分けられるようになりたいのです。どちらか一方しか出来ないとＡで勝ち抜くのはつらいです。だからといって
          ,
          かるたを始めた最初から手元で聞き分けてとっていると、手元で聞くことしか出来ない悪い癖がついてしまいます。（田口氏の書く「世紀の奇病」）。
          <b>両方出来るようになるためには、ごく最初には渡りを学ぶべし。</b>
          最初に聞き分けてしまうと、あとから渡りを覚えるのは困難であるばかりか
          <b>「いに」が単独札のときも「い」１字目の札際への攻めが無意識にも甘くなってしまうという非常に悪い癖</b>
          がついてしまいます。
        </p>
        <p>
          （余談だが、そういう悪い癖がついた選手を相手に戦う時は、「いに」が単独でも２字でいるうちは送らないのが良い戦略です。相手の攻めが甘いため自陣で拾える可能性が高い）
        </p>
        <p></p>
        <h3 id="h3-id-29" class="pt-4">＜構え、払いについて＞</h3>
        <p class="MsoNormal"></p>
        <p>
          これまで述べてこなかったが、構えや払いは当然非常に重要です。とにかく敵陣激攻めを遂行するには、感じや聞き分けだけでなく払いを育てる必要があります。狙い・構え・払いについては波多野氏の著作によい説明があるのでぜひ参考にして欲しいものです。ただ、これまで本著作で述べてきたことを実現しようと練習すれば、自然と構えや払いもそれに従って身体に染み付いていきます。
          <b>
            つまり、敵陣を全部攻め取ろうとしたり、とくに右下段と１字を狙って激抜きしたり、全部払い手でとり、空札でも飛び出した手を止めずにしっかり引っ張って自分の身体の後方まで持ってきたり、万が一止まってしまっても止まったその位置から加速して後ろまで振りなおしたり、・・・という動作を繰り返すことで、それに適した構えや払いが固まっていくものです。
          </b>
          このように、激攻めを志すことで少しづつ払いが身につくという側面もあります。一方、守りがるたでは感じの早さだけは育つが押え手や突き手ばかりになる人が多いです。
        </p>
        <p>
          波多野氏もその指導書で書いていますが、狙いは構えや払いをしっかりさせるために重要です。そのため、狙い方をいろいろ変えてはいけないのです。
          <b>
            右をねらってみたり左を狙ってみたり、ましてや負けて自陣を狙ってみたり、と狙いを変えると、構えや払いがいつまでたってもちゃんと固まりません。
          </b>
          例えば右 2 枚左 1
          枚といった感じで、常に狙い方を自分なりに決めておくのが良いのです。仮に敵陣の配置が左２０枚右５枚になっても、右のポイント札を激狙いするというやり方であればそれを変えてはいけません。
        </p>
        <p class="MsoBodyText">
          また、調子が悪いと感じるとすぐ構えや払いを変えたがる人がいます。構えや払いの修正は必要なことですが、これも上と同様の理由で「やりすぎ」は要注意です。さらに言えば、試合中に一生懸命構えを考えるのはその分暗記や狙いに対する集中をそぐので両刃の剣です。中には器用な人が居て、試合中もいろいろ構えを考えながら取れてしまう人がいますが、それは少数派です。できる人はそれでいいですが、できない人にもそれを指導するのはやめた方がいいでしょう。
        </p>
        <p>
          構えや払いの指導は難しいです。なぜなら、個人個人で体格も違えば音の感じ方も違うからです。Ａさんに適した払いがＢさんにも適しているとは限らないのです。東大かるた会に限らず後輩の払いや構えを気に入らないとすぐにいじりたがる人がいますが、
          <b>「払い綺麗になれども取り遅し」となっては意味が無いのです。</b>
          <b></b>
        </p>
        <p>では構えや払いはどう指導するか。</p>
        <p>
          一つは、色んな強い人のかるたを見て、それぞれの選手のタイプと払いを研究しておくことです。そして後輩に似たタイプ（感じの早さ、体格、取り口など）で攻めの強い選手を参考にさせるのが一つの方法です。例えば数年前の合宿では、体格と感じ方が似ている先輩の払いを参考にさせようと、一晩中その先輩と初心者とを連れ出して指導をしていました。
        </p>
        <p>
          もう一つは、ビデオを取って当人に見せたり、そばから見て助言を送ったりながら本人に試させ、じっくり後輩一人一人のスタイルを作り上げるためのコーチングを計ることです。かつて授業の空きが合う時間を見つけておいて湯島会館を予約し、マンツーマンでこうした払いの指導をしていました。こうした熱心な指導が、東大かるた会を強くしてきた最も大事なところといえます。（上に、とにかく新人を育てようという熱意を持とう、と書いたのはそういうことだ。）
        </p>
        <p>
          東大では普通の練習はただ試合を３試合やるだけなので、払いの練習の時間は別に取らねばならなりません。４－６月に最初一日２試合しかないときには、空いた時間でじっくり構えを見たり、自主練では払いの指導の時間を設けたりしてもよいでしょう。
        </p>
        <p>
          こういった練習時間以外の個人指導を、今どれだけ後輩諸君にしているだろうか。先輩はちょっと考えてみて欲しいです。
        </p>
        <p>
          とはいえ、やはり払いは本人の努力が一番重要である。それを初心者に認識させましょう。無理やりに後輩を呼び出して個人指導をしても効果は薄いと思います。
        </p>
        <p>
          最後に重要な確認を。払いの練習で大事なことは、音と連動して払いを練習することが望ましい、ということです。
          <b>
            払い練を一生懸命やった結果、素振りでは綺麗に払えるようになったが、いざ札が読まれると全然ダメ、という人が少なくありません。それでは意味が無い。できれば、払い練のときも、誰か他の人かテープやよしぽんソフトを利用し、音に反応して払う練習をしつつ払いを固めて欲しいです。
          </b>
          <b></b>
        </p>
        <p class="MsoBodyText"></p>
        <p>
          以下、構えの基本ポイントだけ書きましょう。攻めかるたの構えの基本ポイントは、
          <b>
            <u>前方に重心をしやすく、そのあと後ろに札を引っ張れる構えであること</u>
            。
          </b>
          そのためには足を平行におき、右ひざを後ろに下げすぎないようにして重心を落とさないことが重要です。また両腋を甘くしないことも重要です。左手を必要以上に遠くに置いたり、右ひじを横にだすと腋が開いて手だけで取る取りになってしまいます。（ちなみに初心者が自陣の右に色気を出したりすると、無意識に構えたとき（または払った時）右の腋が開いてくるので良く分かる。）また左足首は立てないこと。左足首を立てると確かに重心が高く保てるのですが、前につんのめってしまい、重要な「後ろに引っ張る」払いができなくなります。
        </p>
        <p>
          もうひとつの基本は、
          <b><u>敵陣右下段を取りやすい構えであること。</u></b>
          必然的に、構えた時に体重は右足よりも左足にかかっていることになります。左を取る時には、
          <b>左足から右足に体重を移動しながら取る</b>
          のが基本となります。
          <b>この重心移動のためにも足は平行に置くのが基本となります。</b>
          <b></b>
        </p>
        <p>
          身長が高いごく一部の男性選手は重心を上げすぎると競技線より先に頭が出たり払いが高くなったりバランスを崩したりしてしまうために、若干足を（膝を）
          10
          センチ以上開いたり右足を後ろに大きく引くこともあるが、長身選手以外がこれをやると前方への重心移動がしにくくなってしまうので注意が必要です。ちなみに、守りがるたの選手はむしろ後方に重心を落とすのが基本となるので、両ひざを開いて構えどっしり構え、敵陣は前方に体重を移動せずに突き手気味に（あるいは手首だけを使って）敵陣を取ることになります。
        </p>
        <p>
          指導で良くない例はいくつかありますが、代表的なものは、右左均等に重心を置かせてしまうことです。一番多い良くない指導例ではないでしょうか。避けたいものです。もう一つ多いのは、たとえば背の高い人が自分と同じように後輩に教えてしまい、
          <b>
            長身選手でない子にも両ひざを開かせたり、右ひざを大きく後ろに下げさせたりして、重心が落ちた守りがるたの構えにさせてしまうことです
            <u>
              。これは絶対避けなければいけない。払った時札がななめ前方に飛んでしまうような選手は構えがちゃんと出来ていないのです。
            </u>
          </b>
          しっかり札を後ろに引っ張れるような構えにするよう注意させよう。とくに女性で両ひざを開いて腰を落とす構えはまず
          100% ありえない。指導者は自分の構えをそのまま後輩に教えないよう、重ねて注意としたいものです。
        </p>
        <p>＜どの程度まで攻めなくてはいけないか？攻めてるつもり？＞</p>
        <p class="MsoBodyTextIndent3">
          指導において「攻めすぎだよ」、という言葉を聞くことがあります。これは非常に誤解させるまずい表現です。初心者はこういうことばを聞くと、攻めないほうがいいのかと思ってしまいます。敵陣を名人からでも全部抜けるようになったらそれ以上攻めるのは攻めすぎといえるかもしれないが、そこまで至らぬうちは、攻めすぎということは決してないのです。
        </p>
        <p>
          どうやら、手を敵陣に向けて出すもののその後のフォローが無い ,
          とか、ブッ早く手を出すもののお手つきが多い、とか、あるいはやや上級の選手については、敵陣は早いけれど自陣に戻れなくてゆっくり札を抜かれる、とかいう場合に「攻めすぎ」といわれることがあるようです。これらは、正確に言えば
          <b>
            攻めすぎなのではなく、渡り手や、聞き分けや、戻り手を意識した練習が足りないだけで、攻めすぎではないのです。指導の時は後輩を混乱させないように注意しよう。
          </b>
          <b></b>
        </p>
        <p class="MsoBodyTextIndent3">
          （もちろん戦略的にわざと手を出さず攻めないようにする、というのは目の前の試合について勝つためにはありうる。）
        </p>
        <p>
          一つこれまで書いてこなかった重要なことを書きましょう。たとえば
          <b>
            単独２字が自陣にあったとき、一音で相手の手がその札の上空まで完全に来てぴったりよければ、こちらはとてもプレッシャーを感じます。出札も完璧に抜かれつづければ、「あ、これは自陣は守れない」と思ってしまうものです。攻めがるたは相手にこう感じさせれば勝ちなのだ。
          </b>
          <b></b>
        </p>
        <p>
          だが逆に、「なんだ。この相手からなら守ろうと思えばいつでも守れる」と思われたら、負けなのです。本人は攻めているつもりでも相手にプレッシャーを感じさせられないと、攻めたことにならない。このことはぜひ新入生にも伝えるとともに、「なるほど、こりゃ守りたくても守れないや」と新入生が思うくらいの攻めを指導中みせるべく、先輩はがんばりましょう。
          <b>
            ただ自陣を取らず敵陣だけをとることが攻めがるたなのだ、と思い込んでいる人がまれにいるが、そうではないのであります。
          </b>
        </p>
        <p>
          また、相手の感じを消すことで、強くプレッシャーをかけることが出来ます。手を出して相手の感じを消すことが攻めがるたの本質ではないのですが、初心者がまず最初に強くなって練習や大会で勝つには、飛び出して相手の感じを消すことは非常に有効な手段となります。＜自陣について＞で述べたが、敵陣に厳しい攻めをすることで、相手の手を止めて戻れるのです。新入生には「相手の感じを消せ」というのではなく
          <b>「相手の感じを消すくらいに強く攻めてプレッシャーをかけろ」と教えましょう。</b>
          <b></b>
        </p>
        <p></p>
        <p class="MsoBodyTextIndent3">
          東大かるた会に限らずかるたの格言として「負けているときこそ攻め、勝っているときこそ守れ」というものがあります。そのとおりです。Ｂ級以下では「勝っているとき守れ」は難しいので下手にやらぬべきですが、
          <b>「負けているときこそいつもより強く攻めろ」はとにかく新入生に強調して言って、徹底させましょう。</b>
          理由も説明しましょう。そうしないと、初心者大会などに出た新入生が、負けてくると守りに入りあっさり敵陣を守られて終わる、というひどい試合を見せることになってしまいます。実際、自陣を取ることをほとんど練習していない新入生が、
          <b>
            大会で劣勢から逆転しようするとき効果的なのは、慣れぬ守りを試み自陣に暗記をいれることではなく、いつも以上に攻めて敵陣をしっかり抜き、なおかつ相手の手を止めておいて戻ることです。
          </b>
          こうやって勝てれば、劣勢でも攻めることで逆転できるという自信を新入生が得ることが出来て、成長の糧になります。ただし、繰り返すが相手の感じを止めることが手を出すことの本質的な目的ではないので、手を出してあとは押え手、といったことにならぬよう、しっかり伝えましょう。
        </p>
        <h3 id="h3-id-30" class="pt-4">＜送りについて＞</h3>
        <p class="MsoNormal"></p>
        <p class="MsoBodyText">
          送りは重要だが、先にも書いたように、札をしっかり早く取るための狙い方や手の出し方やリハーサルの仕方などを十分教えて余裕があったら送りを教える、で良いでしょう。時間が無ければ試合中は「これを送って」と示してあげればよい。試合後にしっかり教えよう。
        </p>
        <p>
          <b>その場の細かい送りよりも>むしろ、送りの原理を教えることが大切です。</b>
          「狙って取る」事がわかってきた新人ならば「敵陣に同じ札を２枚送ると狙いにくい」ことは容易に教えられます。この「送りの原理」こそが大事なのです。経験者に指導する時も、送りの効果とその理由をしっかり教えるとぐっと強くなります。
        </p>
        <p>
          しかし、その「理由」をまだ実感できないであろう段階で、ただマニュアルどおりに教えても、なにも意味がないし、新人も面白くないでしょう。札を１００枚覚えていない新人に一生懸命送りを教える意味は皆無です。知っている札を送らせたほうがずっとずっと良いです。
        </p>
        <p>
          送りは戦略上とても重要なので、それぞれの意味を書きたいところだが、今回は省き別の機会に回す。簡単に要点だけ書くと、①「自分が取りやすくするための送り」と②「相手を取りにくくさせる送り」それぞれを分けて説明することが重要です。そして
          B
          級以下では通常、①が優先されることも教えるべきです。なお①は、共札を分けたり（これには②の効果もあるが）、単独札になるよう送ったり、決まり字が短くなった札を送ったりすることである。また、
          3
          字以上の札を送らないこともこれにあたります。②は決まった札を直後に送らず一回待ったり、送り返しをなるべく避けることがそれにあたります。
        </p>
        <h3 id="h3-id-31" class="pt-4">＜定位置について＞</h3>
        <p>
          定位置も非常に重要ですが、種村、田口両氏に指導書にしっかり書いてあるので省きます。とにかく、自陣は１００％攻めていながらも、無意識に戻ったり反応したりできるように工夫するのです。そのように新入生に伝えよう。
        </p>
        <p>
          繰り返しになるが、指導者自身が自分の定位置が一番と思っている場合があるが、そんなことはありません。あまり後輩の定位置をいじるのは得策ではない。基礎を守れば、あとは好きにしてもいいと思います。基礎とは、たとえば「いに」と「いま」を同じサイドに置かない。とか、共札はつける、とか、大山札は下段に置く、などなどといったことです。こうした基礎はしっかり初心者に教えよう。
          <b>
            <u>
              共札のおき方の左右や、共札をわけるとき必ずこちらを送るよう決めなさい、ということもしっかり指導しましょう。
            </u>
          </b>
          <b><u></u></b>
        </p>
        <h3 id="h3-id-32" class="pt-4">＜戦略について＞</h3>
        <p class="MsoNormal"></p>
        <p class="MsoBodyText">
          戦略も大事である。これこそがかるたの醍醐味であるともいえる。ただし、既に述べたように、基礎的な取りでまず強くなってこそ戦略が生きます。波多野氏の文章にもありますが、戦略などを考えずにＡ級あるいはＡ入賞・優勝くらいまで言ったほうが、その後伸びるのです。
        </p>
        <p>
          ただ、Ｄ級にはＤ級なりの戦略や戦術があるのも事実ではあります。例えば上に書いた「負け試合で相手の感じを消す」とか「まだ自陣のむすめふさほせを守れるようになっていないとき大会にでたら、むしろ積極的に送って抜く」といったことであります。
        </p>
        <p>
          第一部にて、便宜的に「普通の攻めがるた」と「激攻めがるた」を区別してその違いを述べました。そこで記したように、かるたの試合の中で、
          <b>目の前の試合に勝つためのやりかたと、自分の基礎力をあげるためのやりかたは、必ずしも一致しません。</b>
          例えば送りや定位置についても、「自分の取りやすさ」を意図するか「相手の取りにくさ」を意図するかでやることは違ってくるのです。
          <b>
            したがって、指導において重要なことは、ただマニュアルの文面だけを教えるのではなく、それぞれの目的を教えることなのです。
          </b>
          <b></b>
        </p>
        <p>
          戦略についてはいろいろ書きたいが、これはあくまで初心者への指導のための著作なのであまり個々に深入りすることはしません。
        </p>
        <h3 id="h3-id-33" class="pt-4">＜経験者への指導について＞</h3>
        <p class="MsoNormal"></p>
        <p>経験者の指導は、東大でもまだ各種意見があるところです。したがって、以下は私見であります。</p>
        <p>
          経験者でも、攻めがるたを教わってきた人に対する指導は難しくない。もしその選手の攻めが中途半端なものであればしっかり攻めを磨くように指導すればいいだけです。ただし、その選手が自分の攻めの甘さを実感する必要があります。東大で以前よりよく使われてきたのは、
          <b>攻めの強い強豪とまず練習させて新人経験者に攻めの甘さを実感させる、</b>
          というやり方です。こうして本人がもっと攻めたいという意思を持ちさえすれば、普通に指導して強くなるでしょう。
        </p>
        <p>
          ただ、もしその攻めたいと思っている選手に、無意識にしっかり守れる自陣があれば、無理に止めさせる必要は無い、と思います。同じ理由で、明らかにおかしい場合を除いて定位置もいじることはないとおもいます。（とはいえ、経験者の中には、無意識に守れると本人が思い込んでいても実はしょぼい守りでしかない、ということがあるので、そこはちゃんと先輩が指摘しよう）
        </p>
        <p>
          一方、完成された守りがるたの選手に対する指導も、難しくないです。すでに守りを武器にＡ級で存分に戦えるのであれば、あとは送りなど戦略面・精神面等での指導が中心の高度な指導になるでしょう。または、守りがるたの選手は払いがしっかりしていない場合が多いので、払いを教えるのもよいと思います。
        </p>
        <p class="MsoBodyText">
          難しいのは、中途半端選手です。敵陣も自陣もどっちも取ろうとしてるがどちらも遅いといった選手や、守りがるたなのに守りが全然早くない選手、です。この場合、叩き潰して「君のやり方では強くなれない！」、と伝える事は確かに簡単ですが、果たしてそれが良いことでしょうか？当人は頭で理解するかもしれませんが、当人が努力してきたそれまでの歴史がある以上、感情ではなかなか認めたくないというのも無理からぬことだと思います。練習に来るたびに自分の過去の努力を否定されるばかりではかるたそのものに対する熱意も冷めてしまいます。ではどうするか？
        </p>
        <p>
          これに対する明確な答えは、実はぼく自身持ち合わせていません。ただ自分がやる一例としては、まずその選手の良い点を一つでも見つけて、褒めて自信を持たせ、そこは伸ばしてやることです。そうした上で、ではどこを直せば良いのか、どうすればその長所を生かせるか、を説く。たとえば、「君の左の守りは非常にスムーズだからＡで通用するよ。その武器を生かすためには確実に右をしっかり攻めて抜くようにしよう。きっと敵陣右と自陣左を軸に試合を進められるようになるから」といった具合でしょうか。
          <b>結局、本人が納得して東大の指導を聞く気持ちの体制が整ってから教えるのが大事だと思います。</b>
          いつもうまくいくとは限らない。結局はその選手の性格を考えて個人個人に合わせるしかないのかもしれません。
        </p>
        <p>さて、経験者を指導する上で注意しなければいけない別の２ポイントについて書きましょう。</p>
        <p>
          １つ目のポイントは、「調子」である。高校までの経験者で特に技術的指導もなしにただ練習をこなしていた選手の中は
          <b>、「自分は調子さえ上がれば強い」、と思い込んでいる選手がいます。</b>
          つまり、「目標を持ってとにかく練習をこなして調子を上げれば敵陣も自陣も札が取れるんだ！今自分の取りのレベルが低いのは集中した練習をこなしてないから調子が上がっていないだけのことなんだ」、と考えている人が多いのです。なるほど確かに誰しも調子を上げて手が自由に動くようになれば満足の行く取りが出来るのは当然です。調整というのは非常に大事ではあります。だがしかし、そういう選手は結局、調子任せに取っているだけで、この著作で述べてきたような
          <b>フォローの基礎練習に欠けている場合が多いのです。</b>
          感じが上がらないとき飛び出して感じを磨く練習とか、出札に感じ遅れた時に暗記・払いでフォローすることとか、調子が悪くて手が上空で止まるときのフォローの仕方とか、「たま」までじゃなくて「たまのをよ」まで覚えるとフォローがしやすいこととか・・・です。
          <b>要するにたまたま調子が上がったときのみ強いだけの選手</b>
          なのに、その「たまたま」の強さを自分の実力と勘違いしてしまっているのです。
        </p>
        <p>
          問題は、こういった選手を練習で潰して欠点を指摘しても「たまたま今は調子が上がらないから上手く取れなかっただけ。高校の夏休み時のようにバリバリ練習して全盛期の調子が戻れば自分はちゃんと取れるのだ」と思い込んでいてあまり指摘を聞いてもらえないことがあることです。これについてもぼく自身明確な指導法を持っていません。一例としては、まずその「思い込み」を頭ごなしに否定することなく「調子の悪い時のフォロー」としての基礎技術を教えるところから始めて、次第に冷静に気づかせてはどうでしょうか。
        </p>
        <p>
          ２つ目のポイントは「目先の勝利」です。Ｂ級くらいの経験者になると、東大やお茶大などで団体戦のポイントゲッターとして結果を期待されてしまう場合があります。この場合は、本人が一度かるたを崩して基礎を磨きなおしたいと思っても、目の前にせまった団体戦のことを考えるとそこまで勇気が湧かないことが多いと思います。この場合も難しい。やはりこれに対してもぼく自身明確な答えを持っていませんが、本人の意思があれば、それなりに団体戦の負担を減らすようなチーム編成もありかな、と思います。あくまで一案ですが。
        </p>
        <p>
          いろいろ述べてきましたが、
          <b>
            結局は技術論もさることながら、経験者が初心者と一緒になってかるたに強くなろう、という雰囲気を会全体に作っていくことが、実は一番大事なことだと思います。また、経験者が「東大のカルタは自分に会わないんじゃないだろうか・・」と悩むことは減らしたいし、もし悩んだ時、話をちゃんと聞いてあげてアドバイスをしてあげる先輩の存在こそが大事だと思います。
          </b>
        </p>
        <h3 id="h3-id-34" class="pt-4">＜終わりに＞</h3>
        <p>
          本著作は、ごく初期の初心者指導のものであり、もう少し上級の選手に対してはまた詳細な指導をしなくてはならないでしょう。とくに１００％の攻めを失わないようにしつつ自陣で無意識に反応できる札を増やすための練習法などは今回は触れなかったので、また気が向いたらこれに付け足していこうと思います。
        </p>
        <p>
          以上、長々と述べてきた。最後にもう一度繰り返すが、
          <b>
            やっぱり全ての基本となる一番大事なことは、後輩を強くしよう、会を強くしようと熱意をもち、その熱意を後輩に伝えることであります。
          </b>
        </p>
        <p>
          正直、まだかるたを始めて一年しか経っていない大学２年生らが、指導のノウハウ全てを理解して１００％確実に初心者指導するのは至難です。それでも熱意だけは示して欲しいです。とにかく初心者と一番触れ合うことの多い大学二年生は、「名人が相手でも敵陣を全部抜こうと目指すこと、１－２５で負けていても敵陣の札は絶対に最速で抜くことを目指すこと」を練習中でも練習後でも熱意をもって語って欲しいのです。
          <b>
            口だけで「攻めよ」というのは簡単だが、攻めることが大事なんだという気持ちが後輩に伝わって初めて、後輩も本気になって攻めを磨くようになるものです。
          </b>
        </p>
        <p>指導ノウハウで欠けたところはよく３ , ４年生、院生がフォローし、「指導の指導」をして欲しいです。</p>
        <p>
          また、院生やＯＢを上手く使って欲しいと思います。しっかり理論で年寄りが説明するのが望ましそうな初心者がいたら、院生やＯＢに頼んで練習にきてもらって、対戦させましょう。ＯＢが練習にきている目的は自分の練習が第一ではないのです。
        </p>
        <p>
          自分さえ強くなれば後輩はどうなってもいいと考える人がもしもいたら、悲しいことです。そして、大学二年生からＯＢまでの熱意を生むのは、やはりみなのかるた会に対する愛情でしょう。それだけ愛情が持てるサークルを、皆で作り上げていくことが、すべての基本となることだと思います。
        </p>
        <p>
          もしこの著作を読んで、「このように教えなければならない」と義務感だけで指導をしてしまったならかるたがつまらなくなってしまうでしょう。ただの義務感ではなく、かるた会を素晴らしくしよう後輩を強くしようという気持ちが先に立っていれば、きっとこの著作を読んだ時「なるほど！あしたからそうしてみよう！」と練習が楽しみになってくるのではと思います。是非是非、そうして練習を楽しんでもらいたいと思います。先輩がかるたの楽しさやを伝えることが、技術以前に結局一番の指導なんだと思います。（終）
        </p>
        <p></p>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderComponent from "../components/Header.vue";
export default { name: "ShidoNoShidoPage", components: { HeaderComponent } };
</script>
